/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/unbound-method */
import { CommonModule } from '@angular/common';
import { catchError, map, throwError, type Observable } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Router } from '@angular/router';
import { GSIconListBulletModel, GSIconListModel, GsIconListComponent } from 'gas-south';
import { StorageEnum } from '../../shared/enums/storage.enum';
import { Component, OnInit, Renderer2 } from '@angular/core';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../shared/services/util.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from '../../shared/services/storage.service';
import { CookieService } from 'ngx-cookie-service';
import { CmsService } from '../../shared/services/cms.service';
import { AuthService } from '../../shared/services/auth.service';
import { MobileAppBannerComponent } from '../../shared/components/mobile-app-banner/mobile-app-banner.component';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GsIconListComponent,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MobileAppBannerComponent,
    MatCheckboxModule
  ]
})

export class LoginComponent implements OnInit {
  pageLabels$!: Observable<any>;
  loginForm!: FormGroup;
  apiErrors: any[] = [];
  apiError: string = '';
  isHover = false;
  isFocus = false;
  passwordVisible = false;
  formErrorMessage: string = '';

  constructor (
    private readonly cms: CmsService,
    private readonly auth: AuthService,
    private readonly formBuilder: FormBuilder,
    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    private router: Router,
    private readonly utils: UtilService,
    private readonly storage: StorageService,
    private readonly cookieService: CookieService,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit (): void {
    this.pageLabels$ = this.cms.getContent('oas-login', 'Login')
      .pipe(
        map(data => {
          const listData = new GSIconListModel();
          listData.Header = data.accountBenefitsList.header;
          listData.Subheader = data.accountBenefitsList.subheader;

          const bulletList: GSIconListBulletModel[] = [];
          data.accountBenefitsList?.bulletList?.forEach((bullet: any) => {
            const bulletObj = new GSIconListBulletModel();
            bulletObj.BulletText = bullet.bullet.bulletText;
            bulletObj.BulletTitle = bullet.bullet.bulletTitle;
            bulletObj.IconPath = bullet.bullet.iconPath;
            bulletObj.IconAltText = bullet.bullet.iconAltText;
            bulletObj.ShowBullet = bullet.bullet.showBullet;

            bulletList.push(bulletObj);
          });

          listData.BulletList = bulletList;

          const newDataObj = JSON.parse(JSON.stringify(data));
          newDataObj.accountBenefitsList = listData;

          return newDataObj;
        })
      );

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.maxLength(12), Validators.minLength(7)]],
      rememberMe: [false]
    });
    // Check if the cookie exists and set the username
    if (this.cookieService.check('rememberedUsername')) {
      this.loginForm.patchValue({
        email: this.cookieService.get('rememberedUsername'),
        rememberMe: true
      });
    }
    // Add focus event listeners to reset the button state
    this.addFocusListeners();
    this.utils.hideSpinner();
  }

  addFocusListeners (): void {
    if (typeof document !== 'undefined') {
      const emailField = document.getElementById('email');
      const passwordField = document.getElementById('LoginPassword');

      if (emailField && passwordField) {
        this.renderer.listen(emailField, 'focus', () => { this.resetButtonState(); });
        this.renderer.listen(passwordField, 'focus', () => { this.resetButtonState(); });
      }
    }
  }

  resetButtonState (): void { // New method
    this.isHover = false;
    this.isFocus = false;
  }

  get email (): FormControl {
    return this.loginForm.get('email') as FormControl;
  }

  get password (): FormControl {
    return this.loginForm.get('password') as FormControl;
  }

  onSubmit (): void {
    this.apiError = '';
    this.formErrorMessage = '';
    if (this.loginForm.valid) {
      this.utils.showSpinner();
      this.apiErrors = this.storage.getSession(StorageEnum.GlobalVars).apiErrors;
      // Handle form submission
      const userLoginName: string = this.email.value;
      const userPassword: string = this.password.value;

      if ((Boolean(this.loginForm.value.rememberMe)) && (userLoginName.length > 0)) {
        // Set the cookie with the username
        this.cookieService.set('rememberedUsername', userLoginName, { expires: 7, path: '/' }); // Expires in 7 days
      } else {
        // Delete the cookie if remember me is unchecked or username is not provided
        this.cookieService.delete('rememberedUsername');
      }

      this.auth.getAUP(userLoginName, userPassword).pipe(
        catchError((dataError: any) => {
          const errorCode = dataError.error.ErrorCode;
          const newApiError = this.apiErrors.find(error => {
            return error.apiError.errorCode === errorCode;
          });
          if (newApiError === undefined) {
            const genericLoginError = this.apiErrors.find(error => {
              return error.apiError.errorCode === 'GenericLoginError';
            });
            this.apiError = genericLoginError.apiError.displayMessage;
            this.showAPIErrorLabel();
          } else {
            this.apiError = newApiError.apiError.displayMessage;
            this.showAPIErrorLabel();
          }
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          this.utils.hideSpinner();
          return throwError(() => new Error(dataError));
        })).subscribe((data: any) => {
        const statusFlag = data.Accounts[0].ServiceAddresses[0].SaStatusFlg;
        if (data.TotalNumAccounts > 1) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigateByUrl('multiaccount-summary');
        } else {
          if (statusFlag === '10') {
            this.router.navigateByUrl('pending-start');
          } else if (statusFlag === '60' || statusFlag === '70') {
            this.router.navigateByUrl('sesonal');
          } else if (statusFlag === '20' || statusFlag === '40' || statusFlag === '50') {
            this.router.navigateByUrl('account-summary');
          }
        }
      });
    } else {
      // Mark all controls as touched to trigger validation messages
      this.loginForm.markAllAsTouched();
      this.showFormErrorLabel();
    }
  }

  logDebug (data: any): void {
    this.utils.consoleGroup('Login icon-list Debug Data', data);
  }

  async goToForgotPassword (): Promise<void> {
    await this.router.navigateByUrl('forgot');
  }

  logButtonClick (data: any): void {
    this.utils.consoleGroup('logButtonClick', data);
  }

  logDebugData (data: any): void {
    this.utils.consoleGroup('logDebugData', data);
  }

  togglePasswordVisibility (): void {
    this.passwordVisible = !this.passwordVisible;
  }

  showFormErrorLabel (): void {
    this.formErrorMessage = this.storage.getSession(StorageEnum.GlobalVars).formError;
    setTimeout(() => {
      const errorEl = document.getElementById('formErrorLabel');
      errorEl?.focus();
    }, 10);
  }

  showAPIErrorLabel (): void {
    setTimeout(() => {
      const errorEl = document.getElementById('apiErrorLabel');
      errorEl?.focus();
    }, 10);
  }
}
