/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../../shared/services/util.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { CmsService } from '../../../shared/services/cms.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ActivatedRoute, Router } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from '../../../shared/services/storage.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { type Observable } from 'rxjs/internal/Observable';
import { type AccountDetail } from '../../../shared/models/account-detail';
import { StorageEnum } from '../../../shared/enums/storage.enum';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { PaymentService } from '../../../shared/services/payment.service';
import { ErrorData } from '../../../shared/models/error-data';
import { CTA, SuccessData } from '../../../shared/models/success-data.model';
import { AutoPayChangeData } from '../../../shared/models/auto-pay-change';
import { format, toDate } from 'date-fns';
import { AutoPayCancel } from '../../../shared/models/autopay-cancel';
import { PaymentAccountType } from '../../../shared/enums/paymentaccounttype.enum';
import { AutoPay } from '../../../shared/models/auto-pay';

@Component({
  selector: 'app-autopay-cancel',
  standalone: true,
  imports: [
    MatDividerModule,
    MatButtonModule
  ],
  templateUrl: './autopay-cancel.component.html',
  styleUrl: './autopay-cancel.component.scss'
})
export class AutopayCancelComponent {
  pageReady = false;
  accountDetail: AccountDetail;
  pageLabels$!: Observable<any>;
  pageLabels: any = {};
  apiError: string = '';
  formErrorMessage: string = '';
  apiErrors: any[] = [];
  showAutoPayChangeHeader = false;
  autoPayErrors: any [] = [];

  constructor (
    private readonly utils: UtilService,
    private readonly cms: CmsService,
    private readonly router: Router,
    private readonly storage: StorageService,
    private readonly payment: PaymentService,
    private readonly route: ActivatedRoute
  ) {
    this.accountDetail = this.storage.getSession(StorageEnum.AccountDetail);
    this.autoPayErrors = this.storage.getSession(StorageEnum.GlobalVars)?.autoPayErrors;
  }

  ngOnInit (): void {
    this.cms.getContent('oas-autopay-change-cancel', 'AutoPayChangeCancel').subscribe(data => {
      this.pageLabels = data;
    });

    if (this.checkForAutoPayChange()) {
      this.showAutoPayChangeHeader = true;
    }
    this.pageReady = true;
    this.utils.hideSpinner();
  }

  cancelAutoPay (): void {
    const today = format(new Date().toString(), 'MM/dd/yyyy');
    const billDueDate = format(toDate(this.accountDetail.CurrentBalanceDueDate!).toString(), 'MM/dd/yyyy');
    const autoPayEnrollDate = format(toDate(this.accountDetail.AutoPay!.StartDate!).toString(), 'MM/dd/yyyy');
    // if auto pay enrollment date is today or current bill due date is today not eligible
    if (billDueDate === today) {
      this.setErrorDataEnrolledSameBillDate('errorAutoPaySameBillDate');
      void this.router.navigateByUrl('/error-page');
    } else if (autoPayEnrollDate === today) {
      this.setErrorDataEnrolledSameDay('errorAutoPayEnrolledSameDay');
      void this.router.navigateByUrl('/error-page');
    } else {
      this.utils.showSpinner();
      const autoPayCancelRequest = new AutoPayCancel();
      autoPayCancelRequest.AccountNumber = this.accountDetail.AccountNumber;
      autoPayCancelRequest.RecurringScheduleId = this.accountDetail.AutoPay?.RecurringScheduleId;
      autoPayCancelRequest.CustomerClass = this.accountDetail.CustomerClass;

      if (this.accountDetail.AutoPay?.PaymentAccountType === PaymentAccountType.Banking) {
        this.payment.cancelAutoPayBank(autoPayCancelRequest).subscribe({
          error: (e) => {
            this.setErrorData(e.message);
            void this.router.navigateByUrl('/error-page');
          },
          next: (data: any) => {
            if (this.showAutoPayChangeHeader) {
              this.setAutoPayChangeData();
              void this.router.navigateByUrl('/autopay-change');
            } else {
              this.setSuccessData();
              this.accountDetail.IsAutoPay = false;
              this.accountDetail.AutoPay = new AutoPay();
              this.storage.setSession(StorageEnum.AccountDetail, this.accountDetail);
              void this.router.navigateByUrl('/payment-success');
            }
          }
        });
      } else {
        this.payment.cancelAutoPayCard(autoPayCancelRequest).subscribe({
          error: (e) => {
            this.setErrorData(e.message);
            void this.router.navigateByUrl('/error-page');
          },
          next: (data: any) => {
            if (this.showAutoPayChangeHeader) {
              this.setAutoPayChangeData();
              void this.router.navigateByUrl('/autopay-change');
            } else {
              this.setSuccessData();
              this.accountDetail.IsAutoPay = false;
              this.accountDetail.AutoPay = new AutoPay();
              this.storage.setSession(StorageEnum.AccountDetail, this.accountDetail);
              void this.router.navigateByUrl('/payment-success');
            }
          }
        });
      }
    }
  }

  setSuccessData (): void {
    const successLabels = this.pageLabels.autoPayCancelSuccess.value.data;
    const successData = new SuccessData();
    successData.Header = successLabels.header;
    successData.Subheader = successLabels.subheaderText;
    const acctCTA = successLabels.ctaList.find((cta: any) => {
      return cta.ctaId === 'makePayment';
    });
    const cta = new CTA();
    cta.Label = acctCTA.ctaLabel;
    cta.AriaLabel = acctCTA.ctaAriaLabel;
    cta.Link = acctCTA.ctaUrl;
    successData.CTAList.push(cta);
    successData.Details = [];
    this.storage.setSession(StorageEnum.SuccessData, successData);
  }

  setAutoPayChangeData (): void {
    const autoPayChangeData = new AutoPayChangeData();
    autoPayChangeData.LastFour = this.accountDetail.AutoPay?.AccountNumber;
    autoPayChangeData.PaymentType = this.payment.isCardBankDigital(this.accountDetail.AutoPay?.PaymentAccountType);
    autoPayChangeData.PaymentAccountType = this.accountDetail.AutoPay?.PaymentAccountType;
    this.storage.setSession(StorageEnum.ChangeAutoPayData, autoPayChangeData);
  }

  setErrorDataEnrolledSameDay (errorCode: string): void {
    const errorDetail = new ErrorData();
    errorDetail.ErrorCode = errorCode;
    if (this.showAutoPayChangeHeader) {
      errorDetail.Header = this.pageLabels.errors[3].error.value.data.header;
      errorDetail.SubheaderTitle = this.pageLabels.errors[3].error.value.data.subheaderTitle;
      errorDetail.Subheader = this.pageLabels.errors[3].error.value.data.subheaderText;
      errorDetail.CtaButton.Label = this.pageLabels.errors[3].error.value.data.ctaList[0].ctaLabel;
      errorDetail.CtaButton.Link = this.pageLabels.errors[3].error.value.data.ctaList[0].ctaUrl;
      errorDetail.CtaButton.AriaLabel = this.pageLabels.errors[3].error.value.data.ctaList[0].ctaAriaLabel;
      this.storage.setSession(StorageEnum.ErrorData, errorDetail);
    } else {
      errorDetail.Header = this.pageLabels.errors[1].error.value.data.header;
      errorDetail.SubheaderTitle = this.pageLabels.errors[1].error.value.data.subheaderTitle;
      errorDetail.Subheader = this.pageLabels.errors[1].error.value.data.subheaderText;
      errorDetail.CtaButton.Label = this.pageLabels.errors[1].error.value.data.ctaList[0].ctaLabel;
      errorDetail.CtaButton.Link = this.pageLabels.errors[1].error.value.data.ctaList[0].ctaUrl;
      errorDetail.CtaButton.AriaLabel = this.pageLabels.errors[1].error.value.data.ctaList[0].ctaAriaLabel;
      this.storage.setSession(StorageEnum.ErrorData, errorDetail);
    }
  }

  setErrorDataEnrolledSameBillDate (errorCode: string): void {
    const errorDetail = new ErrorData();
    errorDetail.ErrorCode = errorCode;
    if (this.showAutoPayChangeHeader) {
      errorDetail.Header = this.pageLabels.errors[4].error.value.data.header;
      errorDetail.SubheaderTitle = this.pageLabels.errors[4].error.value.data.subheaderTitle;
      errorDetail.Subheader = this.pageLabels.errors[4].error.value.data.subheaderText;
      errorDetail.CtaButton.Label = this.pageLabels.errors[4].error.value.data.ctaList[0].ctaLabel;
      errorDetail.CtaButton.Link = this.pageLabels.errors[4].error.value.data.ctaList[0].ctaUrl;
      errorDetail.CtaButton.AriaLabel = this.pageLabels.errors[4].error.value.data.ctaList[0].ctaAriaLabel;
      this.storage.setSession(StorageEnum.ErrorData, errorDetail);
    } else {
      errorDetail.Header = this.pageLabels.errors[0].error.value.data.header;
      errorDetail.SubheaderTitle = this.pageLabels.errors[0].error.value.data.subheaderTitle;
      errorDetail.Subheader = this.pageLabels.errors[0].error.value.data.subheaderText;
      errorDetail.CtaButton.Label = this.pageLabels.errors[0].error.value.data.ctaList[0].ctaLabel;
      errorDetail.CtaButton.Link = this.pageLabels.errors[0].error.value.data.ctaList[0].ctaUrl;
      errorDetail.CtaButton.AriaLabel = this.pageLabels.errors[0].error.value.data.ctaList[0].ctaAriaLabel;
      this.storage.setSession(StorageEnum.ErrorData, errorDetail);
    }
    errorDetail.Header = this.pageLabels.errors[0].error.value.data.header;
    errorDetail.SubheaderTitle = this.pageLabels.errors[0].error.value.data.subheaderTitle;
    errorDetail.Subheader = this.pageLabels.errors[0].error.value.data.subheaderText;
    errorDetail.CtaButton.Label = this.pageLabels.errors[0].error.value.data.ctaList[0].ctaLabel;
    errorDetail.CtaButton.Link = this.pageLabels.errors[0].error.value.data.ctaList[0].ctaUrl;
    errorDetail.CtaButton.AriaLabel = this.pageLabels.errors[0].error.value.data.ctaList[0].ctaAriaLabel;
    this.storage.setSession(StorageEnum.ErrorData, errorDetail);
  }

  setErrorData (errorCode: string): void {
    const error = this.autoPayErrors.find(error => {
      return error.error.value.data.errorCode === errorCode;
    });
    const autoPayError = error.error.value.data;
    if (this.showAutoPayChangeHeader) {
      const errorLabels = this.pageLabels.errors[5].error.value.data.ctaList[0];
      const errorDetail = new ErrorData();
      errorDetail.Header = autoPayError.errorHeader;
      errorDetail.SubheaderTitle = '';
      errorDetail.Subheader = autoPayError.displayMessage;
      errorDetail.CtaButton.Label = errorLabels.ctaLabel;
      errorDetail.CtaButton.Link = errorLabels.ctaUrl;
      errorDetail.CtaButton.AriaLabel = errorLabels.ctaAriaLabel;
      this.storage.setSession(StorageEnum.ErrorData, errorDetail);
    } else {
      const errorLabels = this.pageLabels.errors[2].error.value.data.ctaList[0];
      const errorDetail = new ErrorData();
      errorDetail.Header = autoPayError.errorHeader;
      errorDetail.SubheaderTitle = '';
      errorDetail.Subheader = autoPayError.displayMessage;
      errorDetail.CtaButton.Label = errorLabels.ctaLabel;
      errorDetail.CtaButton.Link = errorLabels.ctaUrl;
      errorDetail.CtaButton.AriaLabel = errorLabels.ctaAriaLabel;
      this.storage.setSession(StorageEnum.ErrorData, errorDetail);
    }
  }

  checkForAutoPayChange (): boolean {
    const queryParams = this.route.snapshot.queryParams;
    const changeAutoPay = queryParams['changeAutoPay'];
    if (this.utils.isNullOrUndefined(changeAutoPay)) {
      return false;
    }
    if (changeAutoPay === 'true') {
      return true;
    }
    return false;
  }
}
