/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../environments/environment.development';
import { CookieService } from 'ngx-cookie-service';
import { CmsService } from '../../services/cms.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mobile-app-banner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mobile-app-banner.component.html',
  styleUrl: './mobile-app-banner.component.scss'
})
export class MobileAppBannerComponent implements OnInit {
  mobileDeviceType: string = 'unknown';
  env = environment;
  showMobileAppBanner = true;
  pageLabels$!: Observable<any>;
  pageLabels: any = {};

  constructor (
    private readonly utils: UtilService,
    private readonly cookieService: CookieService,
    private readonly cms: CmsService
  ) {}

  ngOnInit (): void {
    this.pageLabels$ = this.cms.getContent('oas-mobile-banner', 'MobileBanner');
    this.pageLabels$.subscribe(data => {
      this.pageLabels = data;
    });
    this.displayMobileAppBanner();
  }

  displayMobileAppBanner (): void {
    this.mobileDeviceType = (this.env.deviceInfo as any)?.os ?? 'unknown';
    if (this.cookieService.check('mobileAppBanner')) {
      this.showMobileAppBanner = false;
    }
  }

  onCloseClick (): void {
    const expirationTime: number = this.pageLabels.appBannerTimeoutHours;
    const localDate = new Date();
    // Get UTC date since server is x hours ahead
    const utcDate = this.convertToUTC(localDate);
    utcDate.setHours(utcDate.getHours() + expirationTime);
    this.cookieService.set('mobileAppBanner', 'true', { expires: utcDate, path: '/' }); // Expires in 1 day
    this.showMobileAppBanner = false;
  }

  convertToUTC (date: Date): Date {
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }
}
