<main *ngIf="pageLabels$ | async as pageLabels" class="gs-page">
    <div class="gradient-page">
        <div class="gradient-container fup-section" [style]="'background:url(' + pageLabels.gradientBackgroundUrl + ')'">
            <div class="fup-form-container">
                <img [src]='pageLabels.gasSouthLogoLg' alt='Gas South Logo' class="logo-image">
                <div id="apiErrorLabel" *ngIf="apiError !== ''" tabindex="0" class="gs-post-notification-error">
                    <i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                    <span [innerHTML]="apiError"></span>
                </div>
                <div id="formErrorLabel" *ngIf="formErrorMessage != ''" tabindex="0" class="gs-post-notification-error">
                    <i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                    <span [innerHTML]="formErrorMessage"></span>
                </div>
                <h1 class="fup-header">{{pageLabels.header}}</h1>
                <form [formGroup]="fupForm" (ngSubmit)="onSubmit()" class="fup-form">
                    <mat-form-field class="gs-form-field email-field" appearance="outline">
                        <mat-label id="emailLabel">{{pageLabels.emailLabel}}</mat-label>
                        <input matInput class="custom-input" formControlName="email" id="email" aria-labelledby="emailLabel emailError">
                        @if (fupForm.get('email')?.hasError('required')) {
                            <mat-error id="emailError">{{pageLabels.emailNotProvidedError}}</mat-error>
                        }
                        @else if (fupForm.get('email')?.hasError('email')) {
                            <mat-error id="emailError">{{pageLabels.emailFormatInvalid}}</mat-error>
                        }
                    </mat-form-field>

                    <button mat-flat-button class="gs-form-button-primary fup-button" color="primary">{{pageLabels.forgotButtonText}}</button>
                </form>
                <p class="help-text" [innerHTML]="pageLabels.helpText"></p>
                <a class="login-link" (click)="goToLogin()" href>{{pageLabels.backLinkText}}</a>
            </div>
        </div>
        <div class="account-benefits fup-section">
            <gs-icon-list [ListData]="pageLabels.accountBenefitsList" (DebugData)="logDebug($event)"></gs-icon-list>
        </div>
    </div>
</main>