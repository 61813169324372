import { Component } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../shared/services/util.service';

@Component({
  selector: 'app-multi-account-summary',
  standalone: true,
  imports: [],
  templateUrl: './multi-account-summary.component.html',
  styleUrl: './multi-account-summary.component.css'
})
export class MultiAccountSummaryComponent {
  constructor (
    private readonly utils: UtilService
  ) {}

  ngOnInit (): void {
    this.utils.hideSpinner();
  }
}
