import { Component, type OnInit } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from '../../services/storage.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../services/util.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Router } from '@angular/router';

import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { StorageEnum } from '../../enums/storage.enum';

@Component({
  selector: 'app-success-page',
  standalone: true,
  imports: [
    MatDividerModule,
    MatButtonModule
  ],
  templateUrl: './success-page.component.html',
  styleUrl: './success-page.component.scss'
})
export class SuccessPageComponent implements OnInit {
  pageReady = false;
  isAutopay = false;
  data: any;
  isFutureDatedPayment = false;

  constructor (
    private readonly router: Router,
    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    private storage: StorageService,
    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    private utils: UtilService
  ) {}

  ngOnInit (): void {
    this.data = this.storage.getSession(StorageEnum.SuccessData);
    this.pageReady = this.inputIsValid();
    this.utils.hideSpinner();
  }

  inputIsValid (): boolean {
    const header = this.data.Header;
    const details = this.data.Details;
    const cta = this.data.CTA;

    if (this.utils.isNullOrWhitespace(header)) {
      this.utils.consoleLog('Header is required');
      return false;
    }

    if (this.utils.isNullOrUndefined(details) || details.length <= 0) {
      this.utils.consoleLog('Details are required');
      return false;
    }

    if (!cta || this.utils.isNullOrWhitespace(cta.Label) || this.utils.isNullOrWhitespace(cta.Link)) {
      this.utils.consoleLog('CTA Label and Link are required');
      return false;
    }

    if (this.data.isFutureDatedPayment) {
      this.isFutureDatedPayment = true;
    }

    return true;
  }

  async goToAccountSummary (): Promise<void> {
    await this.router.navigateByUrl('/account-summary');
  }
}
