import { Injectable } from '@angular/core';
// import { environment } from '../../../environments/environment.development';
import { type Observable, tap } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment.development';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from './storage.service';
import { StorageEnum } from '../enums/storage.enum';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor (
    private readonly http: HttpClient,
    private readonly storage: StorageService,
    private readonly utils: UtilService
  ) { }

  /**
   * Returns content from Builder
   * @param model model name in builder
   * @param pageName content name in builder
   * @returns observable containing
   */
  getAUP (userLoginName: string, userPassword: string): Observable<any> {
    const url = environment.AuthUrl() + '/authenticate/aup';
    const authData = {
      UserName: userLoginName,
      Password: userPassword,
      startIdx: '1',
      endIdx: '30'
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        ClientId: environment.ClientId
      })
    };

    const res = this.http.post(url, authData, httpOptions);
    return res.pipe(
      tap((data: any) => {
        if (data.TotalNumAccounts > 1) {
          this.storage.setSession(StorageEnum.MultiAccountResponse, data);
        } else {
          this.storage.setSession(StorageEnum.LoginResponse, data);
        }
      })
    );
  }

  forgotUsernamePassword (email: string): Observable<any> {
    const url = environment.AuthUrl() + '/authenticate/forgot';
    const authData = {
      EmailID: email
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        ClientId: environment.ClientId
      })
    };

    const res = this.http.post(url, authData, httpOptions);
    return res.pipe(
      tap((data: any) => {
        this.utils.consoleGroup('fup auth service data: ', data);
      })
    );
  }
}
