// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../environments/environment.development';
import { StorageEnum } from './enums/storage.enum';
import { StorageService } from './services/storage.service';
import { inject } from '@angular/core';

export const authtokenheaderInterceptor: HttpInterceptorFn = (req, next) => {
  const storage = inject(StorageService);
  const acct = storage.getSession(StorageEnum.LoginResponse);
  const endPointToAddAuthHeaderTo = environment.OASUrl();

  if (req.url.includes(endPointToAddAuthHeaderTo)) {
    if (acct?.AuthToken) {
      const clonedReq = req.clone({ headers: req.headers.set('AuthToken', acct.AuthToken) });
      return next(clonedReq);
    }
  }
  return next(req);
};
