@if ((mobileDeviceType == 'iOS' || mobileDeviceType == 'Android') && showMobileAppBanner) {
<div role="banner" class="app-banner">
  <button id="closeBtn" type="button" [attr.aria-label]="pageLabels.bannerCloseButtonAriaLabel" class="banner-button" (click)="onCloseClick()">X</button>
  @if(mobileDeviceType == 'iOS') {
  <img class="app-image"
  [src]='pageLabels.bannerImageSource'
    [alt]='pageLabels.bannerImageSourceAltAppStoreText'>
  } @else if (mobileDeviceType == 'Android') {
  <img class="app-image"
  [src]='pageLabels.bannerImageSource'
    [alt]='pageLabels.bannerImageSourceAltPlayStoreText'>
  }
  <div class="banner-text">
    <div class="banner-app-name">{{pageLabels.bannerAppName}}</div>
    <div class="banner-pub-name">{{pageLabels.bannerAppPublisherName}}</div>
    @if(mobileDeviceType == 'iOS') {
    <div class="banner-app">{{pageLabels.bannerAppStoreText}}</div>
    } @else if (mobileDeviceType == 'Android') {
    <div class="banner-app">{{pageLabels.bannerPlayStoreText}}</div>
    }
  </div>
  @if(mobileDeviceType == 'iOS') {
  <a class="banner-link"  [attr.aria-label]="pageLabels.bannerAppStoreLinkAriaLabel" href="{{pageLabels.bannerAppStoreLink}}">{{pageLabels.bannerLinkText}}</a>
  } @else if (mobileDeviceType == 'Android') {
  <a class="banner-link" [attr.aria-label]="pageLabels.bannerPlayStoreLinkAriaLabel"
    href="{{pageLabels.bannerPlayStoreLink}}">{{pageLabels.bannerLinkText}}</a>
  }
</div>
}