/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/unbound-method */
import { Component, Inject, PLATFORM_ID, type OnInit } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { FormBuilder, type FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { type PaymentMethod } from '../../../shared/models/payment-method';
import { StorageEnum } from '../../../shared/enums/storage.enum';
import { catchError, tap, throwError } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from '../../../shared/services/storage.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { PaymentService } from '../../../shared/services/payment.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../../shared/services/util.service';
import { type AccountDetail } from '../../../shared/models/account-detail';
import { environment } from '../../../../environments/environment.development';
import { BankAccountFormComponent } from '../../../shared/components/bank-account-form/bank-account-form.component';
import { CreditCardFormComponent } from '../../../shared/components/credit-card-form/credit-card-form.component';
import { DialogResultType } from '../../../shared/enums/dialogresulttype.enum';
import { type PaymentAccountType } from '../../../shared/enums/paymentaccounttype.enum';
import { type PaymentDialogResult } from '../../../shared/models/payment-dialog-result';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { MatDialog } from '@angular/material/dialog';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { PaymentusService } from '../../../shared/services/paymentus.service';
import { DigitalPaymentModalComponent } from '../../../shared/components/digital-payment-modal/digital-payment-modal.component';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { CmsService } from '../../../shared/services/cms.service';
import { format, toDate } from 'date-fns';
import { CTA, SuccessData, SuccessDetails } from '../../../shared/models/success-data.model';
import { ErrorData } from '../../../shared/models/error-data';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AutoPayChangeData } from '../../../shared/models/auto-pay-change';

@Component({
  selector: 'app-autopay-enroll',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  templateUrl: './autopay-enroll.component.html',
  styleUrl: './autopay-enroll.component.scss'
})
export class AutopayEnrollComponent implements OnInit {
  pageReady = false;
  isBrowser = false;
  accountDetail: AccountDetail;
  autopayForm: FormGroup;
  paymethods: PaymentMethod[] | undefined = [];
  formErrorMessage?: string = '';
  pageLabels: any;
  showFDPWillBeDraftedPreNotification = false;
  showFDPWillBeCanceledPreNotification = false;
  showLimitedPaymentOptionPreNotification = false;
  showBillDueTodayPreNotification = false;
  autoPayErrors: any [] = [];
  changeAutoPayData: AutoPayChangeData;
  changeAutoPay = false;

  constructor (
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) readonly platformId: Object,
    private readonly cms: CmsService,
    public dialog: MatDialog,
    private readonly fb: FormBuilder,
    private readonly payment: PaymentService,
    private readonly paymentus: PaymentusService,
    private readonly router: Router,
    private readonly storage: StorageService,
    private readonly utils: UtilService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.accountDetail = this.storage.getSession(StorageEnum.AccountDetail);
    this.autopayForm = this.fb.group({
      paymethod: this.fb.control<PaymentMethod | null>(null, [Validators.required]),
      terms: this.fb.control<boolean>(false, [Validators.requiredTrue])
    });
    this.autoPayErrors = this.storage.getSession(StorageEnum.GlobalVars)?.autoPayErrors;
    this.changeAutoPayData = this.storage.getSession(StorageEnum.ChangeAutoPayData);
  }

  ngOnInit (): void {
    this.cms.getContent('autopay-enroll', 'Autopay Enroll').subscribe(data => {
      this.pageLabels = data;
      if (this.isBrowser) {
        this.getPreferredPayments();
        this.displayPreNotifications();
        this.checkForAutoPayChange();
      }
    });
  }

  displayPreNotifications (): void {
    const todaysDate = format(new Date().toString(), 'MM/dd/yyyy');
    const billDueDate = format(toDate(this.accountDetail.CurrentBalanceDueDate!).toString(), 'MM/dd/yyyy');

    if (this.accountDetail.FuturePayment?.Info?.PaymentDate && this.accountDetail.FuturePayment?.Info?.PaymentAmount) {
      const futurePaymentDate = format(toDate(this.accountDetail.FuturePayment.Info.PaymentDate).toString(), 'MM/dd/yyyy');
      const futurePaymentAmount = this.accountDetail.FuturePayment.Info?.PaymentAmount;
      if (futurePaymentDate < billDueDate) {
        this.showFDPWillBeDraftedPreNotification = true;
        this.pageLabels.fdpWillBeDraftedPreNofitication = this.pageLabels.fdpWillBeDraftedPreNofitication.replace('{{date}}', futurePaymentDate);
        this.pageLabels.fdpWillBeDraftedPreNofitication = this.pageLabels.fdpWillBeDraftedPreNofitication.replace('{{$}}', '$' + futurePaymentAmount);
      } else if (futurePaymentDate >= billDueDate) {
        this.showFDPWillBeCanceledPreNotification = true;
        this.pageLabels.fdpWillBeCanceledPreNofitication = this.pageLabels.fdpWillBeCanceledPreNofitication.replace('{{date}}', futurePaymentDate);
      }
    }
    if (this.accountDetail.IsLimitedPaymentOptions) {
      this.showLimitedPaymentOptionPreNotification = true;
    }
    if (billDueDate === todaysDate) {
      this.showBillDueTodayPreNotification = true;
    }
  }

  checkForAutoPayChange (): void {
    if (this.router.url.includes('autopay-change')) {
      this.changeAutoPay = true;
      this.pageLabels.changeAutoPayEnrollText = this.pageLabels.changeAutoPayEnrollText.replace('{{paymethod}}', this.changeAutoPayData.PaymentAccountType);
      if (this.changeAutoPayData.PaymentType === 'DIGITAL') {
        this.pageLabels.changeAutoPayEnrollText = this.pageLabels.changeAutoPayEnrollText.replace('{{endingnumber}}', '');
      } else {
        this.pageLabels.changeAutoPayEnrollText = this.pageLabels.changeAutoPayEnrollText.replace('{{endingnumber}}', 'ending in ' + this.changeAutoPayData.LastFour);
      }
    }
  }

  getPreferredPayments (): void {
    this.payment.getPreferredPayments(this.accountDetail.AccountNumber)
      .pipe(
        tap((data: any) => {
          this.storage.setSession(StorageEnum.PaymentMethods, data.PaymentMethods);

          if (!data.PaymentMethods) {
            data.PaymentMethods = [];
          }

          this.payment.addNewCardAndBankToPaymethodList(data.PaymentMethods, this.pageLabels.paymentMethodNewBankText, this.pageLabels.paymentMethodNewCardText);
          this.paymethods = this.payment.sortPaymentMethods(data.PaymentMethods, this.accountDetail.IsLimitedPaymentOptions);
          this.utils.hideSpinner();
          this.pageReady = true;
        })).subscribe();
  }

  paymethodChanged (): void {
    const selectedPaymethod: PaymentMethod = this.autopayForm.get('paymethod')?.value;
    const selectedPaymethodType = this.payment.isCardBankDigital(selectedPaymethod.PaymentAccountType);
    if (selectedPaymethodType === 'BANK') {
      if (!selectedPaymethod.ReferenceId) {
        this.openBankAccountForm();
      }
    } else if (selectedPaymethodType === 'DIGITAL') {
      if (!selectedPaymethod.ReferenceId) {
        this.openDigitalPayment(selectedPaymethod.PaymentAccountType!);
      }
    } else if (selectedPaymethodType === 'CARD') {
      this.openCreditCardForm();
    } else if (selectedPaymethodType === 'CASH') {
      this.utils.consoleLog('cash money');
    } else {
      this.utils.consoleLog(`selectedPaymethodType: ${selectedPaymethodType}`);
    }
  }

  openCreditCardForm (): void {
    const dialogRef = this.dialog.open(CreditCardFormComponent, { ariaLabel: 'Credit Card', disableClose: true, autoFocus: 'first-heading', panelClass: 'gs-dialog', data: { payMethod: this.autopayForm.get('paymethod')?.value, parentForm: 'autopay' } });

    dialogRef.afterClosed().subscribe((result: PaymentDialogResult) => {
      this.utils.showSpinner();
      if (result.Type === DialogResultType.Cancel) {
        this.autopayForm.patchValue({ paymethod: null });
        this.utils.hideSpinner();
      } else if (result.Type === DialogResultType.Update) {
        // Find Record in the List and Update it
        this.paymethods = this.paymethods?.map(pm => pm.ReferenceId === result.PayMethod.ReferenceId ? result.PayMethod : pm);
        // select Update record
        if (!this.utils.isNullOrWhitespace(result.PayMethod.ReferenceId)) {
          const newSelectedMethod = this.paymethods?.find(pm => {
            return pm.ReferenceId === result.PayMethod.ReferenceId;
          });
          this.autopayForm.patchValue({ paymethod: newSelectedMethod });
        }
      } else if (result.Type === DialogResultType.Add) {
        this.payment.addNewPayMethod(this.paymethods!, result.PayMethod);
        this.paymethods = this.payment.sortPaymentMethods(this.paymethods!, this.accountDetail.IsLimitedPaymentOptions);
        if (!this.utils.isNullOrWhitespace(result.PayMethod.ReferenceId)) {
          const newSelectedMethod = this.paymethods.find(pm => {
            return pm.ReferenceId === result.PayMethod.ReferenceId;
          });
          this.autopayForm.patchValue({ paymethod: newSelectedMethod });
        }
      } else if (result.Type === DialogResultType.Error) {
        this.autopayForm.patchValue({ paymethod: null });
        this.showFormErrorLabel(result.ErrorMessage);
        this.utils.hideSpinner();
      }
      this.storage.setSession(StorageEnum.PaymentMethods, this.paymethods);
      this.utils.hideSpinner();
    });
  }

  openBankAccountForm (): void {
    const dialogRef = this.dialog.open(BankAccountFormComponent, { ariaLabel: 'Bank Account', disableClose: true, autoFocus: 'first-heading', panelClass: 'gs-dialog', data: 'autopay' });

    dialogRef.afterClosed().subscribe((result: PaymentDialogResult) => {
      if (result.Type === DialogResultType.Cancel) {
        this.autopayForm.patchValue({ paymethod: null });
        this.utils.hideSpinner();
      } else if (result.Type === DialogResultType.Add) {
        this.payment.addNewPayMethod(this.paymethods!, result.PayMethod);
        this.paymethods = this.payment.sortPaymentMethods(this.paymethods!, this.accountDetail.IsLimitedPaymentOptions);
        this.storage.setSession(StorageEnum.PaymentMethods, this.paymethods);
        if (!this.utils.isNullOrWhitespace(result.PayMethod.ReferenceId)) {
          const newSelectedMethod = this.paymethods.find(pm => {
            return pm.ReferenceId === result.PayMethod.ReferenceId;
          });
          this.autopayForm.patchValue({ paymethod: newSelectedMethod });
        }
      } else if (result.Type === DialogResultType.Error) {
        this.autopayForm.patchValue({ paymethod: null });
        this.showFormErrorLabel(result.ErrorMessage);
        this.utils.hideSpinner();
      }
      this.utils.hideSpinner();
    });
  }

  openDigitalPayment (paymentType: PaymentAccountType): void {
    const page = window.location.origin + '/autopay-enroll';
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    this.paymentus.getDigitalPaymentToken(this.accountDetail?.AccountNumber!, paymentType, page)
      .pipe(
        catchError((dataError: any) => {
          void this.router.navigateByUrl('/error-page');
          return throwError(() => new Error(dataError));
        }),
        tap((data: string) => {
          const url = environment.paymentusDigitalTokenUrl() + '?authToken=' + data;
          this.createIframe(url, page, paymentType);
        })).subscribe();
  }

  createIframe (url: string, page: string, paymentAccountType: PaymentAccountType): void {
    const dialogRef = this.dialog.open(DigitalPaymentModalComponent, {
      ariaLabel: paymentAccountType.toString(),
      disableClose: true,
      autoFocus: 'first-heading',
      panelClass: 'gs-dialog',
      data: {
        url,
        page,
        paymentAccountType
      },
      height: '500px',
      width: '500px'
    });

    dialogRef.afterClosed().subscribe((result: PaymentDialogResult) => {
      if (result.Type === DialogResultType.Add) {
        this.paymethods?.push(result.PayMethod);
        this.paymethods = this.payment.sortPaymentMethods(this.paymethods!, this.accountDetail.IsLimitedPaymentOptions);
        if (!this.utils.isNullOrWhitespace(result.PayMethod.ReferenceId)) {
          const newSelectedMethod = this.paymethods.find(pm => {
            return pm.ReferenceId === result.PayMethod.ReferenceId;
          });
          this.autopayForm.patchValue({ paymethod: newSelectedMethod });
        }
      } else if (result.Type === DialogResultType.Cancel) {
        this.autopayForm.patchValue({ paymethod: null });
        this.utils.hideSpinner();
      }
      this.utils.hideSpinner();
    });
  }

  showFormErrorLabel (errorMessage?: string): void {
    this.formErrorMessage = errorMessage;
    setTimeout(() => {
      const errorEl = document.getElementById('formErrorLabel');
      errorEl?.focus();
    }, 10);
  }

  enrollAutopay (): void {
    this.formErrorMessage = '';
    this.autopayForm.markAllAsTouched();
    this.utils.showSpinner();

    const todaysDate = format(new Date().toString(), 'MM/dd/yyyy');
    const billDueDate = format(toDate(this.accountDetail.CurrentBalanceDueDate!).toString(), 'MM/dd/yyyy');
    const paymentDate = this.accountDetail.FuturePayment?.Info?.PaymentDate;

    if (this.autopayForm.valid) {
      const selectedPayMethod: PaymentMethod = this.autopayForm.get('paymethod')?.value;
      // submit form
      const data = { accountDetail: this.accountDetail, payMethod: selectedPayMethod };

      this.payment.enrollAutoPay(data).subscribe({
        error: (e) => {
          this.setErrorData(e.message);
          void this.router.navigateByUrl('/error-page');
        },
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        next: async (data: any) => {
          this.accountDetail.AutoPay = data;
          this.accountDetail.IsAutoPay = true;

          const builderSuccess = this.pageLabels.enrollSuccessData.value.data;
          const successData = new SuccessData();
          successData.Header = builderSuccess.header;
          successData.DetailPreMessage = builderSuccess.detailPreMessage;
          successData.DetailPostMessage = builderSuccess.detailPostMessage;

          if (this.accountDetail.FuturePayment) {
            // If a future dated payment is scheduled and the draft date is = or > current bill due date. Cancel the future dated payment.

            const futurePaymentDate = format(toDate(paymentDate!).toString(), 'MM/dd/yyyy');
            if (futurePaymentDate >= billDueDate) {
              const futurePayment = this.payment.buildCancelFutureDatePaymentRequest();

              this.payment.postFuturePayment(futurePayment).subscribe({
                error: (e) => {
                // TO DO: Lori created new story for this case
                },
                next: (data: any) => {
                  this.accountDetail.FuturePayment = null;
                }
              });
              const fdrPostNotification = builderSuccess.postNotificationList.find((message: any) => {
                return message.messageId === 'fdpcancelled';
              });
              successData.PostNotificatinoList.push(fdrPostNotification.message);
            }
            this.storage.setSession(StorageEnum.AccountDetail, this.accountDetail);

            const detail = new SuccessDetails();
            detail.Label = builderSuccess.details[0].detailLabel;
            detail.Value = this.getEnrollSuccessDescription();
            successData.Details.push(detail);

            const cta = new CTA();
            let autoPayCTA;

            if (todaysDate === billDueDate) {
              autoPayCTA = builderSuccess.ctaList.find((cta: any) => {
                return cta.ctaId === 'payment';
              });
              cta.Label = autoPayCTA.ctaLabel;
              cta.AriaLabel = autoPayCTA.ctaAriaLabel;
              cta.Link = autoPayCTA.ctaUrl;
              successData.CTAList.push(cta);
              const billDuePostNotification = builderSuccess.postNotificationList.find((message: any) => {
                return message.messageId === 'billtoday';
              });
              successData.PostNotificatinoList.push(billDuePostNotification.message);
            } else if (this.accountDetail.PastDueBalance! > 0) {
              const pastDueNotification = builderSuccess.postNotificationList.find((message: any) => {
                return message.messageId === 'pastdue';
              });
              successData.PostNotificatinoList.push(pastDueNotification.message);
              autoPayCTA = builderSuccess.ctaList.find((cta: any) => {
                return cta.ctaId === 'payment';
              });
              cta.Label = autoPayCTA.ctaLabel;
              cta.AriaLabel = autoPayCTA.ctaAriaLabel;
              cta.Link = autoPayCTA.ctaUrl;
              successData.CTAList.push(cta);
            } else {
              autoPayCTA = builderSuccess.ctaList.find((cta: any) => {
                return cta.ctaId === 'accountsummary';
              });
              cta.Label = autoPayCTA.ctaLabel;
              cta.AriaLabel = autoPayCTA.ctaAriaLabel;
              cta.Link = autoPayCTA.ctaUrl;
              successData.CTAList.push(cta);
            }

            this.storage.setSession(StorageEnum.SuccessData, successData);
            void this.router.navigateByUrl('/autopay-enroll-success');
          } else {
            this.storage.setSession(StorageEnum.AccountDetail, this.accountDetail);

            const detail = new SuccessDetails();
            detail.Label = builderSuccess.details[0].detailLabel;
            detail.Value = this.getEnrollSuccessDescription();
            successData.Details.push(detail);

            const cta = new CTA();
            let autoPayCTA;

            if (todaysDate === billDueDate) {
              autoPayCTA = builderSuccess.ctaList.find((cta: any) => {
                return cta.ctaId === 'payment';
              });
              cta.Label = autoPayCTA.ctaLabel;
              cta.AriaLabel = autoPayCTA.ctaAriaLabel;
              cta.Link = autoPayCTA.ctaUrl;
              successData.CTAList.push(cta);
              const billDuePostNotification = builderSuccess.postNotificationList.find((message: any) => {
                return message.messageId === 'billtoday';
              });
              successData.PostNotificatinoList.push(billDuePostNotification.message);
            } else if (this.accountDetail.PastDueBalance! > 0) {
              const pastDueNotification = builderSuccess.postNotificationList.find((message: any) => {
                return message.messageId === 'pastdue';
              });
              successData.PostNotificatinoList.push(pastDueNotification.message);
              autoPayCTA = builderSuccess.ctaList.find((cta: any) => {
                return cta.ctaId === 'payment';
              });
              cta.Label = autoPayCTA.ctaLabel;
              cta.AriaLabel = autoPayCTA.ctaAriaLabel;
              cta.Link = autoPayCTA.ctaUrl;
              successData.CTAList.push(cta);
            } else {
              autoPayCTA = builderSuccess.ctaList.find((cta: any) => {
                return cta.ctaId === 'accountsummary';
              });
              cta.Label = autoPayCTA.ctaLabel;
              cta.AriaLabel = autoPayCTA.ctaAriaLabel;
              cta.Link = autoPayCTA.ctaUrl;
              successData.CTAList.push(cta);
            }
            this.storage.setSession(StorageEnum.SuccessData, successData);
            void this.router.navigateByUrl('/autopay-enroll-success');
          }
        }
      });
    } else {
      const errorMessage = this.storage.getSession(StorageEnum.GlobalVars).formError;
      this.showFormErrorLabel(errorMessage);
      this.utils.hideSpinner();
    }
  }

  getEnrollSuccessDescription (): string {
    const selectedPayMethod: PaymentMethod = this.autopayForm.get('paymethod')?.value;

    if (selectedPayMethod.Nickname) {
      if (selectedPayMethod.LastFour) {
        return selectedPayMethod.Nickname + ' ending in ' + selectedPayMethod.LastFour;
      } else {
        return selectedPayMethod.Nickname;
      }
    } else {
      return selectedPayMethod.Description!;
    }
  }

  setErrorData (errorCode: string): void {
    const error = this.autoPayErrors.find(error => {
      return error.error.value.data.errorCode === errorCode;
    });
    const autoPayError = error.error.value.data;
    const errorLabels = this.pageLabels.enrollErrorData.value.data.ctaList[0];
    const errorDetail = new ErrorData();
    errorDetail.Header = autoPayError.errorHeader;
    errorDetail.Subheader = autoPayError.displayMessage;
    errorDetail.CtaButton.Label = errorLabels.ctaLabel;
    errorDetail.CtaButton.Link = errorLabels.ctaUrl;
    errorDetail.CtaButton.AriaLabel = errorLabels.ctaAriaLabel;
    this.storage.setSession(StorageEnum.ErrorData, errorDetail);
  }
}
