<main *ngIf="pageLabels$ | async as pageLabels" class="container">
    <div class="header">
        <h2>{{ pageLabels.bankPaymentTitle }}</h2>
    </div>
    <div class="form-wrapper">
        @if (formErrorMessage != '') {
        <div id="formErrorLabel" tabindex="0" class="gs-post-notification-error">
            <i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
            <span [innerHTML]="formErrorMessage"></span>
        </div>
        }
        <mat-dialog-content>
            <form [formGroup]="bankAccountForm" class="bank-form" (ngSubmit)="onSubmit()">
                <mat-form-field class="routing-number-container" appearance="outline" subscriptSizing="dynamic">
                    <mat-label id="routingNumberLabel">{{ pageLabels.routingNumberLabel }}</mat-label>
                    <input matInput formControlName="routingNumber" id="routingNumber" maxlength="9"
                        aria-labelledby="routingNumberLabel routingNumberRequiredError routingNumberInvalidError">
                    @if (bankAccountForm.get('routingNumber')?.hasError('required')) {
                    <mat-error id="routingNumberRequiredError">{{
                        pageLabels.routingNumberErrorText }}</mat-error>
                    }
                    @else if (bankAccountForm.get('routingNumber')?.hasError('invalidRoutingNumber')) {
                    <mat-error id="routingNumberInvalidError">{{
                        pageLabels.routingNumberErrorText }}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field class="gs-form-field account-number-field" appearance="outline"
                    subscriptSizing="dynamic">
                    <mat-label id="accountNumberLabel">{{ pageLabels.accountNumberLabel }}</mat-label>
                    <input matInput formControlName="accountNumber" id="accountNumber" maxlength="19" (blur)="checkForDuplicate()"
                        aria-labelledby="accountNumberLabel accountNumberRequiredError accountNumberInvalidError duplicatePayMethodError">
                    @if (bankAccountForm.get('accountNumber')?.hasError('required')) {
                    <mat-error id="accountNumberRequiredError">{{
                        pageLabels.accountNumberErrorText }}</mat-error>
                    }
                    @else if (bankAccountForm.get('accountNumber')?.hasError('invalidBankAccount')) {
                    <mat-error id="accountNumberInvalidError">{{
                        pageLabels.accountNumberErrorText }}</mat-error>
                    }
                    @else if (bankAccountForm.get('accountNumber')?.hasError('duplicatePayMethod')) {
                    <mat-error id="duplicatePayMethodError">{{ pageLabels.duplicatePaymentMethodErrorText }}</mat-error>
                    }
                </mat-form-field>
                <button id="findAccount" type="button" class="find-bankAccount button-link" (click)="toggleCheckImage()"
                    aria-expanded="false">{{
                    pageLabels.findBankAccountRoutingText }}</button>
                <div *ngIf="showBankAccountCheckImage" class="bankAccountCheck-image">
                    <img src="assets/images/CheckFindAccount.png" [alt]="pageLabels.checkImageAltText"
                        class="check-image">
                </div>
                <mat-form-field class="gs-form-field account-field" appearance="outline" color="primary">
                    <mat-label id="accountTypeLabel">{{ pageLabels.accountTypeLabel }}</mat-label>
                    <mat-select formControlName="accountType" id="accountTypeSelect">
                        <mat-option value="Checking">Checking</mat-option>
                        <mat-option value="Savings">Savings</mat-option>
                    </mat-select>
                </mat-form-field>
                @if (showPrefSection) {
                <p id="preferences-text">{{pageLabels.preferencesLabel}}</p>
                <div class="preferences">
                    <div class="row">
                        <div class="column">
                            <mat-checkbox formControlName="saveWallet" (change)="saveWalletOnChange($event)">{{pageLabels.savetowalletLabel}}</mat-checkbox>
                        </div>
                        @if (isWalletChecked) {
                        <div class="column">
                            <mat-checkbox formControlName="setDefault">{{pageLabels.setasefaultLabel}}</mat-checkbox>
                        </div>
                        }
                    </div>
                </div>
                @if (isWalletChecked) {
                <mat-form-field appearance="outline">
                    <mat-label>{{pageLabels.nicknameLabel}}</mat-label>
                    <input matInput type="text" formControlName="nickName" placeholder="" id="nickname" maxlength="45" (blur)="checkForDuplicateNickName()"
                        aria-labelledby="nickNameReqError nickNameInvalidError nickNameInvalidCharError nickNameDuplicateError">
                    @if (bankAccountForm.get('nickName')?.hasError('required')) {
                    <mat-error id="nickNameReqError">{{pageLabels.nicknameErrorText}}</mat-error>
                    }
                    @else if (bankAccountForm.get('nickName')?.hasError('invalidNickname')) {
                    <mat-error id="nickNameInvalidError">{{pageLabels.nicknameErrorText}}</mat-error>
                    }
                    @else if (bankAccountForm.get('nickName')?.hasError('invalidCharacters')) {
                    <mat-error id="nickNameInvalidCharError">{{pageLabels.nicknameErrorText}}</mat-error>
                    }
                    @else if (bankAccountForm.get('nickName')?.hasError('duplicateNickName')) {
                    <mat-error id="nickNameDuplicateError">{{pageLabels.nickNameDuplicateErrorText}}</mat-error>
                    }
                </mat-form-field>
                }
                <mat-label id="saveLabel" style="display: none;">{{ pageLabels.saveButtonText }}</mat-label>
                }
                <div class="buttons-wrapper">
                        <button id="cancelBtn" type="button" mat-flat-button
                            class="gs-form-button-secondary cancel-button" color="primary" (click)="onCancelClick()">
                            {{ pageLabels.cancelButtonText }}
                        </button>
                        <button id="saveBtn" mat-flat-button class="gs-form-button-primary" color="primary"
                            aria-labelledby="accountNumberRequiredError accountNumberInvalidError routingNumberRequiredError routingNumberInvalidError saveLabel">
                            {{ pageLabels.saveButtonText }}
                        </button>
                </div>
            </form>
        </mat-dialog-content>
    </div>
</main>