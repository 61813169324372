/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { CmsService } from './shared/services/cms.service';
import { StorageEnum } from './shared/enums/storage.enum';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from './shared/services/storage.service';
import { firstValueFrom, map } from 'rxjs';
import { UtilService } from './shared/services/util.service';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { MobileAppBannerComponent } from './shared/components/mobile-app-banner/mobile-app-banner.component';
import { CommonModule } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../environments/environment.development';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  imports: [RouterOutlet, SpinnerComponent, MobileAppBannerComponent, CommonModule]
})
export class AppComponent {
  title = 'Gas South Online Account Service';
  deviceInfo: any;
  env = environment;

  constructor (
    private readonly cms: CmsService,
    private readonly storage: StorageService,
    private readonly utils: UtilService,
    private readonly deviceService: DeviceDetectorService
  ) {}

  async ngOnInit (): Promise<void> {
    const res = await firstValueFrom(this.cms.getContent('global-variables', 'Global Variables')
      .pipe(map(data => {
        const copyData = JSON.parse(JSON.stringify(data));
        const errors: any[] = [];

        data.apiErrors.forEach((err: any) => {
          errors.push({ apiError: err.apiError.value.data });
        });
        copyData.apiErrors = errors;

        const paymentusErrors: any[] = [];
        data.paymentusErrors.forEach((err: any) => {
          paymentusErrors.push({ paymentusError: err.paymentusError.value.data });
        });
        copyData.paymentusErrors = paymentusErrors;

        const paymentErrors: any[] = [];
        data.paymentErrors.forEach((err: any) => {
          paymentErrors.push({ paymentError: err.paymentError.value.data });
        });
        copyData.paymentErrors = paymentErrors;

        return copyData;
      })));
    this.storage.setSession(StorageEnum.GlobalVars, res);
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.env.deviceInfo = this.deviceInfo;
  }

  showSpinner (): void {
    this.utils.showSpinner();
  }
}
