import { Component, Inject, PLATFORM_ID, type OnInit } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, isPlatformBrowser } from '@angular/common';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Subscription, tap, type Observable } from 'rxjs';
import { environment } from '../../../environments/environment.development';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../shared/services/util.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { CmsService } from '../../shared/services/cms.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Router } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AccountService } from '../../shared/services/account.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from '../../shared/services/storage.service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { StorageEnum } from '../../shared/enums/storage.enum';
import { AccountDetail } from '../../shared/models/account-detail';

@Component({
  selector: 'app-account-summary',
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatDividerModule,
    DatePipe,
    CurrencyPipe,
    CommonModule
  ],
  templateUrl: './account-summary.component.html',
  styleUrl: './account-summary.component.scss'
})
export class AccountSummaryComponent implements OnInit {
  panelOpenState = false;
  blogs$!: Observable<any>;
  env = environment;
  isMobile = false;
  account$!: Subscription;
  isBrowser = false;
  pageReady = false;
  accountDetail: AccountDetail = new AccountDetail();

  constructor (
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) readonly platformId: Object,
    private readonly utils: UtilService,
    private readonly cms: CmsService,
    private readonly router: Router,
    private readonly acct: AccountService,
    private readonly storage: StorageService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit (): void {
    this.getAccountData();
    this.blogs$ = this.getBlogs();
    this.isMobile = this.utils.isMobile(992);
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => {
        this.isMobile = this.utils.isMobile(992);
      });
    }
  }

  getBlogs (): Observable<any> {
    return this.cms.getBlogs(3);
  }

  formatBlogDate (dateString: string): string {
    const d = new Date(dateString);
    const dMonth = d.getMonth() + 1;
    const dDay = d.getDate();
    const dYear = d.getFullYear();

    return dMonth.toString() + '/' + dDay.toString() + '/' + dYear.toString();
  }

  async navToPayment (): Promise<void> {
    this.utils.showSpinner();
    await this.router.navigate(['/payment']);
  }

  async navToCancelFuturePayment (): Promise<void> {
    this.utils.showSpinner();
    await this.router.navigate(['/cancel-future-payment']);
  }

  getAccountData (): void {
    if (this.isBrowser) {
      const acct = this.storage.getSession(StorageEnum.LoginResponse);
      const accountNumber = acct?.Accounts[0]?.AccountNumber;

      this.acct.getAccountDetail(accountNumber)
        .pipe(
          tap((data: AccountDetail) => {
            this.accountDetail = data;
            this.storage.setSession(StorageEnum.AccountDetail, data);
            this.pageReady = true;
            this.utils.hideSpinner();
          })
        ).subscribe();
    }
  }
}
