import { Component, type OnInit } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from '../../services/storage.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../services/util.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Router } from '@angular/router';

import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { StorageEnum } from '../../enums/storage.enum';
import { ErrorData } from '../../models/error-data';

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [
    MatDividerModule,
    MatButtonModule
  ],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss'
})

export class ErrorPageComponent implements OnInit {
  pageReady = false;
  isAutopay = false;
  data: ErrorData = new ErrorData();

  constructor (
    private readonly router: Router,
    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    private storage: StorageService,
    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    private utils: UtilService
  ) {}

  ngOnInit (): void {
    this.data = this.storage.getSession(StorageEnum.ErrorData);
    this.utils.hideSpinner();
  }

  async goToRedirectPage (): Promise<void> {
    await this.router.navigateByUrl('/payment');
  }
}
