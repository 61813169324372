import { type AbstractControl, type ValidationErrors, type ValidatorFn } from '@angular/forms';
import { PaymentAccountType } from '../shared/enums/paymentaccounttype.enum';

export function OtherAmountValidator (): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const value = group.get('otheramount')?.value;
    const numVal = parseFloat(value);
    const payType = group.get('paymethod')?.value?.PaymentAccountType ?? PaymentAccountType.Unknown;
    const status = group.get('otheramount')?.status;

    // do not validate it control is disabled
    if (status !== 'DISABLED') {
      if (isNaN(numVal)) {
        return { InvalidPaymentAmount: true };
      }

      if (numVal < 1) {
        return { MinimumPaymentError: true };
      }

      if (payType === PaymentAccountType.Banking && numVal > 1000000) {
        return { MaximumBankPaymentError: true };
      }

      if (payType !== PaymentAccountType.Unknown && payType !== PaymentAccountType.Banking && numVal > 50000) {
        return { MaximumCardPaymentError: true };
      }
    }
    return null;
  };
}
