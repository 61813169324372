<main *ngIf="pageLabels$ | async as pageLabels" class="container">
  <div class="header">
    @if (isPreferredPaymentMethod) {
      <h2>{{pageLabels.preferredCardPaymentTitleText}}</h2>
    }
    @else {
      <h2>{{pageLabels.cardPaymentTitle}}</h2>
    }
  </div>
  <div class="form-wrapper">
    @if (formErrorMessage != '') {
    <div id="formErrorLabel" tabindex="0" class="gs-post-notification-error">
      <i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
      <span [innerHTML]="formErrorMessage"></span>
    </div>
    }
    @if (cardExpired && showCardExpiredMessage) {
      <p id="cardExpired" class="gs-post-notification-error">{{pageLabels.creditCardExpiredText}}</p>
    }
    <mat-dialog-content>
      <!-- Card Number -->
      @if (isPreferredPaymentMethod) {
        <p id="paymethod-title" class="payMethod-title">{{pageLabels.preferredPaymentMethodTitleText}}</p>
        <p id="paymethod-description"  class="payMethod-description">{{ preferredPayMethodDescription }}</p>
      }
      <form [formGroup]="creditCardForm" class="card-form" (ngSubmit)="onSubmit()">
        @if (isPreferredPaymentMethod) {
          <p id="preferred-card" class="preferred-card-title">{{pageLabels.preferredCardNumberTitleText}}</p>
          <input id="payment-lastFour-input" readonly matInput aria-labelledby="preferred-card" class="payMethod-last-four" value="**********{{selectedPaymethod?.LastFour}}">
        }
        @else {
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>{{pageLabels.cardNumberLabel}}</mat-label>
            <input #cardNumber matInput type="text" formControlName="cardNumber" placeholder="" id="card-number" maxlength="19" (blur)="checkForDuplicate()"
              aria-labelledby="cardNumberReq cardNumberInvalid cardNumberTypeError cardNumberLuhnError duplicatePayMethodError"
              [mask]="cardNumberMask" (input)="checkMask(cardNumber.value)">
            @if (creditCardForm.get('cardNumber')?.hasError('required')) {
            <mat-error id="cardNumberReq">{{pageLabels.cardNumberErrorText}}</mat-error>
            }
            @else if (creditCardForm.get('cardNumber')?.hasError('invalidCardNumber')) {
            <mat-error id="cardNumberInvalid">{{pageLabels.cardNumberErrorText2}}</mat-error>
            }
            @else if (creditCardForm.get('cardNumber')?.hasError('invalidCardType')) {
            <mat-error id="cardNumberTypeError">{{pageLabels.cardNumberErrorText3}}</mat-error>
            }
            @else if (creditCardForm.get('cardNumber')?.hasError('invalidLuhnCheck')) {
            <mat-error id="cardNumberLuhnError">{{pageLabels.cardNumberErrorText}}</mat-error>
            }
            @else if (creditCardForm.get('cardNumber')?.hasError('duplicatePayMethod')) {
              <mat-error id="duplicatePayMethodError">{{ pageLabels.duplicatePaymentMethodErrorText }}</mat-error>
              }
          </mat-form-field>
          <p class="error-bottom-margin" *ngIf="!hasError"></p>
        }

        <!-- Zip Code -->
        @if (!isPreferredPaymentMethod) {
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>{{pageLabels.zipCodeLabel}}</mat-label>
            <input matInput type="text" formControlName="zipCode" placeholder="" id="zip-code" maxlength="5">
            @if (creditCardForm.get('zipCode')?.hasError('required')) {
            <mat-error id="zipCodeReqError">{{pageLabels.zipCodeErrorText}}</mat-error>
            }
            @else if (creditCardForm.get('zipCode')?.hasError('invalidZipCode')) {
            <mat-error id="zipCodeInvalidError">{{pageLabels.zipCodeErrorText}}</mat-error>
            }
          </mat-form-field>
        }
        <div class="row">
          <div class="column security-code">
            <!-- Security Code -->
            <mat-form-field appearance="outline" class="remove-margin-bottom" subscriptSizing="dynamic">
              <mat-label>{{pageLabels.securityCodeLabel}}</mat-label>
              <input matInput type="text" formControlName="securityCode" placeholder="" id="security-code"
                maxlength="4">
              @if (creditCardForm.get('securityCode')?.hasError('required')) {
              <mat-error id="securityCodeReqError">{{pageLabels.securityCodeErrorText}}</mat-error>
              }
              @else if (creditCardForm.get('securityCode')?.hasError('invalidSecurityCode')) {
              <mat-error id="securityCodeInvalidError">{{pageLabels.securityCodeErrorText}}</mat-error>
              }
            </mat-form-field>
            <button id="findSecurityCode" class="find-securityCode button-link" (click)="toggleSecurityCodeImages()"
              aria-expanded="false" type="button">
              {{pageLabels.findSecurityCodeLabel}}</button>

            <!-- Security Code Images -->
            <div *ngIf="showSecurityCodeImages" class="security-code-images">
              <img *ngIf="isVisaOrMastercardOrDiscover" src="assets/images/Visa_MC_Discover_Solo.png"
                alt="Visa/MasterCard/Discover Security Code" class="card-image">
              <img *ngIf="isAmex" src="assets/images/American_Express_Solo_front.png" alt="American Express Security Code"
                class="card-image">
              <img *ngIf="isUnknownCardType" src="assets/images/Visa_MC_Discover.png"
                alt="Visa/MasterCard/Discover Security Code">
              <img *ngIf="isUnknownCardType" src="assets/images/American_Express_front.png"
                alt="American Express Security Code">
            </div>
          </div>

            <div class="column expiration-date">
              <!-- Expiration Date -->
              @if (!isPreferredPaymentMethod || cardExpired) {
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>{{pageLabels.expirationDateLabel}}</mat-label>
                <input matInput type="text" formControlName="expirationDate" placeholder="MM/YY" id="expiration-date"
                  (blur)="onExpirationDateBlur($event)" [dropSpecialCharacters]="false" mask="00/00">
                @if (creditCardForm.get('expirationDate')?.hasError('required')) {
                <mat-error id="expirationDateReqError">{{pageLabels.expirationDateErrorText}}</mat-error>
                }
                @else if (creditCardForm.get('expirationDate')?.hasError('invalidExpirationDate')) {
                <mat-error id="expirationDateInvalidError">{{pageLabels.invalidExpirationDateErrorText}}</mat-error>
                }
              </mat-form-field>
            }
            </div>
        </div>
        @if (showPreferencesSection) {
          <p href="#" id="preferences-text">{{pageLabels.preferencesLabel}}</p>
          <div class="preferences">
            <div class="row">
              <div class="column">
                <mat-checkbox formControlName="saveWallet" (change)="saveWalletOnChange($event)">{{pageLabels.savetowalletLabel}}</mat-checkbox>
              </div>
              @if (isWalletChecked) {
                <div class="column">
                  <mat-checkbox formControlName="setDefault">{{pageLabels.setasefaultLabel}}</mat-checkbox>
                </div>
              }
            </div>
          </div>
          <!-- Nickname -->
           @if (isWalletChecked) {
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
              <mat-label>{{pageLabels.nicknameLabel}}</mat-label>
              <input matInput type="text" formControlName="nickname" placeholder="" id="nickname" maxlength="45" (blur)="checkForDuplicateNickName()"
                aria-labelledby="nickNameReqError nickNameInvalidError nickNameInvalidCharError nickNameDuplicateError">
              @if (creditCardForm.get('nickname')?.hasError('required')) {
              <mat-error id="nickNameReqError">{{pageLabels.nicknameErrorText}}</mat-error>
              }
              @else if (creditCardForm.get('nickname')?.hasError('invalidNickname')) {
              <mat-error id="nickNameInvalidError">{{pageLabels.nicknameErrorText}}</mat-error>
              }
              @else if (creditCardForm.get('nickname')?.hasError('invalidCharacters')) {
              <mat-error id="nickNameInvalidCharError">{{pageLabels.nicknameErrorText}}</mat-error>
              }
              @else if (creditCardForm.get('nickname')?.hasError('duplicateNickName')) {
              <mat-error id="nickNameDuplicateError">{{pageLabels.duplicateNickNameErrorText}}</mat-error>
              }
            </mat-form-field>
           }
        }
        <!-- Buttons Wrapper -->
        <div class="buttons-wrapper">
          <!-- Cancel Button -->
            <button  type="button" mat-flat-button class="gs-form-button-secondary cancel-button" (click)="onCancelClick()"
              color="primary">
              {{pageLabels.cancelButtonText}}
            </button>

            <!-- Save Button -->
            <button mat-flat-button class="gs-form-button-primary" color="primary">
              {{pageLabels.saveButtonText}}
            </button>
        </div>
      </form>
    </mat-dialog-content>
  </div>
</main>