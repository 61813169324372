/* eslint-disable @typescript-eslint/consistent-type-imports */
import { BankAccountType } from '../enums/bankaccounttype.enum';
import { APIError } from './apierror';
import { Person } from './person';

export class Payment {
  public Amount?: number;
  public Date?: Date;
  public AccountNumber?: string;
  public Person: Person = new Person();
  public IsInSeverance?: boolean;
  public Token?: string;
  public LastFour?: string;
  public PaymentAccountType?: string;
  public Nickname?: string;
  public SaveToAccount?: boolean;
  public SaveAsDefault?: boolean;
  public UserName?: string;
  public CurrentBalance?: number;
  public PastDueBalance?: number;
  public MinimumPayment?: number;
}

export class CardPayment extends Payment {
  public Cvv?: number;
  public PostalCode?: string;
  public Fee?: string;
  public ExpirationDate?: string;
}

export class BankPayment extends Payment {
  public BankRoutingNumber?: string;
  public BankAccountNumber?: string;
  public BankAccountType?: keyof typeof BankAccountType;
}

export class PaymentResponse {
  public AccountNumber?: string;
  public ConfirmationNumber?: string;
  public CurrentBalance?: number;
  public Nickname?: string;
  public PastDueBalance?: number;
  public MinimumPayment?: number;
  public SONPCode?: string;
  public PaymentType?: string;
  public Error?: APIError;
}

export class FuturePayment extends Payment {
  public ActionCode?: string;
  public ReferenceNumber?: string;
  public CustomerClass?: string;
  public BankAccountType?: string;
}
