export const environment = {
  builderPublicKey: '1b64838a2fd245b29e8bc7c992bd9a8a',
  digitalProductsBuilderKey: '4caa3a425c85496e9b99e391d030985f',
  contentAPIUrl: 'https://cdn.builder.io/api/v3/content/',
  enableLogging: true,
  baseUrl: 'https://gs-qa-east-oas-api.azurewebsites.net',
  authUrl: 'https://gs-qa-east-oas-auth-api.azurewebsites.net',
  blogBaseUrl: 'https://www.gassouth.com/blog',
  deviceInfo: null,
  ClientId: 'GS-5E027899-FB73-47B2-8E23-AA0985EF4B82',
  OASUrl (local = false) {
    return local ? 'https://localhost:7225/api' : this.baseUrl + '/api';
  },
  paymentusTokenUrl () {
    return 'https://secure4.paymentus.com/api/v2/profiles/gass';
  },
  paymentusDigitalTokenUrl () {
    return 'https://secure4.paymentus.com/xotp/pm/GASS';
  },
  paymentusUrl () {
    return 'https://secure4.paymentus.com';
  },
  AuthUrl (local = false) {
    return local ? 'https://localhost:7264/api' : this.authUrl + '/api';
  }
};
