import { type HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { of, tap } from 'rxjs';
import { environment } from '../../environments/environment.development';
import { StorageService } from './services/storage.service';

export const CacheInterceptor: HttpInterceptorFn = (req, next) => {
  const storage = inject(StorageService);
  const endpointsToCache = [
    environment.contentAPIUrl
  ];
  let cacheResponse = false;
  for (let i = 0; i < endpointsToCache.length; i++) {
    if (req.url.includes(endpointsToCache[i])) {
      cacheResponse = true;
      break;
    }
  }
  if (cacheResponse) {
    const cachedResponse: HttpResponse<any> | null = storage.getCache(req.url);
    if (cachedResponse) {
      return of(new HttpResponse<any>(Object.create(cachedResponse)));
    }
    // else make req and cache
    return next(req).pipe(
      tap(response => {
        if (response instanceof HttpResponse) {
          storage.setCache(req.url, response);
        }
      })
    );
  }

  return next(req);
};
