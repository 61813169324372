/* eslint-disable @typescript-eslint/unbound-method */
import { Component, type OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { type Observable } from 'rxjs';
import { type ErrorStateMatcher } from '@angular/material/core';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { CmsService } from '../../shared/services/cms.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../shared/services/util.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { FormBuilder, type FormGroup, Validators, FormsModule, ReactiveFormsModule, type FormControl, type FormGroupDirective, type NgForm, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  pageLabels$!: Observable<any>;
  registerForm!: FormGroup;
  matcher = new MyErrorStateMatcher();

  constructor (
    private readonly cms: CmsService,
    private readonly utils: UtilService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit (): void {
    this.pageLabels$ = this.cms.getContent('oas-register', 'register');

    // Initialize the form group with reactive validators
    this.registerForm = this.formBuilder.group({
      accountType: ['residential', Validators.required],
      accountNumber: ['', Validators.required],
      ssnPin: ['', Validators.required]
    });
    this.utils.hideSpinner();
  }

  get accountNumber (): AbstractControl | null { return this.registerForm.get('accountNumber'); }
  get ssnPin (): AbstractControl | null { return this.registerForm.get('ssnPin'); }

  onSubmit (): void {
    if (this.registerForm.valid) {
      // Handle form submission
      this.utils.consoleLog(this.registerForm.value);
    } else {
      this.utils.consoleGroup('Form is invalid', this.registerForm);
    }
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState (control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = (form !== null) && form.submitted;
    return !!((control !== null) && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
