@if (pageReady) {
    <main class="gs-page">
        <div class="cancel-autopay-container">
            <div class="headline-container">
                <div class="headline-content">
                    @if (showAutoPayChangeHeader) {
                        <h1 class="headline h1-lg">{{pageLabels.autoPayChangeHeaderText}}</h1>
                    } 
                    @else {
                        <h1 class="headline h1-lg">{{pageLabels.autoPayCancelHeaderText}}</h1>
                    }
                    <div>Filler headline content container</div> 
                </div>
            </div>
            <div class="cancel-details">
                <div class="cancel-container">
                    @if (showAutoPayChangeHeader) {
                        <h2 class="cancel-label">{{pageLabels.autoPayChangeText}}</h2>
                        <p>{{pageLabels.autoPayChangeConfirmationText}}</p>
                    }
                    @else {
                        <h2 class="cancel-label">{{pageLabels.autoPayCancelText}}</h2>
                        <p>{{pageLabels.autoPayCancelConfirmationText}}</p>
                    }
                </div>
                <mat-divider></mat-divider>
                <div class="cta-container">
                    <a class="autopay-link" [attr.aria-label]="pageLabels.autoPayKeepAutopayLinkAriaLabel" href="{{pageLabels.autoPayCancelKeepAutoPayLink}}">{{pageLabels.autoPayCancelKeepAutopayText}}</a>
                    <button role="link" mat-flat-button color="primary" [attr.aria-label]="pageLabels.autoPayCancelButtonAriaLabel" class="gs-form-button-primary" (click)="cancelAutoPay()">{{pageLabels.autoPayCancelButtonText}}</button>
                </div>
            </div>
        </div>
    </main>
}

