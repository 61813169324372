// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { type PaymentMethod, type PaymentMethodResponse } from '../models/payment-method';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { map, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment.development';
import { type FuturePayment, type CardPayment, type PaymentResponse } from '../models/payment';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from './storage.service';
import { StorageEnum } from '../enums/storage.enum';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})

export class PaymentService {
  constructor (
    private readonly http: HttpClient,
    private readonly utilService: UtilService,
    private readonly storage: StorageService
  ) { }

  getPreferredPayments (acctNumber?: string): Observable<PaymentMethodResponse> {
    const url = environment.OASUrl(false) + '/payment/preferred-payments' + '?accountNumber=' + acctNumber;
    const res = this.http.get(url).pipe(
      map((data: PaymentMethodResponse) => {
        data.PaymentMethods?.forEach(pm => {
          if (this.utilService.isCardBankDigital(pm.PaymentAccountType) !== 'DIGITAL') {
            pm.Description = `${pm.PaymentAccountType} ending in ${pm.LastFour}`;
          } else {
            pm.Description = pm.PaymentAccountType?.toString();
          }
        });
        return data;
      })
    );
    return res;
  }

  getPaymentData (acctNumber?: string): Observable<PaymentMethod> {
    const url = environment.OASUrl(false) + '/payment/payments-data' + '?accountNumber=' + acctNumber;
    const res = this.http.get(url).pipe(
      map((data: any) => {
        data.PaymentMethods?.forEach((pm: PaymentMethod) => {
          if (this.utilService.isCardBankDigital(pm.PaymentAccountType) !== 'DIGITAL') {
            pm.Description = `${pm.PaymentAccountType?.toString()} ending in ${pm.LastFour}`;
          } else {
            pm.Description = pm.PaymentAccountType?.toString();
          }
        });
        return data;
      })
    );
    return res;
  }

  updatePreferredPayment (prefPaymentData: PaymentMethod): Observable<any> {
    const url = environment.OASUrl(false) + '/payment/change-preferred-payment';
    const res = this.http.post(url, prefPaymentData);
    return res.pipe(
      tap((data: any) => {
        if (data) {
          return res;
        } else {
          return throwError(() => new Error('Update Preferred Payment error: Failed to update preferred payment'));
        }
      })
    );
  }

  makeCreditCardPayment (paymentData: CardPayment): Observable<PaymentResponse> {
    const url = environment.OASUrl(false) + '/payment/make-card-payment';
    const res = this.http.post(url, paymentData);
    let paymentErrors: any [] = [];
    paymentErrors = this.storage.getSession(StorageEnum.GlobalVars).paymentErrors;

    return res.pipe(
      map((data: PaymentResponse) => {
        if (data.Error === null) {
          return data;
        } else {
          // get display message from Builder
          const errorCode = data.Error?.ErrorCode;
          const newPaymentError = paymentErrors.find(error => {
            return error.paymentError?.errorCode === errorCode;
          });
          // build Message and Code string
          throw new Error(newPaymentError.paymentError?.errorHeader + '|' + newPaymentError.paymentError?.displayMessage);
        }
      })
    );
  }

  makeBankPayment (paymentData: CardPayment): Observable<PaymentResponse> {
    const url = environment.OASUrl(false) + '/payment/make-bank-payment';
    const res = this.http.post(url, paymentData);
    let paymentErrors: any [] = [];
    paymentErrors = this.storage.getSession(StorageEnum.GlobalVars).paymentErrors;

    return res.pipe(
      map((data: PaymentResponse) => {
        if (data.Error === null) {
          return data;
        } else {
          // get display message from Builder
          const errorCode = data.Error?.ErrorCode;
          const newPaymentError = paymentErrors.find(error => {
            return error.paymentError?.errorCode === errorCode;
          });
          // build Message and Code string
          throw new Error(newPaymentError.paymentError?.errorHeader + '|' + newPaymentError.paymentError?.displayMessage);
        }
      })
    );
  }

  postFuturePayment (paymentData: FuturePayment): Observable<PaymentResponse> {
    const url = environment.OASUrl(false) + '/payment/post-future-payment';
    const res = this.http.post(url, paymentData);
    let paymentErrors: any [] = [];
    paymentErrors = this.storage.getSession(StorageEnum.GlobalVars).paymentErrors;

    return res.pipe(
      map((data: PaymentResponse) => {
        if (data.Error === null) {
          return data;
        } else {
          // get display message from Builder
          const errorCode = data.Error?.ErrorCode;
          const newPaymentError = paymentErrors.find(error => {
            return error.paymentError?.errorCode === errorCode;
          });
          // build Message and Code string
          throw new Error(newPaymentError.paymentError?.errorHeader + '|' + newPaymentError.paymentError?.displayMessage);
        }
      })
    );
  }
}
