/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { type HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from './util.service';
import { type StorageEnum } from '../enums/storage.enum';
import { environment } from '../../../environments/environment.development';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private httpCache: Map<string, HttpResponse<any>>;
  private activeStore: any;
  private useLocalStorage = false;
  storageInitialized = false;
  isBroswer = false;

  constructor (
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly utils: UtilService
  ) {
    this.httpCache = new Map<string, HttpResponse<any>>();
    this.activeStore = {};
    this.isBroswer = isPlatformBrowser(platformId);
    this.storageInitialized = true;
  }

  preserveStorage (useLocalStorage = false): void {
    this.useLocalStorage = useLocalStorage;
  }

  /* region http cache */
  setCache (key: string, value: HttpResponse<any>): boolean {
    if (!this.utils.isNullOrWhitespace(key) && !this.utils.isNullOrUndefined(value)) {
      this.httpCache.set(key, value);
      return true;
    }
    return false;
  }

  getCache (key: string): HttpResponse<any> | null {
    if (!this.utils.isNullOrWhitespace(key) && this.httpCache.has(key)) {
      return JSON.parse(JSON.stringify(this.httpCache.get(key)));
    }
    return null;
  }

  clearCache (): boolean {
    this.httpCache = new Map<string, HttpResponse<any>>();
    return true;
  }
  /* endregion http cache */

  /* region session */
  setSession (key: StorageEnum, value: any): boolean {
    try {
      if (this.isBroswer) {
        sessionStorage.setItem(key.toString(), JSON.stringify(value));
        return true;
      }
      return false;
    } catch (e) {
      this.utils.consoleGroup('ERROR in StorageService.setSession', e);
      return false;
    }
  }

  // Returns COPY of object. To modify original, update copy and setObject
  getSession (key: StorageEnum): any {
    try {
      if (this.isBroswer) {
        const value = sessionStorage.getItem(key.toString());
        if (value !== null) {
          return JSON.parse(value);
        } else {
          return null;
        }
      }
      return null;
    } catch (e) {
      this.utils.consoleGroup('ERROR in StorageService.getSession', e);
      return null;
    }
  }

  removeSession (key: StorageEnum) {
    if (this.isBroswer) {
      sessionStorage.removeItem(key.toString());
      this.utils.consoleLog(`${key} removed from session storage!`);
    }
  }

  clearAllSession () {
    if (this.isBroswer) {
      sessionStorage.clear();
      this.utils.consoleLog('All stored session data has be cleared!');
    }
  }
  /* endregion session */

  /* region local */
  setLocal (key: StorageEnum, value: any): boolean {
    try {
      if (this.isBroswer) {
        localStorage.setItem(key.toString(), JSON.stringify(value));
        return true;
      }
      return false;
    } catch (e) {
      this.utils.consoleGroup('ERROR in StorageService.setLocal', e);
      return false;
    }
  }

  // Returns COPY of object. To modify original, update copy and setObject
  getLocal (key: StorageEnum): any {
    try {
      if (this.isBroswer) {
        const value = localStorage.getItem(key.toString());
        if (value !== null) {
          return JSON.parse(value);
        } else {
          return null;
        }
      }
      return null;
    } catch (e) {
      this.utils.consoleGroup('ERROR in StorageService.getLocal', e);
      return null;
    }
  }

  removeLocal (key: StorageEnum) {
    if (this.isBroswer) {
      localStorage.removeItem(key.toString());
      this.utils.consoleLog(`${key} removed from local storage!`);
    }
  }

  clearAllLocal () {
    if (this.isBroswer) {
      localStorage.clear();
      this.utils.consoleLog('All stored local data has be cleared!');
    }
  }

  printKeysLocal () {
    if (this.isBroswer) {
      const keys = Object.keys(localStorage);
      this.utils.consoleLog(`Keys in Storage: ${keys.toString()}`);
    }
  }

  /* endregion local */

  set (key: StorageEnum, value: any) {
    this.activeStore[key] = value;
    if (this.useLocalStorage) {
      this.setLocal(key, value);
    }
  }

  // returns a COPY of data. To modify original data, update copy and set
  get (key: StorageEnum) {
    if (Object.prototype.hasOwnProperty.call(this.activeStore, key)) {
      return JSON.parse(JSON.stringify(this.activeStore[key]));
    }
    this.utils.consoleLog(`Key '${key}' not found in active storage!`);
    if (this.useLocalStorage) {
      return this.getLocal(key);
    }
    return null;
  }

  remove (key: StorageEnum) {
    if (Object.prototype.hasOwnProperty.call(this.activeStore, key)) {
      delete this.activeStore[key];
      this.utils.consoleLog(`Key '${key}' deleted from active storage!`);
    } else {
      this.utils.consoleLog(`Key '${key}' not found in active storage!`);
    }
    if (this.useLocalStorage) {
      this.removeLocal(key);
    }
  }

  clear () {
    this.activeStore = {};
    this.utils.consoleLog('Active storage has been cleared!');
    if (this.useLocalStorage) {
      this.clearAllLocal();
    }
  }

  printStore () {
    this.utils.consoleGroup('Active Storage', this.activeStore);
    if (this.useLocalStorage) {
      this.printKeysLocal();
    }
  }
}
