/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Inject, PLATFORM_ID, type TemplateRef, ViewChild, type OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { CmsService } from '../../shared/services/cms.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../shared/services/util.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Router } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { PaymentService } from '../../shared/services/payment.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from '../../shared/services/storage.service';

import { PaymentMethod, type PaymentMethodResponse } from '../../shared/models/payment-method';
import { type Observable, tap } from 'rxjs';
import { StorageEnum } from '../../shared/enums/storage.enum';
import { type AccountDetail } from '../../shared/models/account-detail';
import { isPlatformBrowser } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { PaymentAccountType } from '../../shared/enums/paymentaccounttype.enum';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { MatDialog, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { type FormArray, FormBuilder, type FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ErrorData } from '../../shared/models/error-data';
import { MatButtonModule } from '@angular/material/button';
import { format, toDate } from 'date-fns';

@Component({
  selector: 'app-wallet',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    NgxMaskDirective,
    MatButtonModule
  ],
  templateUrl: './wallet.component.html',
  styleUrl: './wallet.component.scss'
})
export class WalletComponent implements OnInit {
  pageReady = false;
  pageLabels: any;
  formErrorMessage: string = '';
  isBrowser = false;
  paymethods: PaymentMethod[] = [];
  accountDetail: AccountDetail;
  selectedPayMethod: PaymentMethod = new PaymentMethod();
  walletForm: FormGroup;
  paymentForms: FormArray;
  @ViewChild('editModal') editModal: TemplateRef<any> | undefined;
  paymentErrors: any [] = [];
  @ViewChild('deletePaymentDialogTemplate') deletePaymentDialogTemplate!: TemplateRef<any>;

  constructor (
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) readonly platformId: Object,
    private readonly cms: CmsService,
    public dialog: MatDialog,
    private readonly fb: FormBuilder,
    private readonly payment: PaymentService,
    private readonly router: Router,
    private readonly storage: StorageService,
    private readonly utils: UtilService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.accountDetail = this.storage.getSession(StorageEnum.AccountDetail);
    this.paymentErrors = this.storage.getSession(StorageEnum.GlobalVars)?.paymentErrors;
    this.paymentForms = this.fb.array([]);
    this.walletForm = this.fb.group({
      PayForms: this.paymentForms
    });
  }

  ngOnInit (): void {
    this.cms.getContent('oas-wallet', 'wallet').subscribe(data => {
      this.pageLabels = data;
      if (this.isBrowser) {
        this.getPreferredPayments().subscribe({
          error: (e) => {
            this.setErrorData(e.message);
            void this.router.navigateByUrl('/error-page');
          },
          next: (data: any) => {
            const inter = setInterval(() => {
              const methodContainer = document.getElementsByClassName('pay-method-container')[0];
              if (methodContainer) {
                this.utils.setElementHeight(methodContainer, 150);
                clearInterval(inter);
              }
            }, 20);
            window.addEventListener('resize', () => {
              const methodContainer = document.getElementsByClassName('pay-method-container')[0];
              this.utils.setElementHeight(methodContainer, 150);
            });
            this.pageReady = true;
            this.paymethods.map(pm => {
              let bankForm, cardForm, defaultForm;
              switch (pm.BankCardDigital) {
                case 'BANK':
                  bankForm = this.fb.group({
                    Nickname: [pm.Nickname, [Validators.maxLength(45)]],
                    RoutingNumber: [pm.BankRoutingNumber, [Validators.required]],
                    AccountNumber: [pm.BankAccountNumber, [Validators.required]],
                    SetDefault: [false]
                  });
                  this.paymentForms.push(bankForm);
                  return pm;
                case 'CARD':
                  cardForm = this.fb.group({
                    Nickname: [pm.Nickname, [Validators.maxLength(45)]],
                    ExpDate: [pm.ExpDate, [Validators.required]],
                    SecurityCode: ['', [Validators.required]],
                    SetDefault: [false]
                  });
                  this.paymentForms.push(cardForm);
                  return pm;
                case 'DIGITAL':
                default:
                  defaultForm = this.fb.group({});
                  this.paymentForms.push(defaultForm);
                  return pm;
              }
            });
            this.utils.hideSpinner();
          }
        });
      }
    });
  }

  getPreferredPayments (): Observable<PaymentMethodResponse> {
    return this.payment.getPreferredPayments(this.accountDetail.AccountNumber)
      .pipe(
        tap((data: any) => {
          this.storage.setSession(StorageEnum.PaymentMethods, data.PaymentMethods);

          if (!data.PaymentMethods) {
            data.PaymentMethods = [];
          }
          this.paymethods = this.payment.sortPaymentMethods(data.PaymentMethods, this.accountDetail.IsLimitedPaymentOptions, false);
        }));
  }

  setErrorData (errorCode: string): void {
    const error = this.paymentErrors.find(paymentError => {
      return paymentError.paymentError.errorCode === errorCode;
    });
    const preferredPaymentError = error;
    const errorLabels = this.pageLabels.walletErrorData[0].error.value.data.ctaList[0];
    const errorDetail = new ErrorData();
    errorDetail.Header = preferredPaymentError.paymentError.errorHeader;
    errorDetail.SubheaderTitle = '';
    errorDetail.Subheader = preferredPaymentError.paymentError.displayMessage;
    errorDetail.CtaButton.Label = errorLabels.ctaLabel;
    errorDetail.CtaButton.Link = errorLabels.ctaUrl;
    errorDetail.CtaButton.AriaLabel = errorLabels.ctaAriaLabel;
    this.storage.setSession(StorageEnum.ErrorData, errorDetail);
  }

  getPayMethodIcon (type: PaymentAccountType | undefined): string {
    // console.log('getPayMethodIcon: ', type);
    if (!type) {
      type = PaymentAccountType.Unknown;
    }
    switch (type) {
      case PaymentAccountType.AmazonPay:
        return '<i class="fa-brands fa-amazon-pay"></i>';
      case PaymentAccountType.AmericanExpress:
        return '<i class="fa-brands fa-cc-amex"></i>';
      case PaymentAccountType.ApplePay:
        return '<i class="fa-brands fa-apple-pay"></i>';
      case PaymentAccountType.Banking:
        return '<i class="fa-light fa-building-columns"></i>';
      case PaymentAccountType.Card:
        return '<i class="fa-regular fa-credit-card"></i>';
      case PaymentAccountType.Discover:
        return '<i class="fa-brands fa-cc-discover"></i>';
      case PaymentAccountType.GooglePay:
        return '<i class="fa-brands fa-google-pay"></i>';
      case PaymentAccountType.MasterCard:
        return '<i class="fa-brands fa-cc-mastercard"></i>';
      case PaymentAccountType.PayPal:
        return '<i class="fa-brands fa-paypal"></i>';
      case PaymentAccountType.Visa:
        return '<i class="fa-brands fa-cc-visa"></i>';
      case PaymentAccountType.Venmo:
      default:
        return '<i class="fa-regular fa-money-bill-1"></i>';
    }
  }

  async goToAccountSummary (): Promise<void> {
    await this.router.navigateByUrl('/account-summary');
  }

  addNewPayMethod (): void {
    console.log('add new pay method clicked');
    console.log('this the form: ', this.paymentForms);
  }

  editPayMethod (index: number): void {
    console.log('editing form: ', index, this.paymentForms.at(index));
  }

  deletePayMethod (paymethod: PaymentMethod): void {
    const title = this.pageLabels.deletePaymentMethodHeader;
    let content = '';
    const today = format(new Date().toString(), 'MM/dd/yyyy');
    const billDueDate = format(toDate(this.accountDetail.CurrentBalanceDueDate!).toString(), 'MM/dd/yyyy');
    const autoPayEnrollDate = format(toDate(this.accountDetail.AutoPay!.StartDate!).toString(), 'MM/dd/yyyy');
    const autoPayEnrolled = paymethod.IsAssociatedAutoPay;
    // if auto pay enrollment date is today or current bill due date is today not eligible
    if (autoPayEnrolled && billDueDate === today) {
      content = this.pageLabels.deletePaymentMethodAutoPaySameDayBillDue;
    } else if (autoPayEnrolled && autoPayEnrollDate === today) {
      content = this.pageLabels.deletePaymentMethodAutoPaySameDayEnrolled;
    } else if (autoPayEnrolled) {
      content = this.pageLabels.deletePaymentMethodAssociatedAutoPay;
    } else {
      content = this.pageLabels.deletePaymentMethodConfirmation;
    }
    const dialogConfig = new MatDialogConfig();
    // Optional configuration
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = 'first-heading';
    dialogConfig.data = {
      title,
      content
    };
    // dialogConfig.maxWidth = 400;
    const dialogRef = this.dialog.open(this.deletePaymentDialogTemplate, dialogConfig);
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data === true) {
        this.deletePreferredPayment(paymethod);
      }
    });
  }

  deletePreferredPayment (paymethod: PaymentMethod): void {
    this.utils.showSpinner();
    paymethod.ActionCode = 'D';
    this.payment.updatePreferredPayment(paymethod).subscribe({
      error: (e) => {
        this.setErrorData(e.message);
        void this.router.navigateByUrl('/error-page');
      },
      next: (data: any) => {
        this.utils.hideSpinner();
        // console.log('show confirmation check');
      }
    });
  }
}
