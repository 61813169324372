export const environment = {
  builderPublicKey: '1b64838a2fd245b29e8bc7c992bd9a8a',
  digitalProductsBuilderKey: '4caa3a425c85496e9b99e391d030985f',
  contentAPIUrl: 'https://cdn.builder.io/api/v3/content/',
  enableLogging: true,
  baseUrl: 'https://gs-qa-east-oas-api.azurewebsites.net',
  blogBaseUrl: 'https://www.gassouth.com/blog',
  deviceInfo: null,
  OASUrl (local = false) {
    return local ? 'https://localhost:7225/api' : this.baseUrl + '/api';
  },
  paymentusTokenUrl () {
    return 'https://secure4.paymentus.com/api/v2/profiles/gass';
  },
  paymentusDigitalTokenUrl () {
    return 'https://secure4.paymentus.com/xotp/pm/GASS';
  }
};
