// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { BankAccountType } from '../enums/bankaccounttype.enum';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { PaymentAccountType } from '../enums/paymentaccounttype.enum';

export class PaymentMethodResponse {
  public PaymentMethods?: PaymentMethod[];
  public Error?: any;
}

export class PaymentMethod {
  public ReferenceId?: string;
  public Nickname?: string;
  public ExpDate?: string;
  public IsDefault?: boolean;
  public SaveToAccount: boolean = false;
  public LastFour?: string;
  public BankAccountNumber?: string;
  public BankRoutingNumber?: string;
  public IsAssociatedAutoPay?: boolean;
  public PaymentAccountType?: PaymentAccountType;
  public Type?: PaymentAccountType;
  public BankAccountType?: BankAccountType;
  public Description?: string;
  public IsExpired = false;
  public ActionCode?: string;
}
