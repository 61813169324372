/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Injectable } from '@angular/core';
import { map, mergeMap, type Observable, throwError } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment.development';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from './storage.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { PaymentusRequest } from '../models/paymentus';
import { UtilService } from './util.service';
import { StorageEnum } from '../enums/storage.enum';

@Injectable({
  providedIn: 'root'
})
export class PaymentusService {
  constructor (
    private readonly http: HttpClient,
    private readonly storage: StorageService,
    private readonly util: UtilService
  ) {}

  getPaymentusToken (paymentusRequest: PaymentusRequest): Observable<any> {
    const tokenUrl = environment.OASUrl(false) + '/paymentus/authtoken';

    const JWTRes: any = this.http.get(tokenUrl, { responseType: 'text' });

    return JWTRes.pipe(
      // tap((data) => { console.log('jwt  res', data); }),
      mergeMap((data: string) => {
        if (!this.util.isNullOrWhitespace(data)) {
          return this.savePayment(paymentusRequest, data);
        } else {
          return throwError(() => new Error('Paymentus token error: Failed to get token'));
        }
      })
    );
  }

  savePayment (paymentusRequest: PaymentusRequest, JWTRes: any): Observable<any> {
    const paymentusTokenUrl = environment.paymentusTokenUrl();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JWTRes}`
      })
    };

    const res = this.http.post(paymentusTokenUrl, { profile: paymentusRequest }, httpOptions);

    let paymentusErrors: any[] = [];
    paymentusErrors = this.storage.getSession(StorageEnum.GlobalVars).paymentusErrors;

    return res.pipe(
      map((data: any) => {
        if (data['profile-response'].status === 'PROCESSED') {
          return data;
        } else if (data['profile-response'].status === 'FAILED') {
          let paymentusErrorMessage = '';
          if (Array.isArray(data['profile-response'].errors.error)) {
            paymentusErrorMessage = data['profile-response'].errors.error[0].value;
          } else {
            paymentusErrorMessage = data['profile-response'].errors.error.value;
          }

          // log error into Application insights using OAS API call
          this.logTokenError(paymentusRequest['user-info']?.['login-id'], paymentusErrorMessage);

          // get display message from Builder
          const newPaymentusError = paymentusErrors.find(error => {
            return error.paymentusError.errorMessage.toLowerCase().replaceAll('.', '') === paymentusErrorMessage.toLowerCase().replaceAll('.', '');
          });
          // build Message and Code string
          throw new Error(newPaymentusError.paymentusError.errorCode + '|' + newPaymentusError.paymentusError.displayMessage);
        }
      })
    );
  }

  getDigitalPaymentToken (accountNumber: string, pmCategory: string, postMessagePmDetailsOrigin: string): Observable<any> {
    const params = '/paymentus/digitaltokenpostmessage?ownerId=' + accountNumber + '&pmCategory=' + pmCategory + '&postMessagePmDetailsOrigin=' + postMessagePmDetailsOrigin;
    console.log('these are my digital token parameters=', params);
    const url = environment.OASUrl() + params;
    const res = this.http.get(url, { responseType: 'text' });
    return res;
  }

  logTokenError (accountNumber?: string, errorMessage?: string): void {
    const url = environment.OASUrl() + '/paymentus/logtokenerror?accountNumber=' + accountNumber + '&errorMessage=' + errorMessage;
    this.http.get(url, { responseType: 'text' });
  }
}
