@if (pageReady) {
    <main class="gs-page">
        <div class="payment-container">
            <div class="headline-container">
                <div class="headline-content">
                    <h1 class="headline h1-lg">{{pageLabels.paymentHeaderText}}</h1>
                    <div>Filler headline content container</div>
                </div>
            </div>
            <div class="notification-container">
                @if (formErrorMessage != '') {
                    <div id="formErrorLabel" tabindex="0" class="gs-post-notification-error">
                        <i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                        <span [innerHTML]="formErrorMessage"></span>
                    </div>
                }
                @if(showBankLimitNotification) {
                    <p class="gs-post-notification"><i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                    {{pageLabels.maxBankAmountPostNotification}}</p>
                }
                @if(showCardLimitNotification) {
                    <p class="gs-post-notification"><i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                    {{pageLabels.maxCardAmountPostNotification}}</p>
                }
                @if(showFuturePaymentPreNotification) {
                    <p class="gs-post-notification"><i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                    {{pageLabels.futurePaymentScheduledPreNotification}}</p>
                }
                @if(showOnAutoPayPreNotification) {
                    <p class="gs-post-notification"><i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                    {{pageLabels.accountIsOnAutoPayPreNotification}}</p>
                }
                @if(showLimitedPaymentOptionsPreNotification) {
                    <p class="gs-post-notification"><i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                    {{pageLabels.limitedPaymentOptionsPreNotification}}</p>
                }
            </div>
            <div class="gs-content-container">
                <div class="payment-details payment-section">
                    <form [formGroup]="paymentForm">
                        <div class="pay-date pay-detail">
                            @if (!canSchedulePayment) {
                                <h2 class="paymentDate-label"><label id="todayPaymentDateLabel">{{pageLabels.paymentDateLabelText}}</label></h2>
                                <input id="todayPaymentDate" aria-labelledby="todayPaymentDateLabel" readonly matInput class="payment-date-today" value="{{todaysDay}}">
                            }
                            @else {
                                <h2>{{pageLabels.paymentSelectDateLabelText}}</h2>
                                <mat-form-field appearance="outline" color="primary" class="future-date-calendar">
                                    <mat-label>{{pageLabels.paymentSelectDateLabelText}}</mat-label>
                                    <input matInput [matDatepicker]="picker" placeholder="MM/DD/YYYY" formControlName="paydate" (dateChange)="payDateChange($event)"  [min]="minDate" [max]="maxDate">
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker [dateClass]="dateClass" #picker (opened)="onCalendarOpen()" [startAt]="startDate">
                                    </mat-datepicker>
                                    @if (paymentForm.get('paydate')?.hasError('required')) {
                                        <mat-error id="payDateReqError">{{pageLabels.paymentDateReqErrorText}}</mat-error>
                                    }
                                </mat-form-field>
                                <div id="calFooter" style="display: none">
                                    <div class="legend-content" #datepickerFooter>
                                        <div class="legend-row">
                                            <div class="legend-icon due-date-icon"></div>
                                            <div class="legend-text">{{calendarLegendDueDateText}}</div>
                                        </div>
                                        <div class="legend-row" >
                                            <div class="legend-icon selected-date-icon"></div>
                                            <div class="legend-text">{{calendarLegendSelectedPayDateText}}</div>
                                        </div>
                                        <div class="legend-row">
                                            <div class="legend-icon due-date-selected-icon"></div>
                                            <div class="legend-text">{{calendarLegendSelectedDueDateText}}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div class="pay-method pay-detail">
                            <h2>{{pageLabels.selectPaymentMethodLabelText}}</h2>
                            <mat-form-field class="payment-list" appearance="outline" color="primary">
                                <mat-label>{{pageLabels.selectPaymentDefaultText}}</mat-label>
                                <mat-select formControlName="paymethod" (selectionChange)="paymethodChanged()">
                                    @for (method of paymethods; track method) {
                                    <mat-option class="paymethod-option" [value]="method">
                                        {{method.Nickname ? method.Nickname : method.Description}}
                                        @if (method.IsExpired) {
                                        <span class="paymethod-expired">{{pageLabels.selectPaymentExpiredText}}</span>
                                        }
                                    </mat-option>
                                    }
                                </mat-select>
                                @if (paymentForm.get('paymethod')?.hasError('required')) {
                                    <mat-error id="payMethodReqError">{{pageLabels.payMethodReqErrorText}}</mat-error>
                                }
                            </mat-form-field>
                        </div>
                        @if (paymentForm.get('paymethod')?.value?.PaymentAccountType !== 'Cash') {
                            <div class="pay-amount pay-detail">
                                <h2>{{pageLabels.payAmountHeader}}</h2>
                                @if (!accountHasBalance()) {
                                <div id="balanceLabelDiv">
                                    <span>
                                        <p class="balance-label">
                                            {{paymentAmounts[0].label}}
                                            <i #tooltip="matTooltip"
                                            [matTooltip]="paymentAmounts[0].tooltip"
                                            matTooltipPosition="below"
                                            matTooltipClass="gs-tooltip"
                                            (click)="$event.preventDefault(); $event.stopImmediatePropagation(); tooltip.toggle()"
                                            (keydown.enter)="tooltip.toggle()"
                                            tabindex="0"
                                            [attr.aria-label]="paymentAmounts[0].tooltip"
                                            role="tooltip"
                                            class="gs-tooltip-icon fa-solid fa-circle-question"></i>
                                        </p>
                                    </span>
                                    <p class="balance-label">{{paymentAmounts[0].amount | currency| credit}}</p>
                                </div>
                                }
                                <mat-radio-group aria-label="Payment amount radio group." class="gs-radio-group" formControlName="payamount" (change)="checkOtherAmount()" color="primary">
                                    @for (payamount of paymentAmounts; track payamount; let  first = $first) {
                                    <mat-radio-button class="gs-radio-button"
                                                      [class.hide-section]="!accountHasBalance()"
                                                      [checked]="first"
                                                      [value]="payamount.amount" [disabled]="payamount.disable" #radio>
                                        <span [class.hide-section]="!accountHasBalance()">
                                            <p>
                                                {{payamount.label}}
                                                <i #tooltip="matTooltip"
                                                [matTooltip]="payamount.tooltip"
                                                matTooltipPosition="below"
                                                matTooltipClass="gs-tooltip"
                                                (click)="$event.preventDefault(); $event.stopImmediatePropagation(); tooltip.toggle()"
                                                (keydown.enter)="tooltip.toggle()"
                                                tabindex="0"
                                                [attr.aria-label]="payamount.tooltip"
                                                role="tooltip"
                                                class="gs-tooltip-icon fa-solid fa-circle-question"></i>
                                            </p>
                                        </span>
                                        <p>{{payamount.amount | currency| credit}}</p>
                                    </mat-radio-button>
                                    <mat-divider></mat-divider>
                                    }
                                    <mat-radio-button class="gs-radio-button other-amount" value="other"
                                                      [checked]="!accountHasBalance()"
                                                      aria-labelledby="payAmountOtherLabel payAmountOtherInvalid payAmountOtherMin payAmountMaxCard payAmountMaxBank">
                                        <mat-form-field (click)="otherClicked()">
                                            <mat-label id="payAmountOtherLabel">{{pageLabels.payAmountOtherAmountLabel}}</mat-label>
                                            <input matInput #otherAmount formControlName="otheramount" type="text" prefix="$" mask="separator.2" thousandSeparator="," [leadZero]="true"
                                                maxlength="16" aria-labelledby="payAmountOtherLabel payAmountOtherInvalid payAmountOtherMin payAmountMaxCard payAmountMaxBank">
                                        </mat-form-field>
                                    </mat-radio-button>
                                </mat-radio-group>
                                @if (paymentForm.get('payamount')?.touched && paymentForm.get('payamount')?.hasError('required')) {
                                <mat-error id="payAmountRequired" class="gs-error">{{pageLabels.payAmountRequiredError}}</mat-error>
                                }
                                @if (paymentForm.get('payamount')?.value === 'other' && (paymentForm.get('otheramount')?.touched || paymentForm.get('otheramount')?.dirty)) {
                                    @if (paymentForm.hasError('InvalidPaymentAmount')) {
                                    <mat-error id="payAmountOtherInvalid" class="gs-error">{{pageLabels.payAmountInvalidOtherAmount}}</mat-error>
                                    }
                                    @if (paymentForm.hasError('MinimumPaymentError')) {
                                    <mat-error id="payAmountOtherMin" class="gs-error">{{pageLabels.payAmountMinAmountError}}</mat-error>
                                    }
                                    @if (paymentForm.hasError('MaximumCardPaymentError')) {
                                    <mat-error id="payAmountMaxCard" class="gs-error">{{pageLabels.payAmountMaxCardError}}</mat-error>
                                    }
                                    @if (paymentForm.hasError('MaximumBankPaymentError')) {
                                    <mat-error id="payAmountMaxBank" class="gs-error">{{pageLabels.payAmountMaxBankError}}</mat-error>
                                    }
                                }
                            </div>
                        }
                        <div aria-live="polite">
                            @if (paymentForm.get('paymethod')?.value?.PaymentAccountType === 'Cash') {
                                <div class="pay-cash pay-detail">
                                    <h2>{{pageLabels.checkHeader}}</h2>
                                    <p [innerHTML]="pageLabels.checkMailingAddress"></p>

                                    <h2>{{pageLabels.cashHeader}}</h2>
                                    <p>{{pageLabels.cashDescription}}</p>
                                </div>
                            }
                        </div>
                    </form>
                </div>
                <div class="payment-summary payment-section">
                    <form [formGroup]="paymentForm">
                    <mat-card class="pay-summary-card" appearance="outlined">
                        <mat-card-header class="summary-header">
                            <h2 class="summary-title">{{pageLabels.paymentSummaryHeader}}</h2>
                        </mat-card-header>
                        <mat-card-content class="summary-content">
                            @if (showBill) {
                                <div class="summary-bill summary-section">
                                    <a [href]="accountDetail.BillURL" target="_blank">{{pageLabels.paymentSummaryViewBillText}}</a>
                                </div>
                            }
                            <div class="summary-paydate  summary-section">
                                <p class="summary-section-label">{{pageLabels.paymentSummaryPaymentDateText}}</p>
                                <p class="summary-section-value">{{paymentForm.get('paydate')?.value | date: 'MMM dd, yyyy'}}</p>
                            </div>
                            <div class="summary-paymethod  summary-section">
                                <p class="summary-section-label">{{pageLabels.paymentSummaryPaymentMethodText}}</p>
                                <p class="summary-section-value">{{paySummaryMethodLabel}}</p>
                            </div>
                            <div class="summary-total  summary-section">
                                <p class="summary-section-label">{{pageLabels.paymentSummaryTotalBalanceText}}</p>
                                <p class="summary-section-value">{{accountDetail.CurrentBalance | currency | credit}}</p>
                            </div>
                            @if (paymentForm.get('paymethod')?.value?.PaymentAccountType !== 'Cash') {
                                <mat-divider></mat-divider>
                                <div class="summary-payamount  summary-section">
                                    <h2>{{pageLabels.paymentSummaryPaymentAmountHeaderText}}</h2>
                                    <p class="summary-section-value">{{paymentForm.get('payamount')?.value === 'other' ? (paymentForm.get('otheramount')?.value | currency | credit) : (paymentForm.get('payamount')?.value | currency | credit)}}</p>
                                </div>
                                @if (canSchedulePayment && showTermsConditions) {
                                    <div>
                                        <mat-checkbox color="primary" formControlName="termsConditionsChkbox">{{pageLabels.termsConditionsText}}
                                            <a href="{{pageLabels.termsConditionsLinkUrl}}" target="_blank">{{pageLabels.termsConditionsLinkText}} <i aria-hidden="true" class="fa-solid fa-arrow-up-right-from-square"></i></a>
                                        </mat-checkbox>
                                        @if (paymentForm.get('termsConditionsChkbox')?.hasError('termsConditionsError')) {
                                            <mat-error id="termConditionsError" class="gs-error terms-conditoins-error">{{pageLabels.termsConditionsErrorText}}</mat-error>
                                        }
                                    </div>
                                }
                                <div class="summary-confirm">
                                    <p>{{pageLabels.paymentSummaryConfirmationMessageText}}</p>
                                </div>
                            }
                        </mat-card-content>
                        @if (paymentForm.get('paymethod')?.value?.PaymentAccountType !== 'Cash') {
                            <mat-card-actions class="summary-buttons">
                                <button class="gs-form-button-primary" mat-flat-button color="primary" (click)="onSubmit()">Pay {{paymentForm.get('payamount')?.value === 'other' ? (paymentForm.get('otheramount')?.value | currency | credit) : (paymentForm.get('payamount')?.value | currency | credit)}}</button>
                            </mat-card-actions>
                        }
                        @else {
                        <mat-card-actions class="summary-buttons">
                            <button role="link" class="gs-form-button-primary" mat-flat-button color="primary" (click)="findLocations()">{{pageLabels.findLocationsButtonText}}</button>
                        </mat-card-actions>
                        }
                    </mat-card>
                </form>
                </div>
            </div>
        </div>
    </main>
}
