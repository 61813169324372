<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">

<main *ngIf="pageLabels$ | async as pageLabels" class="register-page gs-page">
    <div class="gradient-container">
        <div class="register-form-container">
            <img [src]='pageLabels.gasSouthLogoUrl' alt='Gas South Logo' class="logo-image">
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="register-form">
                <div class="form-radio-group">
                    <label>
                        <input type="radio" formControlName="accountType" value="residential" checked>
                        <div class="custom-radio"></div>
                        <span>{{pageLabels.residentialRadioButtonLabel}}</span>
                    </label>
                    <label class="radio-right">
                        <input type="radio" formControlName="accountType" value="business">
                        <div class="custom-radio"></div>
                        <span>{{pageLabels.businessRadioButtonLabel}}</span>
                    </label>
                </div>                
                <div class="gs-form-field">
                    <label class="register-label email-label" for="accountNumber">{{pageLabels.registerAccountNumber}}</label>
                    <input [class.gs-input-error]="accountNumber?.touched && accountNumber?.invalid"
                           class="custom-input"
                           type="text"
                           formControlName="accountNumber"
                           id="accountNumber">
                    <p *ngIf="accountNumber?.touched && accountNumber?.invalid" class="gs-inline-error">{{pageLabels.accountNumberError}}</p>
                </div>
                <div class="gs-form-field">
                    <label class="register-label password-label" for="ssnPin">{{pageLabels.registerSsnValue}}</label>
                    <input [class.gs-input-error]="ssnPin?.touched && ssnPin?.invalid"
                           class="custom-input"
                           type="text"
                           formControlName="ssnPin"
                           id="ssnPin">
                    <p *ngIf="ssnPin?.touched && ssnPin?.invalid" class="gs-inline-error">{{pageLabels.accountSsnError}}</p>
                </div>
                <button type="submit">{{pageLabels.registerText}}</button>
            </form>
        </div>
    </div>
</main>
