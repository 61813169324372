export class Address {
  public Order?: number;
  public PremiseId?: string;
  public SaId?: string;
  public SaStatusFlg?: string;
  public SaStartDate?: string;
  public SaEndDate?: string;
  public Address1?: string;
  public Address2?: string;
  public City?: string;
  public State?: string;
  public PostalCode?: string;
  public Country?: string;
  public IsResidential?: boolean;
  public SaTypeCode?: string;
  public FullAddress?: string;
  public SaRateScheduleCode?: string;
  public BillingRateExpirationDate?: string;
}
