import { Injectable } from '@angular/core';
import { type Observable } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { HttpClient } from '@angular/common/http';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { environment } from '../../../environments/environment.development';
import { type AccountDetail } from './../models/account-detail';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor (
    private readonly http: HttpClient
  ) { }

  getAccountDetail (acctNumber: string): Observable<AccountDetail> {
    const url = environment.OASUrl(false) + '/account/account-details';
    const req = {
      accountId: acctNumber
    };

    const res = this.http.post(url, req);
    return res;
  }
}
