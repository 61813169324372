a {
    text-decoration: underline;
}
.paymethod-expired {
    font: normal normal medium 14px/17px Montserrat;
    color: #D12A2A;
}

.paymethod-option {
    display: flex;
    justify-content: space-between;
}

.paymethod-highlight {
    display: flex;
    justify-content: space-between;
    color: #FA4600;
    text-decoration: underline;
}

.payment-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notification-container {
    width: 90%;
    max-width: var(--gs-max-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.pay-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.payment-details {
    width: 100%;
}

.payment-summary {
    width: 100%;
}

.payment-list {
    width: 95%;
}

.future-date-calendar {
    width: 95%;
}

.payment-date-today {
    color: #4D4D4D;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 1rem;
    background: #F2F2F2;
    border: 1px solid var(--gs-border-color);
    border-radius: 3px;
    opacity: 1;
    padding: 0.938rem;
    margin-bottom: 1.25rem;
    width: 11.25rem;
}

.gs-radio-group {
    width: 100%;
}

.mat-mdc-card.pay-summary-card {
    border-radius: 8px;
}

.summary-header {
    background-color: var(--gs-orange);
    color: white;
    border-radius: 8px 8px 0 0;
    padding: 1rem 2rem 0;
}

.summary-title {
    margin: 0 0 1rem;
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;
}

.summary-content {
    padding: 1rem 2rem;
}

.summary-section {
    display: flex;
    justify-content: space-between;
}

.summary-section-label {
    font-weight: 600;
    width: 42%;
}

.summary-section-value {
    width: 50%;
    text-align: right;
}

.summary-payamount h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: bold;
}

.summary-payamount p {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.summary-buttons {
    padding: 0 2rem 2rem;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.summary-confirm p {
    text-align: center;
}

.gs-form-button-primary {
    min-width: 220px;
}

.terms-conditoins-error {
    margin-left: 2.7rem;
}

.gs-radio-button span {
    display: flex;
    flex-direction: row;
    width: 60%;
}

.gs-post-notification-error {
    margin: 0rem 2rem 1rem 0rem;
}

.paymentDate-label {
    margin-top: 0rem;
}

.pay-cash h2 {
    margin-bottom: .5rem;
}

.pay-cash p {
    margin-top: 0;
}

.legend-content {
    display: block;
    justify-content: flex-start;
    align-items: left;
    padding: 0 8px 8px 8px;
}

.datepicker-footer {
    width: 100%;
    padding: 0 1.125rem 1.125rem 1.125rem;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
}

button.gs-custom-date-class {
    background: var(--gs-orange);
    border-radius: 100%;
}

.legend-row {
    margin: .75rem;
    justify-content: space-evenly;
}

.legend-icon {
    float: left;
    margin-right: 4px;
}

.legend-text {
    display: flex;
    padding-left: 3px;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    width: 190px;
}

.selected-date-icon {
    background-color: #707070;
    height: 20px;
    width: 20px;
    border-radius: .19rem;
}

.due-date-icon {
    background-color: var(--gs-orange);
    height: 20px;
    width: 20px;
    border-radius: 50%;
}

.due-date-selected-icon {
    background-color: var(--gs-orange);
    height: 16px;
    width: 16px;
    border-radius: 50%;
    outline: 3px solid grey;
    outline-offset: 1px;
    margin: 4px 6px 0px 2px;
}

.summary-bill.summary-section.ng-star-inserted {
    margin-top: 15px;
}

@media only screen and (min-width: 576px) {
    .payment-list {
        width: 70%;
    }

    .future-date-calendar {
        width: 70%;
    }
}

@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 992px) {
    .gs-content-container {
        flex-direction: row;
        align-items: flex-start;
    }

    .payment-section {
        width: 45%;
    }
}

.hide-section{
    visibility: hidden;
    height: 0;
}

div#balanceLabelDiv {
    justify-content: space-between;
    display: flex;
}

.balance-label {
    font-weight:600;
}

@media only screen and (min-width: 1200px) {}

@media only screen and (min-width: 1400px) {}