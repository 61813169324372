/* eslint-disable @typescript-eslint/consistent-type-imports */
import { PaymentAccountType } from '../enums/paymentaccounttype.enum';
import { APIError } from './apierror';

export class AutoPay {
  public AccountNumber?: string;
  public BankingAccountType?: string;
  public Error?: APIError;
  public PaymentAccountType?: PaymentAccountType;
  public RecurringScheduleId?: string;
  public StartDate?: Date;
}
