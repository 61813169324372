<main *ngIf="pageLabels$ | async as pageLabels" class="gs-page">
    <div class="create-password-page">
        <div class="gradient-container create-password-section" [style]="'background:url(' + pageLabels.gradientBackgroundUrl + ')'">
            <div class="createPassword-form-container">
                <img [src]='pageLabels.gasSouthLogoLg' alt='Gas South Logo' class="logo-image">
                <div id="apiErrorLabel" *ngIf="apiError !== ''" tabindex="0" class="gs-post-notification-error">
                    <i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                    <span [innerHTML]="apiError"></span>
                </div>
                <div id="formErrorLabel" *ngIf="formErrorMessage != ''" tabindex="0" class="gs-post-notification-error">
                    <i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                    <span [innerHTML]="formErrorMessage"></span>
                </div>
                <h1 class="createPassword-header">{{pageLabels.createNewPasswordHeaderText}}</h1>
                <form [formGroup]="creatPasswordForm" (ngSubmit)="onSubmit()" class="createPassword-form">
                    <mat-form-field class="password-container remove-margin-bottom" appearance="outline" subscriptSizing="dynamic">
                        <mat-label id="passwordLabel">{{pageLabels.createPasswordText}}</mat-label>
                        <input matInput class="custom-input" [type]="passwordVisible ? 'text' : 'password'" formControlName="password"
                            id="CreateNewPassword" aria-labelledby="passwordLabel passwordReq passwordError pwMinLengthErr pwMaxLengthErr 
                            pwMIsMatchError pwMeetsRequirments" (blur)="confirmPasswordMatch()" maxlength="12">
                        <button type="button" mat-icon-button matSuffix (click)="togglePasswordVisibility()"
                            [attr.aria-label]="passwordVisible ? pageLabels.hidePasswordAriaLabel : pageLabels.showPasswordAriaLabel">
                            <i class="fa-solid fa-eye" [class.fa-eye]="!passwordVisible" [class.fa-eye-slash]="passwordVisible" [class.eye-icon]="true"></i>
                        </button>            
                        @if (creatPasswordForm.get('password')?.hasError('required')) {
                            <mat-error id="passwordError">{{pageLabels.passwordErrorText}}</mat-error>
                        }
                        @else if (creatPasswordForm.get('password')?.hasError('minlength')) {
                            <mat-error id="pwMinLengthErr">{{pageLabels.passwordMinLengthError}}</mat-error>
                        }
                        @else if (creatPasswordForm.get('password')?.hasError('maxlength')) {
                            <mat-error id="pwMaxLengthErr">{{pageLabels.passwordMaxLengthError}}</mat-error>
                        }
                        @else if (creatPasswordForm.get('password')?.hasError('pwMeetsRequirments')) {
                            <mat-error id="pwMeetsRequirments">{{pageLabels.passwordMustMeetRequirementsError}}</mat-error>
                        }
                        @else if (creatPasswordForm.get('password')?.hasError('passwordsDontMatch')) {
                            <mat-error id="pwMIsMatchError">{{pageLabels.passwordsDontMatchError}}</mat-error>
                        }
                    </mat-form-field>
                    <div id="passwordReq" [attr.aria-label]="passwordAriaLabelText" class="passwordList">
                        <p class="passwordRequirements">{{pageLabels.passwordRequirementsText}}</p>
                        <ul class="passwordListItems">
                            @if (hasOneNumber) {
                                <li class="reqListItem">{{pageLabels.passwordAtLeastOneNumberText}}</li>
                            }
                            @if (!hasOneNumber) {
                                <li><i class="fa-solid fa-check fa-lg checkMark"></i>{{pageLabels.passwordAtLeastOneNumberText}}</li>
                            }
                            @if (hasOneSpecialCharacter || charNotAllowed) {
                                <li class="reqListItem">{{pageLabels.passwordAtLeastOneSpecialCharacterText}}</li>
                                <li class="reqListItemRegex">{{passwordSpecialCharacterText}}</li>
                            }
                            @if (!hasOneSpecialCharacter && !charNotAllowed) {
                                <li><i class="fa-solid fa-check fa-lg checkMark"></i>{{pageLabels.passwordAtLeastOneSpecialCharacterText}}</li>
                                <li class="reqListItemRegex">{{passwordSpecialCharacterText}}</li>
                            }
                            @if (hasLessthanSevenChar) {
                                <li class="reqListItem">{{pageLabels.passwordAtLeast7To12CharactersLongText}}</li>
                            }
                            @if (!hasLessthanSevenChar) {
                                <li><i class="fa-solid fa-check fa-lg checkMark"></i>{{pageLabels.passwordAtLeast7To12CharactersLongText}}</li>
                            }                         
                          </ul>  
                    </div>

                    <mat-form-field class="password-container" appearance="outline">
                        <mat-label id="passwordConfirmLabel">{{pageLabels.confirmPasswordText}}</mat-label>
                        <input matInput class="custom-input" [type]="confirmPasswordVisible ? 'text' : 'password'" formControlName="confirmPassword"
                            id="CreateNewPasswordConfirm" aria-labelledby="passwordConfirmLabel passwordError confirmPwdError ConfirmPwMinLengthErr 
                            ConfirmPwMaxLengthErr confirmedPasswordMisMatchError ConfirmPwMeetsRequirments" 
                            (blur)="confirmPasswordMatch()" maxlength="12">
                        <button type="button" mat-icon-button matSuffix (click)="toggleConfirmPasswordVisibility()"
                            [attr.aria-label]="confirmPasswordVisible ? pageLabels.hidePasswordAriaLabel : pageLabels.showPasswordAriaLabel">
                            <i class="fa-solid fa-eye" [class.fa-eye]="!confirmPasswordVisible" [class.fa-eye-slash]="confirmPasswordVisible" [class.eye-icon]="true"></i>
                        </button>
                        @if (creatPasswordForm.get('confirmPassword')?.hasError('required')) {
                            <mat-error id="confirmPwdError">{{pageLabels.passwordErrorText}}</mat-error>
                        }
                        @else if (creatPasswordForm.get('confirmPassword')?.hasError('minlength')) {
                            <mat-error id="ConfirmPwMinLengthErr">{{pageLabels.passwordMinLengthError}}</mat-error>
                        }
                        @else if (creatPasswordForm.get('confirmPassword')?.hasError('maxlength')) {
                            <mat-error id="ConfirmPwMaxLengthErr">{{pageLabels.passwordMaxLengthError}}</mat-error>
                        }
                        @else if (creatPasswordForm.get('confirmPassword')?.hasError('confirmPwMeetsRequirments')) {
                            <mat-error id="ConfirmPwMeetsRequirments">{{pageLabels.passwordMustMeetRequirementsError}}</mat-error>
                        }
                        @else if (creatPasswordForm.get('confirmPassword')?.hasError('passwords2DontMatch')) {
                            <mat-error id="confirmedPasswordMisMatchError">{{pageLabels.passwordsDontMatchError}}</mat-error>
                        }
                    </mat-form-field>

                    <button mat-flat-button class="gs-form-button-primary continue-button" color="primary">{{pageLabels.createNewPasswordContinueBtnText}}</button>
                </form>
                <p class="help-text" [innerHTML]="pageLabels.helpText"></p>
                <a class="login-link" (click)="goToLogin()" href>{{pageLabels.backLinkText}}</a>
            </div>
        </div>
        <div class="account-benefits create-password-section">
            <gs-icon-list [ListData]="pageLabels.accountBenefitsList" (DebugData)="logDebug($event)"></gs-icon-list>
        </div>
    </div>
</main>