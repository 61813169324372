import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent {
  constructor (
    private readonly router: Router
  ) {}

  async goToLogin (): Promise<void> {
    await this.router.navigateByUrl('/login');
  }
}
