
<app-mobile-app-banner></app-mobile-app-banner>
<main *ngIf="pageLabels$ | async as pageLabels" class="gs-page">
    <div class="login-page">
        <div class="gradient-container login-section" [style]="'background:url(' + pageLabels.gradientBackgroundUrl + ')'">
            <div class="login-form-container">
                <img [src]='pageLabels.gasSouthLogoLg' alt='Gas South Logo' class="logo-image">
                <div id="apiErrorLabel" *ngIf="apiError !== ''" tabindex="0" class="gs-post-notification-error">
                    <i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                    <span [innerHTML]="apiError"></span>
                </div>
                <div id="formErrorLabel" *ngIf="formErrorMessage != ''" tabindex="0" class="gs-post-notification-error">
                    <i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                    <span [innerHTML]="formErrorMessage"></span>
                </div>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
                    <mat-form-field class="gs-form-field email-field" appearance="outline">
                        <mat-label id="emailLabel">{{pageLabels.usernameEmailLabel}}</mat-label>
                        <input matInput class="custom-input" formControlName="email" id="email" aria-labelledby="emailLabel emailError">
                        @if (loginForm.get('email')?.hasError('required')) {
                            <mat-error id="emailError">{{pageLabels.usernameEmailErrorText}}</mat-error>
                        }
                    </mat-form-field>

                    <mat-form-field class="password-container" appearance="outline">
                        <mat-label id="passwordLabel">{{pageLabels.passwordLabel}}</mat-label>
                        <input matInput class="custom-input" [type]="passwordVisible ? 'text' : 'password'" formControlName="password"
                            id="LoginPassword" aria-labelledby="passwordLabel passwordError">
                        <button type="button" mat-icon-button matSuffix (click)="togglePasswordVisibility()"
                            [attr.aria-label]="passwordVisible ? pageLabels.hidePasswordAriaLabel : pageLabels.showPasswordAriaLabel">
                            <i class="fa-solid fa-eye" [class.fa-eye]="!passwordVisible" [class.fa-eye-slash]="passwordVisible" [class.eye-icon]="true"></i>
                        </button>
                        @if (loginForm.get('password')?.hasError('required')) {
                            <mat-error id="passwordError">{{pageLabels.passwordErrorText}}</mat-error>
                        }
                        @else if (loginForm.get('password')?.hasError('minlength')) {
                            <mat-error id="pwMinLengthErr">{{pageLabels.passwordMinLengthError}}</mat-error>
                        }
                        @else if (loginForm.get('password')?.hasError('maxlength')) {
                            <mat-error id="pwMaxLengthErr">{{pageLabels.passwordMaxLengthError}}</mat-error>
                        }
                    </mat-form-field>

                    <div class="gs-form-field remember-me-container">
                        <mat-checkbox id="RememberMe" color="primary" formControlName="rememberMe">{{pageLabels.rememberMeCbLabel}}</mat-checkbox>
                    </div>

                    <button mat-flat-button class="gs-form-button-primary login-button" color="primary">{{pageLabels.loginButtonText}}</button>
                </form>
                <a class="forgot-account" href="{{pageLabels.forgotUsernamePasswordLinkUrl}}">{{pageLabels.forgotUsernamePasswordText}}</a>
                <p class="register-text">{{pageLabels.registerText}}</p>
                <a class="create-account" href="{{pageLabels.registerLinkUrl}}">{{pageLabels.registerLinkText}}</a>
            </div>
        </div>
        <div class="account-benefits login-section">
            <gs-icon-list [ListData]="pageLabels.accountBenefitsList" (DebugData)="logDebug($event)"></gs-icon-list>
        </div>
    </div>
</main>