export class SuccessData {
  public Header: string = '';
  public Subheader: string = '';
  public PaymentAmount: PaymentAmount = new PaymentAmount();
  public Details: SuccessDetails[] = [];
  public AutopayLinkText: string = '';
  public AutopayLinkAriaLabel: string = '';
  public CTA: CTA = new CTA();
  public isFutureDatedPayment: boolean = false;
  public FutureDatedPaymentNote: string = '';
}

export class PaymentAmount {
  public Label!: string;
  public Value?: number;
}

export class SuccessDetails {
  public Label!: string;
  public Value!: string;
}

export class CTA {
  public Label!: string;
  public Link!: string;
  public AriaLabel!: string;
}
