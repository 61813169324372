import { type Routes } from '@angular/router';
import { LoginComponent } from './login-feature/login/login.component';
import { ForgotPasswordComponent } from './login-feature/forgot-password/forgot-password.component';
import { RegisterComponent } from './login-feature/register/register.component';
import { AccountSummaryComponent } from './account-summary-feature/account-summary/account-summary.component';
import { MultiAccountSummaryComponent } from './account-summary-feature/multi-account-summary/multi-account-summary.component';
import { PendingStartComponent } from './account-summary-feature/pending-start/pending-start.component';
import { SeasonalComponent } from './account-summary-feature/seasonal/seasonal.component';
import { PaymentComponent } from './payment-feature/payment/payment.component';
import { BankAccountFormComponent } from './shared/components/bank-account-form/bank-account-form.component';
import { CreditCardFormComponent } from './shared/components/credit-card-form/credit-card-form.component';
import { SuccessPageComponent } from './shared/components/success-page/success-page.component';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';
import { CancelFuturePaymentComponent } from './shared/components/cancel-future-payment/cancel-future-payment.component';

export const routes: Routes = [
  { path: 'payment-success', component: SuccessPageComponent },
  { path: 'credit-card-form', component: CreditCardFormComponent },
  { path: 'bankaccount', component: BankAccountFormComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'multiaccount-summary', component: MultiAccountSummaryComponent },
  { path: 'pending-start', component: PendingStartComponent },
  { path: 'seasonal', component: SeasonalComponent },
  { path: 'account-summary', component: AccountSummaryComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot', component: ForgotPasswordComponent },
  { path: 'login', component: LoginComponent },
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'error-page', component: ErrorPageComponent },
  { path: 'cancel-future-payment', component: CancelFuturePaymentComponent },
  { path: 'cancel-future-payment-success', component: SuccessPageComponent }
];
