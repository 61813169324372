export class ErrorData {
  public Header: string = '';
  public Subheader: string = '';
  public CtaButton: CtaButton = new CtaButton();
  public CtaLink: CtaLink = new CtaLink();
}

export class CtaButton {
  public Label!: string;
  public Link!: string;
  public AriaLabel!: string;
}

export class CtaLink {
  public Label!: string;
  public Link!: string;
  public AriaLabel!: string;
}
