@if (pageReady) {
    <main class="gs-page">
        <div class="wallet-container">
            <div class="headline-container">
                <div class="headline-content">
                    <h1 class="headline h1-lg">Manage Wallet</h1>
                    <div>Filler headline content container</div>
                </div>
            </div>
            <div class="notification-container">
                @if (formErrorMessage != '') {
                <div id="formErrorLabel" tabindex="0" class="gs-post-notification-error">
                    <i aria-hidden="true" class="fa-solid fa-triangle-exclamation" style="color:#EB0000;"></i>
                    <span [innerHTML]="formErrorMessage"></span>
                </div>
                }
            </div>
            <div class="gs-content-container">
                <div class="current-method-container">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>
                                Auto-Pay
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <p>Name of pay method</p>
                            <p>method description</p>
                            <p>method expiration date</p>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="pay-method-container">
                    <div class="list-header">
                        <h3>Cards and Accounts</h3>
                        <a role="button" (click)="addNewPayMethod()">+Add</a>
                    </div>
                    <form [formGroup]="walletForm">
                        <div class="paymethod-list" formArrayName="PayForms">
                            @for (paymethod of paymethods; let i = $index; track i) {
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header class="paymethod-">
                                        <mat-panel-title class="method-header">
                                            <div class="method-img" [innerHTML]="getPayMethodIcon(paymethod.PaymentAccountType)"></div>
                                            <p class="method-header">{{paymethod.Nickname ?? paymethod.PaymentAccountType}}</p>
                                        </mat-panel-title>
                                        <mat-panel-description class="method-desc-container">
                                            <div class="paymethod-status">
                                                @if (!paymethod.IsAssociatedAutoPay) {
                                                    <p>Auto-Pay</p>
                                                }
                                                @if (!paymethod.IsExpired) {
                                                    <p>Expired</p>
                                                }
                                            </div>
                                            <div class="paymethod-details">
                                                <p class="method-description">...{{paymethod.LastFour}}</p>
                                            </div>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div>
                                        @if (paymethod.BankCardDigital === 'BANK') {
                                            <form [formGroupName]="i">
                                                <div>
                                                    <mat-form-field appearance="outline" color="primary">
                                                        <mat-label>Routing Number</mat-label>
                                                        <input readonly type="text" matInput [value]="paymethod.BankRoutingNumber">
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" color="primary">
                                                        <mat-label>AccountNumber</mat-label>
                                                        <input formControlName="AccountNumber" matInput type="text" id="accountNumber" [value]="paymethod.BankAccountNumber">
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" color="primary">
                                                        <mat-label>Account Type</mat-label>
                                                        <input readonly type="text" matInput [value]="paymethod.BankAccountType">
                                                    </mat-form-field>
                                                </div>
                                                <div>
                                                    <mat-form-field appearance="outline" color="primary">
                                                        <mat-label>Nickname(optional)</mat-label>
                                                        <input formControlName="Nickname" type="text" matInput [value]="paymethod.Nickname ?? ''">
                                                    </mat-form-field>
                                                    @if (paymethod.IsDefault) {
                                                        <mat-checkbox formControlName="SetDefault" color="primary">Set as Default</mat-checkbox>
                                                    }
                                                </div>
                                                <button (click)="editPayMethod(i)">submit</button>
                                                <a role="button" (click)="deletePayMethod(paymethod)">Remove payment Method</a>
                                            </form>
                                        }
                                        @else if (paymethod.BankCardDigital === 'CARD') {
                                            <form [formGroupName]="i">
                                                <div>
                                                    <mat-form-field appearance="outline" color="primary">
                                                        <mat-label>Card Number</mat-label>
                                                        <input readonly type="text" matInput [value]="paymethod.BankRoutingNumber ?? paymethod.LastFour">
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" color="primary">
                                                        <mat-label>Expiration Date</mat-label>
                                                        <input formControlName="ExpDate" matInput type="text" placeholder="MM/YY" id="expiration-date"
                                                            [dropSpecialCharacters]="false" mask="00/00" [value]="paymethod.ExpDate">
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" color="primary">
                                                        <mat-label>Security Code</mat-label>
                                                        <input formControlName="SecurityCode" type="text" matInput>
                                                    </mat-form-field>
                                                </div>
                                                <div>
                                                    <mat-form-field appearance="outline" color="primary">
                                                        <mat-label>Nickname(optional)</mat-label>
                                                        <input formControlName="Nickname" type="text" matInput [value]="paymethod.Nickname ?? ''">
                                                    </mat-form-field>
                                                    @if (paymethod.IsDefault) {
                                                        <mat-checkbox formControlName="SetDefault" color="primary">Set as Default</mat-checkbox>
                                                    }
                                                </div>
                                                <button (click)="editPayMethod(i)">submit</button>
                                                <a role="button" (click)="deletePayMethod(paymethod)">Remove payment Method</a>
                                            </form>
                                        }
                                        @else {
                                            <form [formGroupName]="i">
                                                <a role="button" (click)="deletePayMethod(paymethod)">Remove payment Method</a>
                                            </form>
                                        }
                                    </div>
                                </mat-expansion-panel>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <ng-template #deletePaymentDialogTemplate let-data>
                <div class="form-wrapper">
                    <h2>{{data.title}}</h2>
                    <div class="dialog-content">
                    <p>{{data.content}}</p>
                    </div>
                    <div class="buttons-wrapper">
                        <button id="cancelBtn" type="button" mat-flat-button mat-dialog-close 
                        [attr.aria-label]="pageLabels.deletePaymentMethodCancelLinkAriaLabel"
                            class="gs-form-button-secondary cancel-button" color="primary">
                            {{ pageLabels.deletePaymentMethodCancelButtonText }}
                        </button>
                        <button id="continueBtn" mat-flat-button class="gs-form-button-primary" color="primary" [mat-dialog-close]="true"
                        [attr.aria-label]="pageLabels.deletePaymentMethodButtonContinueAriaLabel">
                            {{ this.pageLabels.deletePaymentMethodButtonContinueText }}
                        </button>
                    </div>
                </div>
          </ng-template>
    </main>
}
