import { Component } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../shared/services/util.service';

@Component({
  selector: 'app-pending-start',
  standalone: true,
  imports: [],
  templateUrl: './pending-start.component.html',
  styleUrl: './pending-start.component.css'
})
export class PendingStartComponent {
  constructor (
    private readonly utils: UtilService
  ) {}

  ngOnInit (): void {
    this.utils.hideSpinner();
  }
}
