/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/unbound-method */
import { Component, ElementRef, Inject, PLATFORM_ID, ViewChild, ViewEncapsulation, type OnInit } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { CommonModule, CurrencyPipe, DecimalPipe, formatDate, isPlatformBrowser } from '@angular/common';
import { catchError, type Observable, tap, throwError } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Router } from '@angular/router';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCalendarCellClassFunction, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CreditCardFormComponent } from '../../shared/components/credit-card-form/credit-card-form.component';
import { BankAccountFormComponent } from '../../shared/components/bank-account-form/bank-account-form.component';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../shared/services/util.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from '../../shared/services/storage.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { PaymentService } from '../../shared/services/payment.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { CmsService } from '../../shared/services/cms.service';

import { StorageEnum } from '../../shared/enums/storage.enum';
import { PaymentAccountType } from '../../shared/enums/paymentaccounttype.enum';
import { PaymentMethod } from '../../shared/models/payment-method';
import { type AccountDetail } from '../../shared/models/account-detail';
import { OtherAmountValidator } from '../other-amount-validator';

import { NgxMaskDirective } from 'ngx-mask';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { PaymentDialogResult } from '../../shared/models/payment-dialog-result';
import { add, addDays, endOfMonth, format, isAfter, isEqual, isFuture, toDate } from 'date-fns';
import { BankPayment, CardPayment, FuturePayment, Payment, type PaymentResponse } from '../../shared/models/payment';
import { Person } from '../../shared/models/person';
import { type UserProfile } from '../../shared/models/user-profile';
import { CreditPipe } from '../../shared/pipes/credit.pipe';
import { SuccessData } from '../../shared/models/success-data.model';
import { DialogResultType } from '../../shared/enums/dialogresulttype.enum';
import { PaymentusService } from '../../shared/services/paymentus.service';
import { environment } from '../../../environments/environment.development';
import { ErrorData } from '../../shared/models/error-data';
import { DigitalPaymentModalComponent } from '../../shared/components/digital-payment-modal/digital-payment-modal.component';
import { PaymentErrorCode } from '../../shared/enums/paymenterrorcode.enum';

@Component({
  selector: 'app-payment',
  standalone: true,
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss',
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    NgxMaskDirective,
    MatDatepickerModule,
    MatDividerModule,
    MatCardModule,
    MatCheckboxModule,
    MatTooltipModule,
    CreditPipe
  ],
  providers: [
    CreditPipe,
    CurrencyPipe,
    DecimalPipe
  ]
})

export class PaymentComponent implements OnInit {
  pageReady = false;
  paymethods: PaymentMethod[] | undefined = [];
  accountDetail: AccountDetail;
  userProfile?: UserProfile;
  paymentAmounts: any[] = [];
  paymentForm: FormGroup;
  pageLabels$!: Observable<any>;
  pageLabels: any = {};
  isBrowser = false;
  canSchedulePayment = false;
  todaysDay: string = new Date().toISOString();
  showBankLimitNotification = false;
  showCardLimitNotification = false;
  showFuturePaymentPreNotification = false;
  showOnAutoPayPreNotification = false;
  showLimitedPaymentOptionsPreNotification = false;
  formErrorMessage?: string = '';
  showBill = false;
  showTermsConditions = false;
  paySummaryMethodLabel = '';
  applePayURL = '';
  paymentErrors: any [] = [];
  @ViewChild('payAmount') payAmountEl!: HTMLElement;
  @ViewChild('datepickerFooter') datepickerFooter!: ElementRef;
  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    // Only highligh dates inside the month view.
    if (view === 'month') {
      const date = cellDate.toLocaleDateString();
      if (!this.accountDetail.IsPayPlan) {
        const billDueDate = toDate(this.accountDetail.CurrentBalanceDueDate!).toLocaleDateString();
        // Highlight the bill due date day
        return date === billDueDate ? 'gs-custom-date-class' : '';
      } else if (this.accountDetail.IsPayPlan) {
        let payPlanDueDate: Date | undefined = this.accountDetail.PaymentPlans?.find(x => toDate(x.DueDate!) > this.minDate && toDate(x.DueDate!) < addDays(this.minDate, 30))?.DueDate;
        if (payPlanDueDate !== undefined) {
          payPlanDueDate = toDate(payPlanDueDate);
          // Highlight the payplan due date day
          return date === payPlanDueDate.toLocaleDateString() ? 'gs-custom-date-class' : '';
        }
      }
    }
    return '';
  };

  minDate = new Date();
  maxDate?: Date;
  startDate = this.minDate;
  calendarLegendDueDateText = '';
  calendarLegendSelectedPayDateText = '';
  calendarLegendSelectedDueDateText = '';
  selectedPaymethodType = '';

  constructor (
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) readonly platformId: Object,
    public dialog: MatDialog,
    private readonly utils: UtilService,
    private readonly payment: PaymentService,
    private readonly paymentus: PaymentusService,
    private readonly storage: StorageService,
    private readonly fb: FormBuilder,
    private readonly cms: CmsService,
    private readonly router: Router,
    private readonly currencyP: CurrencyPipe,
    private readonly creditP: CreditPipe,
    private readonly decimalPipe: DecimalPipe
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.accountDetail = this.storage.getSession(StorageEnum.AccountDetail);
    this.userProfile = this.storage.getSession(StorageEnum.LoginResponse);
    this.paymentForm = this.fb.group({
      paydate: ['', [Validators.required, this.validatePaymentDate.bind(this)]],
      paymethod: this.fb.control<PaymentMethod | null>(null, [Validators.required]),
      payamount: [null, [Validators.required]],
      otheramount: [null],
      termsConditionsChkbox: [false, [Validators.required]]
    });
    this.paymentForm.get('otheramount')?.disable();
    this.paymentForm.setValidators(OtherAmountValidator());
    this.paymentErrors = this.storage.getSession(StorageEnum.GlobalVars)?.paymentErrors;
  }

  ngOnInit (): void {
    this.cms.getContent('oas-payment', 'Payment').subscribe(data => {
      this.pageLabels = data;
      if (this.isBrowser) {
        this.getPreferredPayments();
        this.getPaymentOptions();
        this.checkFuturePaymentEligibility();
        this.displayPaymentPreNotifications();
        this.setupFuturePaymentDateCalendar();
      }
    });
    this.setShowBill();
  }

  setupFuturePaymentDateCalendar (): void {
    if (!this.accountDetail.IsPayPlan) {
      // if bill due date + 14 days is greater than 30 days from today, set max payment date to today + 30 days
      if (addDays(this.accountDetail.CurrentBalanceDueDate!, 14) > addDays(this.minDate, 30)) {
        this.maxDate = addDays(this.minDate, 30);
      } else {
        this.maxDate = addDays(this.accountDetail.CurrentBalanceDueDate!, 14);
      }
      this.calendarLegendDueDateText = this.pageLabels.calendarLegendBillDueDateText.replace('{0}', format(this.accountDetail.CurrentBalanceDueDate!, 'MMM dd, yyyy'));
      this.calendarLegendSelectedPayDateText = this.pageLabels.calendarLegendSelectedPayDateText;
      this.calendarLegendSelectedDueDateText = this.pageLabels.calendarLegendSelectedDateDueDateText;
    }
    if (this.accountDetail.IsPayPlan && this.accountDetail.PaymentPlans) {
      const maxDate = this.accountDetail.PaymentPlans?.find(x => toDate(x.DueDate!) > this.minDate && toDate(x.DueDate!) < addDays(this.minDate, 30))?.DueDate;
      if (maxDate !== undefined) {
        this.maxDate = maxDate;
      } else {
        this.canSchedulePayment = false;
        // default payment date to today if no date in form model or not eligible for future payment
        this.todaysDay = formatDate(new Date(), 'MM/dd/yyy', 'en-us');
        this.paymentForm.get('paydate')?.setValue(this.todaysDay);
        return;
      }

      // maxDate = new Date(this.findEligiblePayPlanDate(this.accountSummary.PayPlans));
      this.calendarLegendDueDateText = this.pageLabels.calendarLegendPayPlanDueDateText;
      this.calendarLegendSelectedPayDateText = this.pageLabels.calendarLegendSelectedPayDateText;
      this.calendarLegendSelectedDueDateText = this.pageLabels.calendarLegendSelectedPayPlanDueDateText;
    }
    const endOfMonthDay = format(endOfMonth(this.minDate), 'MM/dd/yyyy');
    const startDateDay = format(this.startDate, 'MM/dd/yyyy');
    // If current day is end of month default start date on calendar to be
    // first day of the next month.
    if (endOfMonthDay === startDateDay) {
      this.startDate = addDays(this.startDate, 1);
    }
  }

  /**
   * returns payplan due date that is within next 30 days
   * @param payPlans
   * @returns eligible date string or undefined if not found
   */
  findEligiblePayPlanDate (payPlans: any[]): Date {
    return payPlans.find(x => x.DueDate > this.minDate && x.DueDate < addDays(this.minDate, 30))?.DueDate;
  }

  onCalendarOpen (): void {
    this.appendFooter();
    if (typeof document !== 'undefined') {
      setTimeout(() => {
        const matDatePickerChild = document.getElementsByClassName('gs-custom-date-class')[0];
        const ariaLabelText = 'Selected Date is the due date';
        matDatePickerChild?.setAttribute('aria-label', ariaLabelText);
      }, 10);
    }
  }

  private appendFooter (): void {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    matCalendar.appendChild(this.datepickerFooter.nativeElement);
  }

  setShowBill (): void {
    if (this.accountDetail?.Bills && this.accountDetail?.Bills?.length > 0) {
      const bill = this.accountDetail.Bills[0];
      const dateToShowBill = format(add(bill.ExtractedDate!, { days: 3 }), 'MM/dd/yyyy');
      const today = new Date();
      const dateIsEqual = isEqual(today, dateToShowBill);
      const dateIsAfter = isAfter(today, dateToShowBill);
      if (dateIsEqual || dateIsAfter) {
        this.showBill = true;
      } else {
        this.showBill = false;
      }
    }
  }

  checkOtherAmount (): void {
    if (this.paymentForm.get('payamount')?.value === 'other') {
      this.paymentForm.get('otheramount')?.enable();
    } else {
      this.paymentForm.get('otheramount')?.disable();
    }
  }

  otherClicked (): void {
    const payamountVal = this.paymentForm.get('payamount');
    if (payamountVal?.value !== 'other') {
      payamountVal?.patchValue('other');
      this.checkOtherAmount();
    }
  }

  openCreditCardForm (): void {
    const dialogRef = this.dialog.open(CreditCardFormComponent, { ariaLabel: 'Credit Card', disableClose: true, autoFocus: 'first-heading', panelClass: 'gs-dialog', data: this.paymentForm.get('paymethod')?.value });

    dialogRef.afterClosed().subscribe((result: PaymentDialogResult) => {
      this.utils.showSpinner();
      if (result.Type === DialogResultType.Cancel) {
        this.paymentForm.patchValue({ paymethod: null });
        this.paySummaryMethodLabel = '';
        this.utils.hideSpinner();
      } else if (result.Type === DialogResultType.Update) {
        // Find Record in the List and Update it
        this.paymethods = this.paymethods?.map(pm => pm.ReferenceId === result.PayMethod.ReferenceId ? result.PayMethod : pm);
        // select Update record
        if (!this.utils.isNullOrWhitespace(result.PayMethod.ReferenceId)) {
          const newSelectedMethod = this.paymethods?.find(pm => {
            return pm.ReferenceId === result.PayMethod.ReferenceId;
          });
          this.paymentForm.patchValue({ paymethod: newSelectedMethod });
          this.setPaySummaryMethodLabel();
        }
      } else if (result.Type === DialogResultType.Add) {
        this.paymethods?.push(result.PayMethod);
        this.paymethods = this.utils.sortPaymentMethods(this.paymethods!, this.accountDetail.IsLimitedPaymentOptions);
        if (!this.utils.isNullOrWhitespace(result.PayMethod.ReferenceId)) {
          const newSelectedMethod = this.paymethods.find(pm => {
            return pm.ReferenceId === result.PayMethod.ReferenceId;
          });
          this.paymentForm.patchValue({ paymethod: newSelectedMethod });
          this.setPaySummaryMethodLabel();
        }
      } else if (result.Type === DialogResultType.Error) {
        this.paymentForm.patchValue({ paymethod: null });
        this.paySummaryMethodLabel = '';
        this.showFormErrorLabel(result.ErrorMessage);
        this.utils.hideSpinner();
      }
      this.storage.setSession(StorageEnum.PaymentMethods, this.paymethods);
      this.utils.hideSpinner();
      this.payAmountNotifications();
    });
  }

  openBankAccountForm (): void {
    const dialogRef = this.dialog.open(BankAccountFormComponent, { ariaLabel: 'Bank Account', disableClose: true, autoFocus: 'first-heading', panelClass: 'gs-dialog' });

    dialogRef.afterClosed().subscribe((result: PaymentDialogResult) => {
      if (result.Type === DialogResultType.Cancel) {
        this.paymentForm.patchValue({ paymethod: null });
        this.paySummaryMethodLabel = '';
        this.utils.hideSpinner();
      } else if (result.Type === DialogResultType.Add) {
        this.paymethods?.push(result.PayMethod);
        this.paymethods = this.utils.sortPaymentMethods(this.paymethods!, this.accountDetail.IsLimitedPaymentOptions);
        this.storage.setSession(StorageEnum.PaymentMethods, this.paymethods);
        if (!this.utils.isNullOrWhitespace(result.PayMethod.ReferenceId)) {
          const newSelectedMethod = this.paymethods.find(pm => {
            return pm.ReferenceId === result.PayMethod.ReferenceId;
          });
          this.paymentForm.patchValue({ paymethod: newSelectedMethod });
          this.setPaySummaryMethodLabel();
        }
      } else if (result.Type === DialogResultType.Error) {
        this.paymentForm.patchValue({ paymethod: null });
        this.paySummaryMethodLabel = '';
        this.showFormErrorLabel(result.ErrorMessage);
        this.utils.hideSpinner();
      }
      this.utils.hideSpinner();
      this.payAmountNotifications();
    });
  }

  getPreferredPayments (): void {
    this.payment.getPreferredPayments(this.accountDetail.AccountNumber)
      .pipe(
        tap((data: any) => {
          this.storage.setSession(StorageEnum.PaymentMethods, data.PaymentMethods);

          if (!data.PaymentMethods) {
            data.PaymentMethods = [];
          }

          this.addNewCardAndBankOptions(data.PaymentMethods);
          this.paymethods = this.utils.sortPaymentMethods(data.PaymentMethods, this.accountDetail.IsLimitedPaymentOptions);
          this.utils.hideSpinner();
          this.pageReady = true;
        })).subscribe();
  }

  getPaymentData (): void {
    this.payment.getPaymentData(this.accountDetail.AccountNumber)
      .pipe(
        tap((data: any) => {
          if (!data.PaymentMethods) {
            data.PaymentMethods = [];
          }

          this.addNewCardAndBankOptions(data.PaymentMethods);

          this.paymethods = this.utils.sortPaymentMethods(data.PaymentMethods, this.accountDetail.IsLimitedPaymentOptions);
          this.accountDetail = { ...this.accountDetail, ...data };
          this.checkFuturePaymentEligibility();
          this.utils.hideSpinner();
          this.pageReady = true;
        })).subscribe();
  }

  addNewCardAndBankOptions (paymethodsList: PaymentMethod[]): void {
    const newBankPayment = new PaymentMethod();
    newBankPayment.ReferenceId = '';
    newBankPayment.Description = this.pageLabels.paymentMethodNewBankText;
    newBankPayment.PaymentAccountType = PaymentAccountType.Banking;
    paymethodsList?.push(newBankPayment);

    const newCardPayment = new PaymentMethod();
    newCardPayment.ReferenceId = '';
    newCardPayment.Description = this.pageLabels.paymentMethodNewCardText;
    newCardPayment.PaymentAccountType = PaymentAccountType.Card;
    paymethodsList?.push(newCardPayment);

    const cashCheckPayment = new PaymentMethod();
    cashCheckPayment.PaymentAccountType = PaymentAccountType.Cash;
    cashCheckPayment.Description = this.pageLabels.payMethodCashText;
    paymethodsList.push(cashCheckPayment);
  }

  checkFuturePaymentEligibility (): void {
    if (this.accountDetail.IsEligibleForFuturePayment &&
      (!this.accountDetail.IsSevered && !this.accountDetail.IsInCollections && !this.accountDetail.IsLimitedPaymentOptions)) {
      this.canSchedulePayment = true;
    } else {
      this.canSchedulePayment = false;
      // default payment date to today if no date in form model or not eligible for future payment
      this.todaysDay = formatDate(new Date(), 'MM/dd/yyy', 'en-us');
      this.paymentForm.get('paydate')?.setValue(this.todaysDay);
    }
  }

  displayPaymentPreNotifications (): void {
    if (this.accountDetail.IsLimitedPaymentOptions) {
      this.showLimitedPaymentOptionsPreNotification = true;
    }
    const balance = this.accountDetail?.CurrentBalance ?? 0;
    if (this.accountDetail.IsAutoPay && balance > 0) {
      const autoPayDate = formatDate(this.accountDetail.CurrentBalanceDueDate!, 'MM/dd/yyy', 'en-us');
      this.showOnAutoPayPreNotification = true;
      this.pageLabels.accountIsOnAutoPayPreNotification = this.pageLabels.accountIsOnAutoPayPreNotification.replace('{{date}}', autoPayDate);
      this.pageLabels.accountIsOnAutoPayPreNotification = this.pageLabels.accountIsOnAutoPayPreNotification.replace('{{$}}', '$' + this.accountDetail.CurrentBalance);
    }
    if (this.accountDetail.FuturePayment?.Info?.PaymentDate && this.accountDetail.FuturePayment?.Info?.PaymentAmount) {
      const futurePaymentDate = formatDate(this.accountDetail.FuturePayment?.Info?.PaymentDate, 'MM/dd/yyy', 'en-us');
      const futurePaymentAmount = this.accountDetail.FuturePayment.Info?.PaymentAmount;
      this.showFuturePaymentPreNotification = true;
      this.pageLabels.futurePaymentScheduledPreNotification = this.pageLabels.futurePaymentScheduledPreNotification.replace('{{date}}', futurePaymentDate);
      this.pageLabels.futurePaymentScheduledPreNotification = this.pageLabels.futurePaymentScheduledPreNotification.replace('{{$}}', '$' + futurePaymentAmount);
    }
  }

  validatePaymentDate (control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return { required: true };
    }
    return null;
  }

  payDateChange (event: any): void {
    const dateValue: Date = event.value;
    const selectedDateIsFuture = isFuture(dateValue);
    if (selectedDateIsFuture) {
      this.showTermsConditions = true;
    } else {
      this.showTermsConditions = false;
    }
    this.startDate = dateValue;
    if (typeof document !== 'undefined') {
      const matTogglerChild = document.querySelector('mat-datepicker-toggle')?.firstElementChild;
      if (toDate(this.accountDetail.CurrentBalanceDueDate!).toString() === toDate(dateValue).toString()) {
        const ariaLabelText = this.pageLabels.calendarCurrentDateDueDateAriaLabelText + format(dateValue, 'MMM dd, yyyy');
        matTogglerChild?.setAttribute('aria-label', ariaLabelText);
      } else {
        const ariaLabelText = this.pageLabels.calendarSelectedPaymentDateAriaLabelText + format(dateValue, 'MMM dd, yyyy');
        matTogglerChild?.setAttribute('aria-label', ariaLabelText);
      }
    }
  }

  validateTermsConditions (): void {
    if (this.canSchedulePayment && this.showTermsConditions) {
      if (!this.paymentForm.value.termsConditionsChkbox) {
        const termsCond = this.paymentForm.get('termsConditionsChkbox');
        if (termsCond) {
          termsCond.setErrors({ termsConditionsError: true });
        }
      }
    }
  }

  setPaySummaryMethodLabel (): void {
    if (this.paymentForm.get('paymethod')?.value?.Nickname) {
      if (this.paymentForm.get('paymethod')?.value?.LastFour) {
        this.paySummaryMethodLabel = this.paymentForm.get('paymethod')?.value?.Nickname + ' ending in ' + this.paymentForm.get('paymethod')?.value?.LastFour;
      } else {
        this.paySummaryMethodLabel = this.paymentForm.get('paymethod')?.value?.Nickname;
      }
    } else {
      this.paySummaryMethodLabel = this.paymentForm.get('paymethod')?.value?.Description;
    }
  }

  paymethodChanged (): void {
    this.setPaySummaryMethodLabel();
    const selectedPaymethod: PaymentMethod = this.paymentForm.get('paymethod')?.value;
    this.selectedPaymethodType = this.utils.isCardBankDigital(selectedPaymethod.PaymentAccountType);
    if (this.selectedPaymethodType === 'BANK') {
      if (!selectedPaymethod.ReferenceId) {
        this.openBankAccountForm();
      }
    } else if (this.selectedPaymethodType === 'DIGITAL') {
      if (!selectedPaymethod.ReferenceId) {
        this.openDigitalPayment(selectedPaymethod.PaymentAccountType!);
      }
    } else if (this.selectedPaymethodType === 'CARD') {
      this.openCreditCardForm();
    } else if (this.selectedPaymethodType === 'CASH') {
      this.utils.consoleLog('cash money');
    } else {
      this.utils.consoleLog(`selectedPaymethodType: ${this.selectedPaymethodType}`);
    }
    this.payAmountNotifications();
  }

  openDigitalPayment (paymentType: PaymentAccountType): void {
    const page = window.location.origin + '/payment';
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    this.paymentus.getDigitalPaymentToken(this.accountDetail?.AccountNumber!, paymentType, page)
      .pipe(
        catchError((dataError: any) => {
          void this.router.navigateByUrl('/error-page');
          return throwError(() => new Error(dataError));
        }),
        tap((data: string) => {
          const url = environment.paymentusDigitalTokenUrl() + '?authToken=' + data;
          this.createIframe(url, page, paymentType);
        })).subscribe();
  }

  createIframe (url: string, page: string, paymentAccountType: PaymentAccountType): void {
    const dialogRef = this.dialog.open(DigitalPaymentModalComponent, {
      ariaLabel: paymentAccountType.toString(),
      disableClose: true,
      autoFocus: 'first-heading',
      panelClass: 'gs-dialog',
      data: {
        url,
        page,
        paymentAccountType
      },
      height: '500px',
      width: '500px'
    });

    dialogRef.afterClosed().subscribe((result: PaymentDialogResult) => {
      if (result.Type === DialogResultType.Add) {
        this.paymethods?.push(result.PayMethod);
        this.paymethods = this.utils.sortPaymentMethods(this.paymethods!, this.accountDetail.IsLimitedPaymentOptions);
        if (!this.utils.isNullOrWhitespace(result.PayMethod.ReferenceId)) {
          const newSelectedMethod = this.paymethods.find(pm => {
            return pm.ReferenceId === result.PayMethod.ReferenceId;
          });
          this.paymentForm.patchValue({ paymethod: newSelectedMethod });
          this.setPaySummaryMethodLabel();
        }
      } else if (result.Type === DialogResultType.Cancel) {
        this.paymentForm.patchValue({ paymethod: null });
        this.paySummaryMethodLabel = '';
        this.utils.hideSpinner();
      }
      this.utils.hideSpinner();
    });
  }

  payAmountNotifications (): void {
    const payType = this.paymentForm.get('paymethod')?.value?.Type ?? PaymentAccountType.Unknown;
    const totalBalance = this.accountDetail?.CurrentBalance ?? 0;
    if (payType === PaymentAccountType.Banking && totalBalance > 1000000) {
      this.showBankLimitNotification = true;
    } else {
      this.showBankLimitNotification = false;
    }

    if (payType !== PaymentAccountType.Unknown && payType !== PaymentAccountType.Banking && totalBalance > 50000) {
      this.showCardLimitNotification = true;
    } else {
      this.showCardLimitNotification = false;
    }
  }

  getPaymentOptions (): void {
    const current = this.accountDetail?.CurrentBalance ?? 0;
    const pastDue = this.accountDetail?.PastDueBalance ?? 0;
    const minDue = this.accountDetail?.MinimumPayment ?? 0;
    const isLiheap = this.accountDetail?.PayPlanType?.toUpperCase() === 'LIHEAP';
    this.paymentAmounts = [];

    if (current <= 0) {
      this.paymentAmounts.push({ label: this.pageLabels.payAmountTotalLabel, amount: current, disable: true, tooltip: this.pageLabels.payAmountTotalTooltip });
      this.otherClicked();
    } else {
      if (current === minDue && minDue === pastDue) {
        // show min Due
        this.paymentAmounts.push({ label: this.pageLabels.payAmountMinDueLabel, amount: minDue, disable: false, tooltip: this.pageLabels.payAmountMinDueTooltip });
      } else if (current === pastDue && minDue === 0) {
        // show past due
        this.paymentAmounts.push({ label: this.pageLabels.payAmountPastDueLabel, amount: pastDue, disable: false, tooltip: this.pageLabels.payAmountPastDueTooltip });
      } else if (current === pastDue && pastDue !== minDue) {
        // show pastDue and minDue
        this.paymentAmounts.push({ label: this.pageLabels.payAmountPastDueLabel, amount: pastDue, disable: false, tooltip: this.pageLabels.payAmountPastDueTooltip });
        this.paymentAmounts.push({ label: this.pageLabels.payAmountMinDueLabel, amount: minDue, disable: false, tooltip: this.pageLabels.payAmountMinDueTooltip });
      } else if (current !== pastDue && pastDue === minDue && minDue > 0) {
        // show current and min
        this.paymentAmounts.push({ label: this.pageLabels.payAmountTotalLabel, amount: current, disable: false, tooltip: this.pageLabels.payAmountTotalTooltip });
        this.paymentAmounts.push({ label: this.pageLabels.payAmountMinDueLabel, amount: minDue, disable: false, tooltip: this.pageLabels.payAmountMinDueTooltip });
      } else if (current !== pastDue && pastDue !== minDue) {
        // show total,  pastDue and minDue
        this.paymentAmounts.push({ label: this.pageLabels.payAmountTotalLabel, amount: current, disable: false, tooltip: this.pageLabels.payAmountTotalTooltip });
        this.paymentAmounts.push({ label: this.pageLabels.payAmountPastDueLabel, amount: pastDue, disable: false, tooltip: this.pageLabels.payAmountPastDueTooltip });
        if (minDue > 0) {
          this.paymentAmounts.push({ label: this.pageLabels.payAmountMinDueLabel, amount: minDue, disable: false, tooltip: this.pageLabels.payAmountMinDueTooltip });
        }
      } else {
        // show current
        this.paymentAmounts.push({ label: this.pageLabels.payAmountTotalLabel, amount: current, disable: false, tooltip: this.pageLabels.payAmountTotalTooltip });
      }

      if (this.accountDetail.IsPayPlan) {
        if (isLiheap) {
          this.paymentAmounts.push({ label: this.pageLabels.payAmountEapLabel, amount: this.accountDetail.PaymentPlans ? this.accountDetail.PaymentPlans[0].AmountDue : 0, disable: false, tooltip: this.pageLabels.payAmountEapTooltip });
        } else {
          this.paymentAmounts.push({ label: this.pageLabels.payAmountPayPlanLabel, amount: this.accountDetail.PaymentPlans ? this.accountDetail.PaymentPlans[0].AmountDue : 0, disable: false, tooltip: this.pageLabels.payAmountPayPlanTooltip });
        }
      }
      // Set Pay Amount to first item in the list, since we are Selecting it by default.
      // so Pay button in Summary section will have amount populated
      this.paymentForm.get('payamount')?.setValue(this.paymentAmounts[0].amount);
    }
  }

  showFormErrorLabel (errorMessage?: string): void {
    this.formErrorMessage = errorMessage;
    setTimeout(() => {
      const errorEl = document.getElementById('formErrorLabel');
      errorEl?.focus();
    }, 10);
  }

  onSubmit (): void {
    const payDate = this.paymentForm.get('paydate')?.value;
    this.formErrorMessage = '';
    this.paymentForm.markAllAsTouched();
    this.validateTermsConditions();
    if (this.paymentForm.valid) {
      this.utils.showSpinner();
      const payDateTime = new Date(payDate).getTime();
      const todayDateTime = new Date(this.todaysDay).getTime();
      if (payDateTime > todayDateTime) {
        this.makeFuturePayment();
      } else if (this.paymentForm.get('paymethod')?.value?.PaymentAccountType !== PaymentAccountType.Banking) {
        this.makeCardPayment();
      } else {
        this.makeBankPayment();
      }
    } else {
      const errorMessage = this.storage.getSession(StorageEnum.GlobalVars).formError;
      this.showFormErrorLabel(errorMessage);
    }
  }

  setSuccessData (payRequest: BankPayment, payResponse: PaymentResponse): void {
    const successLabels = this.pageLabels.paymentSuccessLabels.value.data;
    const success = new SuccessData();
    success.Header = successLabels.header;
    success.Subheader = successLabels.subheader.replace('{0}', payRequest.Person.Email);
    success.PaymentAmount.Label = successLabels.paymentAmountLabel;
    success.PaymentAmount.Value = payRequest.Amount;
    if (this.selectedPaymethodType === 'DIGITAL') {
      const selectedPaymethod = this.paymentForm.get('paymethod')?.value;
      success.Details.push({ Label: successLabels.details[0].detailLabel, Value: `${selectedPaymethod.PaymentAccountType}` });
    } else {
      success.Details.push({ Label: successLabels.details[0].detailLabel, Value: `${payRequest.Nickname ? payRequest.Nickname : payRequest.PaymentAccountType} ending in ${payRequest.LastFour}` });
    }
    success.Details.push({ Label: successLabels.details[1].detailLabel, Value: `${payResponse.ConfirmationNumber}` });
    success.Details.push({ Label: successLabels.details[2].detailLabel, Value: `${this.creditP.transform(this.currencyP.transform(payResponse.CurrentBalance))}` });
    success.AutopayLinkText = successLabels.autopayLinkText;
    success.AutopayLinkAriaLabel = successLabels.autopayLinkAriaLabel;
    success.CTA.Label = successLabels.ctaLabel;
    success.CTA.Link = successLabels.ctaLink;
    success.CTA.AriaLabel = successLabels.ctaAriaLabel;

    this.storage.setSession(StorageEnum.SuccessData, success);
  }

  setFutureDatedPaymentSuccessData (payRequest: Payment, payResponse: PaymentResponse): void {
    const successLabels = this.pageLabels.paymentSuccessLabels.value.data;
    const success = new SuccessData();
    success.isFutureDatedPayment = true;
    success.Header = successLabels.futureDatedPaymentHeader;
    success.Subheader = successLabels.futureDatedPaymentSubHeader.replace('{0}', '$' + payRequest.Amount);
    success.Subheader = success.Subheader.replace('{1}', format(payRequest.Date!, 'MMMM dd, yyyy'));
    success.PaymentAmount.Label = successLabels.paymentAmountLabel;
    success.PaymentAmount.Value = payRequest.Amount;
    if (this.selectedPaymethodType === 'DIGITAL') {
      const selectedPaymethod = this.paymentForm.get('paymethod')?.value;
      success.Details.push({ Label: successLabels.details[0].detailLabel, Value: `${selectedPaymethod.PaymentAccountType}` });
    } else {
      success.Details.push({ Label: successLabels.details[0].detailLabel, Value: `${payRequest.Nickname ? payRequest.Nickname : payRequest.PaymentAccountType} ending in ${payRequest.LastFour}` });
    }
    success.Details.push({ Label: successLabels.details[3].detailLabel, Value: format(payRequest.Date!, 'MM/dd/yyyy') });
    success.Details.push({ Label: successLabels.details[1].detailLabel, Value: `${payResponse.ConfirmationNumber}` });

    success.AutopayLinkText = successLabels.autopayLinkText;
    success.AutopayLinkAriaLabel = successLabels.autopayLinkAriaLabel;
    success.CTA.Label = successLabels.ctaLabel;
    success.CTA.Link = successLabels.ctaLink;
    success.CTA.AriaLabel = successLabels.ctaAriaLabel;
    success.FutureDatedPaymentNote = successLabels.futureDatedPaymentCancelNote;
    this.storage.setSession(StorageEnum.SuccessData, success);
  }

  setSuccessDataWithError (payRequest: BankPayment, errorCode: string): void {
    const successLabels = this.pageLabels.paymentSuccessLabels.value.data;
    const success = new SuccessData();
    const newPaymentError = this.paymentErrors.find(error => {
      return error.paymentError?.errorCode === errorCode;
    });

    success.Header = newPaymentError.paymentError?.errorHeader; // errorDetail.Header;
    success.Subheader = newPaymentError.paymentError?.displayMessage;
    success.PaymentAmount.Label = successLabels.paymentAmountLabel;
    success.PaymentAmount.Value = payRequest.Amount;
    if (this.selectedPaymethodType === 'DIGITAL') {
      const selectedPaymethod = this.paymentForm.get('paymethod')?.value;
      success.Details.push({ Label: successLabels.details[0].detailLabel, Value: `${selectedPaymethod.PaymentAccountType}` });
    } else {
      success.Details.push({ Label: successLabels.details[0].detailLabel, Value: `${payRequest.Nickname ? payRequest.Nickname : payRequest.PaymentAccountType} ending in ${payRequest.LastFour}` });
    }
    success.Details.push({ Label: successLabels.details[4].detailLabel, Value: `${this.creditP.transform(this.currencyP.transform(payRequest.CurrentBalance))}` });
    success.AutopayLinkText = successLabels.autopayLinkText;
    success.AutopayLinkAriaLabel = successLabels.autopayLinkAriaLabel;
    success.CTA.Label = successLabels.ctaLabel;
    success.CTA.Link = successLabels.ctaLink;
    success.CTA.AriaLabel = successLabels.ctaAriaLabel;
    this.storage.setSession(StorageEnum.SuccessData, success);
  }

  makeBankPayment (): void {
    const payRequest = this.buildBankRequest();
    this.payment.makeBankPayment(payRequest).subscribe({
      error: (e) => {
        if (e.message === PaymentErrorCode.PaymentNotPostedtoCCS) {
          this.setSuccessDataWithError(payRequest, e.message);
          void this.router.navigateByUrl('/payment-success');
        } else {
          this.setErrorData(e.message, payRequest);
          void this.router.navigateByUrl('/error-page');
        }
      },
      next: (data: PaymentResponse) => {
        this.refreshPaymentData(data);
        this.setSuccessData(payRequest, data);
        void this.router.navigateByUrl('/payment-success');
      }
    });
  }

  makeCardPayment (): void {
    const payRequest = this.buildCardRequest();
    this.payment.makeCreditCardPayment(payRequest).subscribe({
      error: (e) => {
        if (e.message === PaymentErrorCode.PaymentNotPostedtoCCS) {
          this.setSuccessDataWithError(payRequest, e.message);
          void this.router.navigateByUrl('/payment-success');
        } else {
          this.setErrorData(e.message, payRequest);
          void this.router.navigateByUrl('/error-page');
        }
      },
      next: (data: PaymentResponse) => {
        this.refreshPaymentData(data);
        this.setSuccessData(payRequest, data);
        void this.router.navigateByUrl('/payment-success');
      }
    });
  }

  makeFuturePayment (): void {
    const selectedPaymethod = this.paymentForm.get('paymethod')?.value;
    let futurePayment = new FuturePayment();
    futurePayment = this.buildPaymentRequest();
    futurePayment.ActionCode = 'A';
    futurePayment.BankAccountType = selectedPaymethod.BankAccountType;
    this.payment.postFuturePayment(futurePayment).subscribe({
      error: (e) => {
        this.setErrorData(e.message);
        void this.router.navigateByUrl('/error-page');
      },
      next: (data: PaymentResponse) => {
        this.refreshPaymentData(data);
        this.setFutureDatedPaymentSuccessData(futurePayment, data);
        void this.router.navigateByUrl('/payment-success');
      }
    });
  }

  setErrorData (errorCode: string, paymentRequest?: any): void {
    let payType = this.selectedPaymethodType;
    if (this.selectedPaymethodType === 'DIGITAL') {
      const selectedPaymethod = this.paymentForm.get('paymethod')?.value;
      payType = selectedPaymethod.PaymentAccountType;
    } else {
      payType = paymentRequest.Nickname ? paymentRequest.Nickname : paymentRequest.PaymentAccountType;
    }
    const newPaymentError = this.paymentErrors.find(error => {
      return error.paymentError?.errorCode === errorCode;
    });
    const errorLabels = this.pageLabels.paymentErrorLabels.value.data;
    const errorDetail = new ErrorData();
    errorDetail.ErrorCode = newPaymentError.paymentError?.errorCode;
    errorDetail.Header = newPaymentError.paymentError?.errorHeader;
    let errorMessage = newPaymentError.paymentError?.displayMessage;
    if (paymentRequest) {
      errorMessage = errorMessage.replace('{{$}}', '$' + paymentRequest.Amount);
      errorMessage = errorMessage.replace('{{PaymentType}}', payType);
      errorMessage = errorMessage.replace('{{LastFour}}', paymentRequest.LastFour);
    }
    errorDetail.Subheader = errorMessage;
    errorDetail.CtaButton.Label = errorLabels.ctaBackToPaymentCloseLabel;
    errorDetail.CtaButton.Link = errorLabels.ctaBackToPaymentCloseLink;
    errorDetail.CtaButton.AriaLabel = errorLabels.ctaBackToPaymentCloseAriaLabel;
    this.storage.setSession(StorageEnum.ErrorData, errorDetail);
  }

  refreshPaymentData (res: PaymentResponse): void {
    // merge new values back to account detail
    this.accountDetail.CurrentBalance = res.CurrentBalance;
    this.accountDetail.PastDueBalance = res.PastDueBalance;
    this.accountDetail.MinimumPayment = res.MinimumPayment;

    // put updated data into storage
    this.storage.setSession(StorageEnum.AccountDetail, this.accountDetail);
    this.getPaymentOptions();
  }

  buildBankRequest (): BankPayment {
    const selectedPaymethod = this.paymentForm.get('paymethod')?.value;
    let bankPayment = new BankPayment();
    bankPayment = this.buildPaymentRequest();
    bankPayment.BankAccountNumber = selectedPaymethod.BankAccountNumber;
    bankPayment.BankRoutingNumber = selectedPaymethod.BankRoutingNumber;
    bankPayment.BankAccountType = selectedPaymethod.BankAccountType;
    return bankPayment;
  }

  buildCardRequest (): CardPayment {
    const selectedPaymethod = this.paymentForm.get('paymethod')?.value;
    let cardPayment = new CardPayment();
    cardPayment = this.buildPaymentRequest();
    cardPayment.ExpirationDate = selectedPaymethod.ExpDate;
    cardPayment.Fee = '0';
    cardPayment.PostalCode = cardPayment.Person?.Address?.PostalCode;
    cardPayment.Cvv = 0;
    return cardPayment;
  }

  buildPaymentRequest (): Payment {
    const selectedPaymethod = this.paymentForm.get('paymethod')?.value;

    const person = new Person();
    person.Email = this.accountDetail.Email;
    person.FirstName = this.accountDetail.FirstName;
    person.LastName = this.accountDetail.LastName;

    const payment = new Payment();
    if (this.paymentForm.get('payamount')?.value === 'other') {
      payment.Amount = this.paymentForm.get('otheramount')?.value;
    } else {
      // update payment amount to include trailing zeros and 2 decimal places.
      let payAmountformatted = this.paymentForm.get('payamount')?.value;
      payAmountformatted = this.decimalPipe.transform(payAmountformatted, '1.2-2');
      payment.Amount = payAmountformatted;
    }
    payment.Date = this.paymentForm.get('paydate')?.value;
    payment.AccountNumber = this.accountDetail.AccountNumber;
    payment.Person = person;
    payment.IsInSeverance = this.accountDetail.IsSevered;
    payment.Token = selectedPaymethod.ReferenceId;
    payment.LastFour = selectedPaymethod.LastFour;
    payment.PaymentAccountType = selectedPaymethod.PaymentAccountType?.replaceAll(' ', '');
    if (selectedPaymethod.Nickname) {
      payment.Nickname = selectedPaymethod.Nickname;
    }
    if (selectedPaymethod.SaveToAccount) {
      payment.SaveToAccount = selectedPaymethod.SaveToAccount;
    } else {
      payment.SaveToAccount = false;
    }
    payment.SaveAsDefault = selectedPaymethod.IsDefault;
    payment.UserName = this.userProfile?.Accounts[0].UserName;
    payment.CurrentBalance = this.accountDetail.CurrentBalance;
    payment.PastDueBalance = this.accountDetail.PastDueBalance;
    payment.MinimumPayment = this.accountDetail.MinimumPayment;
    return payment;
  }

  findLocations (): void {
    window.open('https://www.gas-south.com/paymentlocations.aspx', '_blank');
  }

  accountHasBalance (): boolean {
    const totalBalance = this.accountDetail?.CurrentBalance ?? 0;
    return totalBalance > 0;
  }
}
