/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/unbound-method */
import { CommonModule } from '@angular/common';
import { catchError, map, throwError, type Observable } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Router } from '@angular/router';
import { GSIconListBulletModel, GSIconListModel, GsIconListComponent } from 'gas-south';
import { StorageEnum } from '../../shared/enums/storage.enum';
import { Component, OnInit, Renderer2 } from '@angular/core';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../shared/services/util.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from '../../shared/services/storage.service';
import { CookieService } from 'ngx-cookie-service';
import { CmsService } from '../../shared/services/cms.service';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-create-password',
  standalone: true,
  templateUrl: './create-password.component.html',
  styleUrl: './create-password.component.scss',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GsIconListComponent,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule
  ]
})
export class CreatePasswordComponent implements OnInit {
  pageLabels$!: Observable<any>;
  creatPasswordForm!: FormGroup;
  apiErrors: any[] = [];
  apiError: string = '';
  isHover = false;
  isFocus = false;
  passwordVisible = false;
  confirmPasswordVisible = false;
  formErrorMessage: string = '';
  hasOneSpecialCharacter = false;
  hasOneNumber = false;
  hasLessthanSevenChar = false;
  charNotAllowed = false;
  passwordSpecialCharacterText = '(can\'t be ()+=-[]\\;,./{}|":<>`~)';
  passwordAriaLabelText = `password requirements must have at least one number
  at least one special character and cannnot be left parentheseis right parenthesis
  plus sign equal sign minus sign open brace close brace 
  back slash semi colon comma period forward slash open bracket close bracket
  pipe perenthesis colon less than angle greater than angle back tick tilde and at least 7-12 characters long`;

  constructor (
    private readonly cms: CmsService,
    private readonly auth: AuthService,
    private readonly formBuilder: FormBuilder,
    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    private router: Router,
    private readonly utils: UtilService,
    private readonly storage: StorageService,
    private readonly cookieService: CookieService,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit (): void {
    this.pageLabels$ = this.cms.getContent('forgot-username-password', 'Forgot')
      .pipe(
        map((data: any) => {
          const unmappedListData = data.bulletList.value.data;
          const listData = new GSIconListModel();
          listData.Header = unmappedListData.header;
          listData.Subheader = unmappedListData.subheader;

          const bulletList: GSIconListBulletModel[] = [];
          unmappedListData?.bulletList?.forEach((bullet: any) => {
            const bulletObj = new GSIconListBulletModel();
            bulletObj.BulletText = bullet.bullet.bulletText;
            bulletObj.BulletTitle = bullet.bullet.bulletTitle;
            bulletObj.IconPath = bullet.bullet.iconPath;
            bulletObj.IconAltText = bullet.bullet.iconAltText;
            bulletObj.ShowBullet = bullet.bullet.showBullet;

            bulletList.push(bulletObj);
          });

          listData.BulletList = bulletList;

          const newDataObj = JSON.parse(JSON.stringify(data));
          newDataObj.accountBenefitsList = listData;

          return newDataObj;
        })
      );

    this.creatPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.maxLength(12), Validators.minLength(7), this.validatePassword.bind(this)]],
      confirmPassword: ['', [Validators.required, Validators.maxLength(12), Validators.minLength(7), this.validateConfirmPassword.bind(this)]]
    });
    this.utils.hideSpinner();
  }

  get password (): FormControl {
    return this.creatPasswordForm.get('password') as FormControl;
  }

  get confirmPassword (): FormControl {
    return this.creatPasswordForm.get('confirmPassword') as FormControl;
  }

  resetButtonState (): void { // New method
    this.isHover = false;
    this.isFocus = false;
  }

  validatePassword (control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    const passwordPatternOneNumberCheck = /\d/;
    if (!passwordPatternOneNumberCheck.test(value)) {
      this.hasOneNumber = true;
    } else {
      this.hasOneNumber = false;
    }

    const passwordPatternOneSpecialCharCheck = /[*_!@#$%^&?]/;
    if (!passwordPatternOneSpecialCharCheck.test(value)) {
      this.hasOneSpecialCharacter = true;
    } else {
      this.hasOneSpecialCharacter = false;
    }

    if (value.length < 7 || value.length > 12) {
      this.hasLessthanSevenChar = true;
    } else {
      this.hasLessthanSevenChar = false;
    }

    const passwordPatternCharNotAllowedCheck = /[()+=\-[\]\\';,./{}|":<>~\s]/; // /[()+=-[\]\\;,./{}|"":<>/]/;
    if (passwordPatternCharNotAllowedCheck.test(value)) {
      this.charNotAllowed = true;
    } else {
      this.charNotAllowed = false;
    }

    const passwordPatternOneNumber = /\d/;
    if (!passwordPatternOneNumber.test(value)) {
      return { pwMeetsRequirments: true };
    }

    const passwordPatternOneLetter = /[a-zA-Z]/;
    if (!passwordPatternOneLetter.test(value)) {
      return { pwMeetsRequirments: true };
    }

    const passwordPatternCharNotAllowed = /[()+=\-[\]\\';,./{}|":<>~\s]/;
    if (passwordPatternCharNotAllowed.test(value)) {
      return { pwMeetsRequirments: true };
    }

    const passwordPatternOneSpecialChar = /[*_!@#$%^&?]/; // .*[-+_!@#$%^&*.,?] (?=.*[-+_!@#$%^&*.,?]).+$")
    if (!passwordPatternOneSpecialChar.test(value)) {
      return { pwMeetsRequirments: true };
    }
    return null;
  }

  validateConfirmPassword (control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    const passwordPatternOneNumber = /\d/;
    if (!passwordPatternOneNumber.test(value)) {
      return { confirmPwMeetsRequirments: true };
    }

    const passwordPatternOneLetter = /[a-zA-Z]/;
    if (!passwordPatternOneLetter.test(value)) {
      return { confirmPwMeetsRequirments: true };
    }

    const passwordPatternCharNotAllowed = /[()+=\-[\]\\';,./{}|":<>~\s]/;
    if (passwordPatternCharNotAllowed.test(value)) {
      return { confirmPwMeetsRequirments: true };
    }

    const passwordPatternOneSpecialChar = /[*_!@#$%^&?]/; // .*[-+_!@#$%^&*.,?] (?=.*[-+_!@#$%^&*.,?]).+$")
    if (!passwordPatternOneSpecialChar.test(value)) {
      return { confirmPwMeetsRequirments: true };
    }

    return null;
  }

  onSubmit (): void {
    this.apiError = '';
    this.formErrorMessage = '';
    if (this.creatPasswordForm.valid) {
      // this.utils.showSpinner();
      this.apiErrors = this.storage.getSession(StorageEnum.GlobalVars).apiErrors;
      // Handle form submission
      // const userPassword: string = this.password.value;

      // this.auth.getAUP(userLoginName, userPassword).pipe(
      //   catchError((dataError: any) => {
      //     const errorCode = dataError.error.ErrorCode;
      //     const newApiError = this.apiErrors.find(error => {
      //       return error.apiError.errorCode === errorCode;
      //     });
      //     if (newApiError === undefined) {
      //       const genericLoginError = this.apiErrors.find(error => {
      //         return error.apiError.errorCode === 'GenericLoginError';
      //       });
      //       this.apiError = genericLoginError.apiError.displayMessage;
      //       this.showAPIErrorLabel();
      //     } else {
      //       this.apiError = newApiError.apiError.displayMessage;
      //       this.showAPIErrorLabel();
      //     }
      //     // eslint-disable-next-line @typescript-eslint/no-throw-literal
      //     this.utils.hideSpinner();
      //     return throwError(() => new Error(dataError));
      //   })).subscribe((data: any) => {
      //   const statusFlag = data.Accounts[0].ServiceAddresses[0].SaStatusFlg;
      //   if (data.TotalNumAccounts > 1) {
      //     // eslint-disable-next-line @typescript-eslint/no-floating-promises
      //     this.router.navigateByUrl('multiaccount-summary');
      //   } else {
      //     if (statusFlag === '10') {
      //       this.router.navigateByUrl('pending-start');
      //     } else if (statusFlag === '60' || statusFlag === '70') {
      //       this.router.navigateByUrl('sesonal');
      //     } else if (statusFlag === '20' || statusFlag === '40' || statusFlag === '50') {
      //       this.router.navigateByUrl('account-summary');
      //     }
      //   }
      // });
    } else {
      // Mark all controls as touched to trigger validation messages
      this.creatPasswordForm.markAllAsTouched();
      this.showFormErrorLabel();
    }
  }

  confirmPasswordMatch (): void {
    const password = this.creatPasswordForm.get('password');
    const confirmedPassword = this.creatPasswordForm.get('confirmPassword');
    if (password && confirmedPassword) {
      const passwordValue = this.creatPasswordForm.get('password')?.value;
      const confirmPasswordValue = this.creatPasswordForm.get('confirmPassword')?.value;
      if (passwordValue === confirmPasswordValue && passwordValue.length > 0 && confirmPasswordValue.length > 0) {
        confirmedPassword.setErrors(null);
      } else if (passwordValue.length > 0 && confirmPasswordValue.length > 0) {
        if (passwordValue !== confirmPasswordValue) {
          confirmedPassword.setErrors({ passwords2DontMatch: true });
        }
      }
    }
  }

  togglePasswordVisibility (): void {
    this.passwordVisible = !this.passwordVisible;
  }

  toggleConfirmPasswordVisibility (): void {
    this.confirmPasswordVisible = !this.confirmPasswordVisible;
  }

  showFormErrorLabel (): void {
    this.formErrorMessage = this.storage.getSession(StorageEnum.GlobalVars).formError;
    setTimeout(() => {
      const errorEl = document.getElementById('formErrorLabel');
      errorEl?.focus();
    }, 10);
  }

  logDebug (data: any): void {
    this.utils.consoleGroup('Login icon-list Debug Data', data);
  }

  async goToLogin (): Promise<void> {
    await this.router.navigateByUrl('/login');
  }
}
