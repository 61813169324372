import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'credit',
  standalone: true
})
export class CreditPipe implements PipeTransform {
  transform (value: string | null | undefined): string {
    if (value === '' || value === null || value === undefined) {
      return '';
    }

    if (!value.includes('-')) {
      return value;
    } else {
      const credit = value.replace(/[^\d.]+/g, '');
      return `($${credit} Credit)`;
    }
  }
}
