/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.development';
import { BehaviorSubject } from 'rxjs';
import { PaymentMethod } from '../models/payment-method';
import { PaymentAccountType } from '../enums/paymentaccounttype.enum';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  isLoading = false;
  showLoadingMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isNullOrWhitespace (str: string | null | undefined): boolean {
    return str === null || str === undefined || str.trim() === '';
  }

  isNullOrUndefined (value: any): boolean {
    return value === null || value === undefined;
  }

  consoleGroup (title: string, msg: any): void {
    if (environment.enableLogging) {
      console.groupCollapsed(title);
      console.log(msg);
      console.groupEnd();
    }
  }

  consoleLog (msg: any): void {
    if (environment.enableLogging) {
      console.log(msg);
    }
  }

  showSpinner (showMessage = false): void {
    if (typeof document !== 'undefined') {
      const spinner = document.getElementById('spinner');
      spinner?.classList.add('show');
    }
    this.isLoading = true;
    if (showMessage) {
      this.showLoadingMessage.next(true);
    }
  }

  hideSpinner (): void {
    if (typeof document !== 'undefined') {
      const spinner = document.getElementById('spinner');
      spinner?.classList.remove('show');
      this.isLoading = false;
      this.showLoadingMessage.next(false);
    }
  }

  /**
   * Function to check if screen width is lower than passed in width.
   * @param mobileWidth maximum width to be considered mobile(non-inclusive)
   * @returns true if screen size is smaller than mobileWidth, false otherwise
   */
  isMobile (mobileWidth: number): boolean {
    if (typeof window !== 'undefined') {
      return window.screen.width < mobileWidth;
    } else {
      return false;
    }
  }

  isCardExpired (expDate: string | undefined): boolean {
    if (!expDate) {
      return false;
    }

    const month = parseInt(expDate.slice(0, 2), 10);
    const year = parseInt(expDate.slice(2), 10);

    const currentDate = new Date();
    const currMonth = currentDate.getMonth() + 1;
    const currYear = currentDate.getFullYear();

    if (year < currYear || (year === currYear && month < currMonth)) {
      return true;
    }
    return false;
  }

  /**
   *
   * @param paymethodType Takes PaymentAccountType and gives you general classification
   * @returns string 'BANK', 'CARD', 'DIGITAL', 'CASH', 'UNKNOWN'
   */
  isCardBankDigital (paymethodType: PaymentAccountType | undefined): string {
    switch (paymethodType) {
      case PaymentAccountType.Banking || PaymentAccountType.Banking.toString():
        return 'BANK';
      case PaymentAccountType.Visa || PaymentAccountType.Visa.toString():
      case PaymentAccountType.MasterCard || PaymentAccountType.MasterCard.toString():
      case PaymentAccountType.AmericanExpress || PaymentAccountType.AmericanExpress.toString():
      case PaymentAccountType.Discover || PaymentAccountType.Discover.toString():
      case PaymentAccountType.Card || PaymentAccountType.Card.toString():
        return 'CARD';
      case PaymentAccountType.ApplePay || PaymentAccountType.ApplePay.toString():
      case PaymentAccountType.GooglePay || PaymentAccountType.GooglePay.toString():
      case PaymentAccountType.PayPal || PaymentAccountType.PayPal.toString():
      case PaymentAccountType.PayPalCredit || PaymentAccountType.PayPalCredit.toString():
      case PaymentAccountType.Venmo || PaymentAccountType.Venmo.toString():
      case PaymentAccountType.AmazonPay || PaymentAccountType.AmazonPay.toString():
        return 'DIGITAL';
      case PaymentAccountType.Cash || PaymentAccountType.Cash.toString():
        return 'CASH';
      default:
        return 'UNKNOWN';
    }
  }

  sortPaymentMethods (paymethodsList: PaymentMethod[], isLimitedPaymentOptions: boolean = false): PaymentMethod[] {
    let sortedList: PaymentMethod[] = [];
    let newList: PaymentMethod[] = JSON.parse(JSON.stringify(paymethodsList));

    const expiredCards = newList.filter((pm: PaymentMethod) => {
      if (pm.ExpDate) {
        if (this.isCardExpired(pm.ExpDate)) {
          pm.IsExpired = true;
          return true;
        }
        return false;
      } else {
        return false;
      }
    });

    newList = newList.filter((pm: PaymentMethod) => {
      return !expiredCards.includes(pm);
    });

    const defaultPayMethod: PaymentMethod | undefined = newList.find((pm: PaymentMethod) => {
      return pm.IsDefault;
    });

    if (defaultPayMethod) {
      sortedList.push(defaultPayMethod);
      newList = newList.filter((pm: PaymentMethod) => {
        return !pm.IsDefault;
      });
    }

    // nicknamed payments
    const nicknamedPayMethods: PaymentMethod[] = newList.filter((pm: PaymentMethod) => {
      return pm.Nickname;
    });

    sortedList = sortedList.concat(nicknamedPayMethods.sort((a, b) => {
      const nameA = a.Nickname!.toUpperCase();
      const nameB = b.Nickname!.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }));

    newList = newList.filter((pm: PaymentMethod) => {
      return !nicknamedPayMethods.includes(pm);
    });

    // credit cards on ascending last 4
    const cardsWithLast4 = newList.filter((pm: PaymentMethod) => {
      return this.isCardBankDigital(pm.PaymentAccountType) === 'CARD' && pm.LastFour;
    });

    sortedList = sortedList.concat(cardsWithLast4.sort((a, b) => {
      const nameA = a.LastFour!;
      const nameB = b.LastFour!;

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }));

    newList = newList.filter((pm: PaymentMethod) => {
      return !cardsWithLast4.includes(pm);
    });

    // expired cards
    sortedList = sortedList.concat(expiredCards);

    // bank ascending on last 4
    const bankWithLast4 = newList.filter((pm: PaymentMethod) => {
      return this.isCardBankDigital(pm.PaymentAccountType) === 'BANK' && pm.LastFour;
    });

    sortedList = sortedList.concat(bankWithLast4.sort((a, b) => {
      const nameA = a.LastFour!;
      const nameB = b.LastFour!;

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }));
    newList = newList.filter((pm: PaymentMethod) => {
      return !bankWithLast4.includes(pm);
    });

    // digital payments
    const digitalPayMethods = newList.filter((pm: PaymentMethod) => {
      return this.isCardBankDigital(pm.PaymentAccountType) === 'DIGITAL' && !this.isNullOrUndefined(pm.ReferenceId);
    });

    sortedList = sortedList.concat(digitalPayMethods.sort((a, b) => {
      const nameA = a.PaymentAccountType!;
      const nameB = b.PaymentAccountType!;

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }));
    newList = newList.filter((pm: PaymentMethod) => {
      return !digitalPayMethods.includes(pm);
    });

    // new card
    const newCardIndex: number = newList.findIndex((pm: PaymentMethod) => {
      return !pm.ReferenceId && pm.PaymentAccountType === PaymentAccountType.Card;
    });
    if (newCardIndex > -1) {
      sortedList.push(newList[newCardIndex]);
      newList.splice(newCardIndex, 1);
    }

    // new bank
    const newBankIndex: number = newList.findIndex((pm: PaymentMethod) => {
      return !pm.ReferenceId && pm.PaymentAccountType === PaymentAccountType.Banking;
    });
    if (newBankIndex > -1) {
      sortedList.push(newList[newBankIndex]);
      newList.splice(newBankIndex, 1);
    }

    // get list of unused digital payments
    const digitalPayTypes = [PaymentAccountType.AmazonPay, PaymentAccountType.ApplePay, PaymentAccountType.GooglePay, PaymentAccountType.PayPal,
      PaymentAccountType.PayPalCredit, PaymentAccountType.Venmo]
      .filter(pat => {
        // return digital pay types that aren't in use
        return digitalPayMethods.findIndex(digitalPayment => {
          return digitalPayment.PaymentAccountType === pat;
        }) < 0;
      });

    // create list of paymethods for unused digital pay types
    let unusedDigitalPayments: PaymentMethod[] = [];
    digitalPayTypes.forEach(dp => {
      const newDP = new PaymentMethod();
      newDP.Description = `New ${dp.toString()}`;
      newDP.PaymentAccountType = dp;
      unusedDigitalPayments.push(newDP);
    });

    // remove digital paymethod if it's default pay method
    unusedDigitalPayments = unusedDigitalPayments.filter(dp => {
      return dp.PaymentAccountType !== defaultPayMethod?.PaymentAccountType;
    });

    sortedList = sortedList.concat(unusedDigitalPayments.sort((a, b) => {
      const nameA = a.PaymentAccountType!;
      const nameB = b.PaymentAccountType!;

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }));

    // digital payments not in use(alphabetical)
    // if LPO set dont show bank, venmo, amazon pay
    const isLPO = isLimitedPaymentOptions;
    if (isLPO) {
      sortedList = sortedList.filter((pm: PaymentMethod) => {
        return pm.PaymentAccountType !== PaymentAccountType.Banking &&
        pm.PaymentAccountType !== PaymentAccountType.Venmo &&
        pm.PaymentAccountType !== PaymentAccountType.AmazonPay;
      });
    }

    const cashPayment = newList.find(dp => {
      return dp.PaymentAccountType === PaymentAccountType.Cash;
    });
    sortedList.push(cashPayment!);

    return sortedList;
  }

  // https://stackoverflow.com/a/21617574
  getCreditCardBrandFromNumber (currVal: string): PaymentAccountType {
    // American Express
    const amexRegex = /^3[47][0-9]{0,}$/; // 34, 37
    // Visa
    const visaRegex = /^4[0-9]{0,}$/; // 4
    // MasterCard
    const mastercardRegex = /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$/; // 2221-2720, 51-55
    const maestroRegex = /^(5[06789]|6)[0-9]{0,}$/; // always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
    // Discover
    const discoverRegex = /^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$/; // 6011, 622126-622925, 644-649, 65

    // get rid of anything but numbers
    currVal = currVal.replace(/\D/g, '');

    // checks per each, as their could be multiple hits
    // fix: ordering matter in detection, otherwise can give false results in rare cases
    let selBrand = PaymentAccountType.Unknown;
    if (currVal.match(amexRegex)) {
      selBrand = PaymentAccountType.AmericanExpress;
    } else if (currVal.match(visaRegex)) {
      selBrand = PaymentAccountType.Visa;
    } else if (currVal.match(mastercardRegex)) {
      selBrand = PaymentAccountType.MasterCard;
    } else if (currVal.match(discoverRegex)) {
      selBrand = PaymentAccountType.Discover;
    } else if (currVal.match(maestroRegex)) {
      if (currVal[0] === '5') { // started 5 must be mastercard
        selBrand = PaymentAccountType.MasterCard;
      } else {
        selBrand = PaymentAccountType.Unknown; // maestro is all 60-69 which is not something else, thats why this condition in the end
      }
    }

    return selBrand;
  }
}
