@if (pageReady) {
    <main class="gs-page">
        <div class="success-container">
            <div class="headline-container">
                <h1 class="headline">{{pageLabels.cancelFuturePaymentPageHeaderText}}</h1>
            </div>
            <div class="gs-content-container">
                <div class="success-details">
                    <div class="subheader-container">
                        <h2 class="section-header">{{pageLabels.cancelFuturePaymentHeaderText}}</h2>
                        <p class="section-subheader">{{pageLabels.cancelFuturePaymentSubheaderText}}</p>
                    </div>
                    <div class="detail-container">
                        <mat-divider></mat-divider>
                        @if (data.Details !== 'undefined' || data.Details !== undefined) {
                            @for (detail of data.Details; track $index) {
                                <div class="success-detail-row">
                                    <h3>{{detail.Label}}</h3>
                                    <p>{{detail.Value}}</p>
                                </div>
                                <mat-divider></mat-divider>
                            }
                        }
                    </div>
                    <div class="cta-container">
                        <a href="{{this.pageLabels.paymentSuccessLabels.value.data.ctaLink}}" class="keep-payment-link" [attr.aria-label]="pageLabels.keepFuturePaymentLinkText">{{pageLabels.keepFuturePaymentLinkText}}</a>
                        <button role="link" mat-flat-button color="primary" [attr.aria-label]="pageLabels.cancelFuturePaymentButtonText" (click)="cancelFuturePayment()" class="gs-form-button-primary">{{pageLabels.cancelFuturePaymentButtonText}}</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
}