import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.development';
import { type Observable, map, throwError } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  constructor (
    private readonly http: HttpClient
  ) { }

  /**
   * Returns content from Builder
   * @param model model name in builder
   * @param pageName content name in builder
   * @returns observable containing
   */
  getContent (model: string, pageName: string): Observable<any> {
    const url = environment.contentAPIUrl + encodeURIComponent(model) + '?apiKey=' + environment.builderPublicKey + '&query.name=' + encodeURIComponent(pageName) + '&includeRefs=true';
    const res = this.http.get(url);

    return res.pipe(
      map((data: any) => {
        if (data.results.length > 0 && data.results[0].data !== null) {
          return data.results[0].data;
        } else {
          return throwError(() => new Error(`CMS error: Failed to get content for ${model}:${pageName}.`));
        }
      })
    );
  }

  getBlogs (blogCount: number): Observable<any> {
    const url = environment.contentAPIUrl + encodeURIComponent('gs-blog-detail-page') + '?apiKey=' + environment.digitalProductsBuilderKey + '&fields=name,data.url,data.thumbNail,data.date,data.excerpt&sort.data.date=-1&offset=0&limit=' + blogCount;
    const res = this.http.get(url);

    return res.pipe(
      map((data: any) => {
        if (data.results.length > 0 && data.results[0].data !== null) {
          return data.results;
        } else {
          return throwError(() => new Error('Blogs CMS error: Failed to get content for gs-blog-detail-page.'));
        }
      })
    );
  }
}
