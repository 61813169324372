import { Component, Inject, PLATFORM_ID, type OnInit } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { CmsService } from '../../services/cms.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UtilService } from '../../services/util.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { StorageService } from '../../services/storage.service';
import { SuccessData } from '../../models/success-data.model';
import { StorageEnum } from '../../enums/storage.enum';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { FuturePayment } from '../../models/payment';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { PaymentService } from '../../services/payment.service';
import { ErrorData } from '../../models/error-data';
import { type AccountDetail } from '../../models/account-detail';
import { Person } from '../../models/person';
import { PaymentAccountType } from '../../enums/paymentaccounttype.enum';

@Component({
  selector: 'app-cancel-future-payment',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDividerModule
  ],
  templateUrl: './cancel-future-payment.component.html',
  styleUrl: './cancel-future-payment.component.scss'
})

export class CancelFuturePaymentComponent implements OnInit {
  pageReady = false;
  pageLabels: any = {};
  isBrowser = false;
  data: any;
  accountDetail: AccountDetail;

  constructor (
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) readonly platformId: Object,
    private readonly cms: CmsService,
    private readonly router: Router,
    private readonly utils: UtilService,
    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    private storage: StorageService,
    private readonly payment: PaymentService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.accountDetail = this.storage.getSession(StorageEnum.AccountDetail);
  }

  ngOnInit (): void {
    this.cms.getContent('oas-payment', 'Payment').subscribe(data => {
      this.pageLabels = data;
      this.setSuccessData();
    });
    this.pageReady = true;
    this.utils.hideSpinner();
  }

  setSuccessData (): void {
    const successLabels = this.pageLabels.paymentSuccessLabels.value.data;
    const success = new SuccessData();
    success.Header = successLabels.canceledFutureDatedPaymentHeader;

    success.Details.push({ Label: 'Schedule Date', Value: `${this.accountDetail?.FuturePayment?.Info?.PaymentDate?.toString()}` });
    success.Details.push({ Label: 'Payment Amount', Value: `$ ${this.accountDetail?.FuturePayment?.Info?.PaymentAmount}` });
    if (this.accountDetail?.FuturePayment?.PaymentMethod?.Type === PaymentAccountType.ApplePay.toString() ||
        this.accountDetail?.FuturePayment?.PaymentMethod?.Type === PaymentAccountType.GooglePay.toString() ||
        this.accountDetail?.FuturePayment?.PaymentMethod?.Type === PaymentAccountType.PayPal.toString() ||
        this.accountDetail?.FuturePayment?.PaymentMethod?.Type === PaymentAccountType.PayPalCredit.toString() ||
        this.accountDetail?.FuturePayment?.PaymentMethod?.Type === PaymentAccountType.Venmo.toString() ||
        this.accountDetail?.FuturePayment?.PaymentMethod?.Type === PaymentAccountType.AmazonPay.toString()) {
      success.Details.push({ Label: 'Payment Method', Value: this.accountDetail?.FuturePayment?.PaymentMethod?.Type });
    } else {
      success.Details.push({ Label: 'Payment Method', Value: `${this.accountDetail?.FuturePayment?.PaymentMethod?.Type} ending in ${this.accountDetail?.FuturePayment?.PaymentMethod?.AccountNumber}` });
    }

    success.CTA.Label = successLabels.ctaLabel;
    success.CTA.Link = successLabels.ctaLink;
    success.CTA.AriaLabel = successLabels.ctaAriaLabel;

    this.storage.setSession(StorageEnum.SuccessData, success);
    this.data = success;
  }

  cancelFuturePayment (): void {
    this.utils.showSpinner();
    const person = new Person();
    person.Email = this.accountDetail.Email;
    person.FirstName = this.accountDetail.FirstName;
    person.LastName = this.accountDetail.LastName;

    const futurePayment = new FuturePayment();
    futurePayment.Person = person;
    futurePayment.AccountNumber = this.accountDetail.FuturePayment?.Info?.AccountNumber;
    futurePayment.ActionCode = 'C';
    futurePayment.CustomerClass = this.accountDetail.CustomerClass;
    futurePayment.Date = this.accountDetail.FuturePayment?.Info?.PaymentDate;
    futurePayment.Amount = this.accountDetail.FuturePayment?.Info?.PaymentAmount;
    futurePayment.ReferenceNumber = this.accountDetail.FuturePayment?.Info?.ReferenceNumber;
    futurePayment.PaymentAccountType = this.accountDetail.FuturePayment?.PaymentMethod?.PaymentAccountType;
    futurePayment.BankAccountType = this.accountDetail.FuturePayment?.PaymentMethod?.BankAccountType;

    this.payment.postFuturePayment(futurePayment).subscribe({
      error: (e) => {
        this.setErrorData(e.message);
        void this.router.navigateByUrl('/error-page');
      },
      next: (data: any) => {
        this.utils.hideSpinner();
        void this.router.navigateByUrl('/cancel-future-payment-success');
      }
    });
  }

  setErrorData (cmsErrorData: string): void {
    const [errorHeader, errorMessage] = cmsErrorData.split('|').map(String);
    const errorLabels = this.pageLabels.paymentErrorLabels.value.data;

    const errorDetail = new ErrorData();
    errorDetail.Header = errorHeader;
    errorDetail.Subheader = errorMessage;
    errorDetail.CtaButton.Label = errorLabels.ctaBackToSummaryCloseLabel;
    errorDetail.CtaButton.Link = errorLabels.ctaBackToSummaryCloseLink;
    errorDetail.CtaButton.AriaLabel = errorLabels.ctaBackToSummaryCloseLabel;
    this.storage.setSession(StorageEnum.ErrorData, errorDetail);
  }
}
