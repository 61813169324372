export enum StorageEnum {
  LoginResponse = 'LoginResponse',
  MultiAccountResponse = 'MultiAccountResponse',
  GlobalVars = 'GlobalVariables',
  AccountDetail = 'AccountDetail',
  PaymentMethods = 'PaymentMethods',
  SuccessData = 'SuccessData',
  ErrorData = 'ErrorData',
  ChangeAutoPayData = 'ChangeAutoPayData'
}
