@if (pageReady) {
    <main class="gs-page">
        <div class="account-summary">
            <div class="headline-container">
                <div class="headline">Welcome back, <span class="customer-name">Bob</span>. Did you know that you're up for renewal?</div>
                <button mat-flat-button color="primary">CTA BTN</button>
            </div>
            <section class="gs-content-container">
                <div class="payment-details-container">
                    <div class="balance-details">
                        <mat-card class="balance-card">
                            <div class="card-content-container">
                                <div class="balance-container">
                                    <p class="balance-amount">
                                        {{'56.78' | currency }}
                                    </p>
                                    <div class="due-date-container">
                                        <p class="due-date-text">Due By</p>
                                        <p class="due-date">{{'1/2/24' | date: 'MMM dd, yyyy'}}</p>
                                    </div>
                                    <button class="make-payment-cta" mat-flat-button color="primary" (click)="navToPayment()">make a payment</button>
                                    <a class="make-payment-link" href="#">need more time to pay?</a>
                                </div>
                                <mat-divider class="divider" [vertical]="!isMobile"></mat-divider>
                                <div class="view-bill-container">
                                    <i class="fa-thin fa-file-invoice-dollar fa-3x"></i>
                                    <a href="#">View Bill</a>
                                    @if (accountDetail.FuturePayment !== null) {
                                        <button class="make-payment-cta" mat-flat-button color="primary" (click)="navToCancelFuturePayment()">Cancel Future Payment</button>
                                    }
                                </div>
                            </div>
                        </mat-card>
                    </div>
                    <div class="payment-settings">
                        <p class="payment-settings-header">Payment Settings</p>
                        <div class="payment-settings-autopay">
                            @if (accountDetail.IsAutoPay === false) {                                                       
                                <i class="fa-solid fa-rotate-right"></i>
                                <a href="/autopay-enroll">Set up Auto-Pay</a> 
                                <i class="fa-solid fa-circle-xmark"></i>
                            }
                            @if (accountDetail.IsAutoPay === true) {
                                <i class="fa-solid fa-rotate-right"></i>
                                <a href="/autopay-cancel">Cancel Auto-Pay</a>
                                <i class="fa-solid fa-circle-check"></i>
                            }
                        </div>
                        @if (accountDetail.IsAutoPay === true) {
                        <div class="payment-settings-autopay-change">
                                <i class="fa-solid fa-rotate-right"></i>
                                <a href="/autopay-cancel?changeAutoPay=true">Change Auto-Pay</a>
                                <i class="fa-solid fa-circle-check"></i>                    
                        </div>
                        } 
                        <div class="payment-settings-paperless">
                            <i class="fa-regular fa-leaf"></i>
                            <a href="#">Paperless Billing</a>
                            <i class="fa-solid fa-circle-xmark"></i>
                        </div>
                    </div>
                    <div class="rate-plan-details">
                        <p class="rate-plan-header">Your Plan</p>
                        <p class="rate-plan-title">Fixed Rate Plan</p>
                        <p class="rate-plan-rate"><span class="rate-price">55</span> per therm</p>
                        <p class="rate-plan-expiration-header">EXPIRATION</p>
                        <p class="rate-plan-expiration">JAN 2025</p>
                        <a href="#">Select a Plan</a>
                    </div>
                </div>
                <div class="consumption-history">
                    <mat-accordion>
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Consumption History
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>I'm visible because I am open</p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="features-container">
                    <div class="feature" (click)="navToWallet()">
                        <i class="fa-thin fa-wallet fa-3x"></i>
                        <p class="feature-title">Manage your Wallet</p>
                        <p class="feature-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div class="feature">
                        <i class="fa-thin fa-user-plus fa-3x"></i>
                        <p class="feature-title">Refer a friend</p>
                        <p class="feature-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div class="feature">
                        <i class="fa-thin fa-bell fa-3x"></i>
                        <p class="feature-title">Alerts</p>
                        <p class="feature-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                </div>

                <div *ngIf="blogs$ | async as blogs" class="blog-section">
                    <div class="blog-section-title">Check out our latest blogs</div>
                    <div class="blogs-container" >
                        @for (blog of blogs; track $index) {
                            <div class="blog">
                                <a [href]="env.blogBaseUrl + blog.data.url" target="_blank">
                                    <img class="blog-thumbnail" [src]="blog.data.thumbNail" alt="">
                                </a>
                                <div class="blog-desc">
                                    <a [href]="env.blogBaseUrl + blog.data.url" target="_blank">
                                        <p class="blog-title">{{blog.name}}</p>
                                    </a>
                                    <p class="blog-date">{{formatBlogDate(blog.data.date)}}</p>
                                    <p class="blog-excerpt">{{blog.data.excerpt}}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div class="contact-us-section">
                    <p class="contact-us-text">Looking for something else?</p>
                    <button class="contact-us-cta" mat-flat-button color="primary">Contact Us</button>
                </div>
            </section>

        </div>
    </main>
}
