export enum PaymentAccountType {
  Unknown = 'Unknown',
  Banking = 'Banking',
  Card = 'Card',
  Visa = 'Visa',
  MasterCard = 'MasterCard',
  AmericanExpress = 'AmericanExpress',
  Discover = 'Discover',
  AmazonPay = 'Amazon Pay',
  ApplePay = 'Apple Pay',
  GooglePay = 'Google Pay',
  PayPal = 'PayPal',
  PayPalCredit = 'PayPal Credit',
  Venmo = 'Venmo',
  Cash = 'Cash'
}
