@if (pageReady) {
    <main class="gs-page">
        <div class="success-container">
            <div class="success-headline-container border">
                <i aria-hidden="true" class="success-icon fa-sharp fa-light fa-circle-check fa-5x"></i>
                <h1>{{data.Header}}</h1>
                <p class="subheader">{{data.Subheader}}</p>
            </div>
            <div class="success-details">
                @if (data.PaymentAmount.Value > 0 ) {
                    <div class="payment-amount-container">
                        <h2 class="payment-amount-label">{{data.PaymentAmount.Label}}</h2>
                        <p class="payment-amount"><span class="currencySup">$</span>{{data.PaymentAmount.Value}}</p>
                    </div>
                    <mat-divider></mat-divider>
                }
                <div class="detail-container">

                    @for (detail of data.Details; track $index) {
                        <div class="success-detail-row">
                            <h3>{{detail.Label}}</h3>
                            <p>{{detail.Value}}</p>
                        </div>
                        <mat-divider></mat-divider>
                    }
                </div>
                @if (isFutureDatedPayment) {
                    <p>{{data.FutureDatedPaymentNote}}</p>
                }
                <div class="cta-container">
                    @if (data.AutopayCTAText) {
                        <a class="autopay-link" [attr.aria-label]="data.AutopayLinkAriaLabel">{{data.AutopayCTAText}}</a>
                    }
                    <button role="link" mat-flat-button color="primary" [attr.aria-label]="data.CTA.AriaLabel" (click)="goToAccountSummary()" class="gs-form-button-primary">{{data.CTA.Label}}</button>
                </div>
            </div>
        </div>
    </main>
}