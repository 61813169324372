import { type Address } from './address';
import { type PhoneInfo } from './phone-info';
import { type ContactPhoneInfo } from './contact-phone-info';
import { type ContactEmailInfo } from './contact-email-info';
import { type BillInfo } from './bill-info';
import { type BillingPeriod } from './billing-period';
import { type RecentPayment } from './recent-payment';
import { type PaymentHistory } from './payment-history';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { RateType } from '../enums/ratetype.enum';
import { type PaymentMethod } from './payment-method';
import { type PaymentPlan } from './payment-plan';
import { type FuturePayment } from './future-payment';
import { type AutoPay } from './auto-pay';

export class AccountDetail {
  public AccountNumber?: string;
  public AutoPay?: AutoPay;
  public CurrentBalance?: number;
  public CurrentBalanceDueDate?: Date;
  public ServiceAddresses?: Address[];
  public IsRenewalInProgress?: boolean;
  public DefaultAddress?: Address;
  public BillingAddress?: Address;
  public ServiceAddress?: string;
  public PhoneNumbers?: PhoneInfo[];
  public ContactPhoneInfo?: ContactPhoneInfo[];
  public ContactEmailInfo?: ContactEmailInfo[];
  public BillRate?: RateType;
  public Bills?: BillInfo[];
  public BillURL?: string;
  public FinalBillDate?: Date;
  public FinalBillDueDate?: Date;
  public FirstBillDate?: Date;
  public Headline?: string;
  public IsAutoPay?: boolean;
  public IsFinalBill?: boolean;
  public IsInCollections?: boolean;
  public IsLimitedPaymentOptions?: boolean;
  public LandLordAlertIndicator?: string;
  public AccountBridgePlanStatus?: string;
  public PrimaryAccountHolder?: string;
  public IsPaperlessBilling?: boolean;
  public IsStopped?: boolean;
  public IsPaymentAllowed?: boolean;
  public IsSevered?: boolean;
  public IQSScore?: string;
  public MinimumPayment?: number;
  public MostRecentBillPeriod?: BillingPeriod;
  public MostRecentPayment?: RecentPayment;
  public NextBillDate?: Date;
  public NextBillDueDate?: Date;
  public PastDueBalance?: number;
  public Payments?: PaymentHistory[];
  public ServiceStartDate?: Date;
  public ShowRenewal?: boolean;
  public BudgetAmount?: number;
  public ReferFriendCode?: string;
  public IsOnePricePlan?: boolean;
  public IsBudgetBillEligible?: boolean;
  public FirstName?: string;
  public LastName?: string;
  public Email?: string;
  public CustomerClass?: string;
  public SaRateScheduleCode?: string;
  public SuppressBillingRateIndicator?: string;
  public BillingRateExpirationDate?: Date;
  public PaymentPlans?: PaymentPlan[];
  public IsPayPlan?: boolean;
  public PayPlanType?: string;
  public PaymentMethods?: PaymentMethod[];
  public PastCutoffTime?: Date; // This will be value in CMS
  public IsEligibleForFuturePayment?: boolean;
  public FuturePayment?: FuturePayment | null;
  public Error?: any;
}
