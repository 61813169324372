import { Component, Inject, Optional, type OnInit } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafePipe } from '../../pipes/dom-sanitizer.pipe';
import { PaymentMethod } from '../../models/payment-method';
import { DialogResultType } from '../../enums/dialogresulttype.enum';
import { PaymentDialogResult } from '../../models/payment-dialog-result';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-digital-payment-modal',
  standalone: true,
  imports: [
    SafePipe,
    MatButtonModule
  ],
  templateUrl: './digital-payment-modal.component.html',
  styleUrl: './digital-payment-modal.component.scss'
})
export class DigitalPaymentModalComponent implements OnInit {
  pageData: any;
  logging: string = '';

  constructor (
    @Optional() public dialogRef: MatDialogRef<DigitalPaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.pageData = data;
  }

  ngOnInit (): void {
    this.logging += JSON.stringify(this.pageData) + '\n';

    window.addEventListener('message', (e) => {
      this.logging += JSON.stringify(e) + '\n';
      const pmDetails = e.data;

      if (pmDetails.indexOf('Token') > -1) {
        const pmDetailsJson = JSON.parse(pmDetails.substring(pmDetails.indexOf(':') + 1));
        this.logging += JSON.stringify(pmDetailsJson) + '\n';
        console.log('pmDetailsJson: ', this.logging);
        this.addPaymentMethod(pmDetailsJson.Token);
      }
    });
  }

  addPaymentMethod (token: string): void {
    const digitalMethod = new PaymentMethod();
    digitalMethod.ReferenceId = token;
    digitalMethod.PaymentAccountType = this.pageData.paymentAccountType;
    digitalMethod.Description = this.pageData.paymentAccountType;
    digitalMethod.SaveToAccount = true;
    digitalMethod.BankCardDigital = 'DIGITAL';

    const dialogResult = new PaymentDialogResult();
    dialogResult.PayMethod = digitalMethod;
    dialogResult.Type = DialogResultType.Add;
    this.dialogRef.close(dialogResult);
  }

  closeModal (): void {
    const dialogResult = new PaymentDialogResult();
    dialogResult.Type = DialogResultType.Cancel;
    this.dialogRef.close(dialogResult);
  }
}
