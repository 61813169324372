/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/indent */
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CmsService } from '../../../shared/services/cms.service';
import { catchError, Observable, Subscription, throwError } from 'rxjs';
import { UserProfile } from '../../models/user-profile';
import { StorageEnum } from '../../enums/storage.enum';
import { StorageService } from '../../services/storage.service';
import { UtilService } from '../../services/util.service';
import { PaymentusCardMethod, PaymentusRequest } from '../../models/paymentus';
import { PaymentusService } from '../../services/paymentus.service';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { PaymentMethod } from '../../models/payment-method';
import { PaymentAccountType } from '../../enums/paymentaccounttype.enum';
import { PaymentService } from '../../services/payment.service';
import { PaymentDialogResult } from '../../models/payment-dialog-result';
import { PaymentusErrorCode } from '../../enums/paymentuserrorcode.enum';
import { DialogResultType } from '../../enums/dialogresulttype.enum';

@Component({
  selector: 'credit-card-form',
  templateUrl: 'credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    NgxMaskDirective,
    NgxMaskPipe
  ]
})
export class CreditCardFormComponent implements OnDestroy, OnInit {
  creditCardForm!: FormGroup;
  isHover = false;
  isFocus = false;
  showSecurityCodeImages = false;
  isVisaOrMastercardOrDiscover = false;
  isAmex = false;
  isUnknownCardType = false;
  pageLabels$!: Observable<any>;
  cardNumberSubscription: Subscription | undefined;
  hasError = false;
  showCreditCardImage = false;
  userProfile?: UserProfile;
  formErrorMessage: string = '';
  preferredPayMethods?: PaymentMethod[];
  showPreferencesSection = true;
  selectedPaymethod: PaymentMethod | null = null;
  isPreferredPaymentMethod = false;
  cardExpired = false;
  preferredPayMethodDescription?: string = '';
  showCardExpiredMessage = true;
  isWalletChecked = false;
  cardNumberMask = '0000 0000 0000 0000';

  constructor (
    @Optional() public dialogRef: MatDialogRef<CreditCardFormComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly cms: CmsService,
    private readonly storage: StorageService,
    private readonly utils: UtilService,
    private readonly paymentusSvc: PaymentusService,
    private readonly paymentSvc: PaymentService,
    @Inject(MAT_DIALOG_DATA) data: PaymentMethod
  ) {
        this.selectedPaymethod = data;
  }

  ngOnInit (): void {
    this.pageLabels$ = this.cms.getContent('oas-paymethod-modal', 'PaymethodModal');
    this.setupCurrentPayMethod();
    if (!this.isPreferredPaymentMethod) {
      this.creditCardForm = this.formBuilder.group({
        cardNumber: ['', [Validators.required, this.validateCardNumber.bind(this)]],
        zipCode: ['', [Validators.required, this.validateZipCode.bind(this)]],
        securityCode: ['', [Validators.required, this.validateSecurityCode.bind(this)]],
        expirationDate: ['', [Validators.required, this.validateExpirationDate.bind(this)]],
        nickname: ['', [this.validateNickname.bind(this)]],
        setDefault: [false],
        saveWallet: [false],
        cardNumberPreferredPayment: ['']
      });
    } else if (this.cardExpired) {
        this.creditCardForm = this.formBuilder.group({
        securityCode: ['', [Validators.required, this.validateSecurityCode.bind(this)]],
        expirationDate: ['', [Validators.required, this.validateExpirationDate.bind(this)]],
        cardNumberPreferredPayment: ['']
      });
    } else if (this.isPreferredPaymentMethod && !this.cardExpired) {
        this.creditCardForm = this.formBuilder.group({
        securityCode: ['', [Validators.required, this.validateSecurityCode.bind(this)]],
        cardNumberPreferredPayment: ['']
      });
    }

    this.creditCardForm.statusChanges.subscribe(() => {
      this.updateErrorState();
    });
    this.cardNumberSubscription = this.creditCardForm.get('cardNumber')?.valueChanges.subscribe(value => {
      this.creditCardForm.get('securityCode')?.updateValueAndValidity();
      this.updateCardType(value);
      this.updateErrorState();
    });
    this.userProfile = this.storage.getSession(StorageEnum.LoginResponse);
    this.preferredPayMethods = this.storage.getSession(StorageEnum.PaymentMethods);
    this.utils.hideSpinner();
  }

  checkMask (value: string): void {
    const type = this.utils.getCreditCardBrandFromNumber(value);
    if (type === PaymentAccountType.AmericanExpress) {
      this.cardNumberMask = '0000 000000 00000';
    } else if (type === PaymentAccountType.MasterCard ||
      type === PaymentAccountType.Visa ||
      type === PaymentAccountType.Discover
    ) {
      this.cardNumberMask = '0000 0000 0000 0000';
    } else {
      this.cardNumberMask = '';
    }
  }

  setupCurrentPayMethod (): void {
    if (!this.utils.isNullOrWhitespace(this.selectedPaymethod?.ReferenceId)) {
      this.isPreferredPaymentMethod = true;
      this.updatePreferredCardType();
      this.showPreferencesSection = false;
      if (this.selectedPaymethod?.IsExpired) {
        this.cardExpired = true;
      }
      this.preferredPayMethodDescription = this.utils.isNullOrWhitespace(this.selectedPaymethod?.Nickname)
      ? this.selectedPaymethod?.Description
      : this.selectedPaymethod?.Nickname;
    }
  }

  ngOnDestroy (): void {
    this.cardNumberSubscription?.unsubscribe();
  }

  updateErrorState (): void {
    this.hasError = this.creditCardForm.get('cardNumber')?.errors !== null;
  }

  onSubmit (): void {
    this.formErrorMessage = '';
    this.checkForDuplicateNickName();
    if (this.creditCardForm.valid) {
      const expirationDate = this.creditCardForm.get('expirationDate')?.value;
      let cardExpirationDate = expirationDate;
      if (expirationDate) {
        const [month, year] = expirationDate.split('/').map(String);
        cardExpirationDate = month + year.padStart(4, '20');
      }

        if (this.isPreferredPaymentMethod) {
        if (this.cardExpired) {
          this.utils.showSpinner();
          this.updatePreferredPaymentData().pipe(
            catchError((dataError: any) => {
              // eslint-disable-next-line @typescript-eslint/no-throw-literal
              this.utils.hideSpinner();
              // throw 'error';
              return throwError(() => new Error(dataError));
            })).subscribe(() => {
              // create object and use this.selectedPaymethod to build it
              const dialogResult = new PaymentDialogResult();
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              dialogResult.PayMethod = JSON.parse(JSON.stringify(this.selectedPaymethod))!;
              dialogResult.PayMethod.ExpDate = cardExpirationDate;
              dialogResult.PayMethod.IsExpired = false;
              dialogResult.Type = DialogResultType.Update;
              this.dialogRef.close(dialogResult);
            });
        }
      } else {
        this.utils.showSpinner();
        this.savePaymentData().subscribe({
          error: (e) => {
            // get code from message string
            const [errorCode, message] = e.message.split('|').map(String);
            if (errorCode === PaymentusErrorCode.dialogCode) {
              this.showFormErrorLabel(message);
            } else if (errorCode === PaymentusErrorCode.ExternalPageCode) {
              const dialogResult = new PaymentDialogResult();
              dialogResult.Type = DialogResultType.Error;
              dialogResult.ErrorMessage = message;
              this.dialogRef.close(dialogResult);
            } else {
              this.formErrorMessage = this.storage.getSession(StorageEnum.GlobalVars).paymentusGenericError;
            }
            this.utils.hideSpinner();
          },
          next: (data: any) => {
            const dialogResult = new PaymentDialogResult();
            dialogResult.Type = DialogResultType.Add;
            dialogResult.PayMethod.ReferenceId = data['profile-response'].token;
            dialogResult.PayMethod.Nickname = this.creditCardForm.get('nickname')?.value;
            dialogResult.PayMethod.ExpDate = cardExpirationDate;
            dialogResult.PayMethod.IsDefault = this.creditCardForm.get('setDefault')?.value;
            dialogResult.PayMethod.SaveToAccount = this.creditCardForm.get('saveWallet')?.value;
            dialogResult.PayMethod.LastFour = data['profile-response']['account-number'].replaceAll('*', '');
            dialogResult.PayMethod.PaymentAccountType = this.getMappedPaymentType(data['profile-response'].type);
            dialogResult.PayMethod.Description = `${dialogResult.PayMethod.PaymentAccountType} ending in ${dialogResult.PayMethod.LastFour}`;
            this.dialogRef.close(dialogResult);
          }
        });
      }
      if (this.isPreferredPaymentMethod && !this.cardExpired) {
        const dialogResult = new PaymentDialogResult();
        dialogResult.Type = DialogResultType.Preferred;
        this.dialogRef.close(dialogResult); // don't update preffered payment list
       }
    } else {
      this.creditCardForm.markAllAsTouched();
      const errorMessage = this.storage.getSession(StorageEnum.GlobalVars).formError;
      this.showFormErrorLabel(errorMessage);
    }
  }

  getMappedPaymentType (cardValue: string): PaymentAccountType {
    const card = cardValue.toLowerCase();
    if (card === 'mc') {
      return PaymentAccountType.MasterCard;
    } else if (card === 'amex') {
      return PaymentAccountType.AmericanExpress;
    } else if (card === 'disc') {
      return PaymentAccountType.Discover;
    } else if (card === 'visa') {
      return PaymentAccountType.Visa;
    } else {
      return PaymentAccountType.Unknown;
    }
  }

  onCancelClick (): void {
    const dialogResult = new PaymentDialogResult();
    dialogResult.Type = 'Cancel';
    dialogResult.PayMethod.ReferenceId = '';
    this.dialogRef.close(dialogResult);
  }

  onExpirationDateBlur (event: any): void {
    const expirationDateControl = this.creditCardForm.get('expirationDate');
    if (expirationDateControl && expirationDateControl.valid) {
      expirationDateControl.setValue(event.target.value);
    }
  }

  formatExpirationDate (value: string): string {
    const [month, year] = value.split('/');
    return `${month.padStart(2, '0')}/${year}`;
  }

  updateCardType (cardNumber: string): void {
    if (this.GetCardType(cardNumber) === 'visa' ||
        this.GetCardType(cardNumber) === 'masterCard' ||
        // eslint-disable-next-line padded-blocks
        this.GetCardType(cardNumber) === 'discover') {

        this.isVisaOrMastercardOrDiscover = true;
        this.isAmex = false;
        this.isUnknownCardType = false;
    } else if (this.GetCardType(cardNumber) === 'americanExpress') {
        this.isAmex = true;
        this.isVisaOrMastercardOrDiscover = false;
        this.isUnknownCardType = false;
    } else {
        this.isUnknownCardType = true;
        this.isVisaOrMastercardOrDiscover = false;
        this.isAmex = false;
    }
}

  // Update so security code images display correctly
  updatePreferredCardType (): void {
    if (this.selectedPaymethod?.PaymentAccountType === PaymentAccountType.Visa ||
      this.selectedPaymethod?.PaymentAccountType === PaymentAccountType.MasterCard ||
        // eslint-disable-next-line padded-blocks
        this.selectedPaymethod?.PaymentAccountType === PaymentAccountType.Discover) {

        this.isVisaOrMastercardOrDiscover = true;
        this.isAmex = false;
        this.isUnknownCardType = false;
    } else if (this.selectedPaymethod?.PaymentAccountType === PaymentAccountType.AmericanExpress) {
        this.isAmex = true;
        this.isVisaOrMastercardOrDiscover = false;
        this.isUnknownCardType = false;
    } else {
        this.isUnknownCardType = true;
        this.isVisaOrMastercardOrDiscover = false;
        this.isAmex = false;
    }
  }

  toggleSecurityCodeImages (): void {
    this.showSecurityCodeImages = !this.showSecurityCodeImages;
    const findSecurityCodebutton = document.getElementById('findSecurityCode');
    const findAccountButtonAriaExpandedValue = findSecurityCodebutton?.getAttribute('aria-expanded');
    if (findSecurityCodebutton != null && findAccountButtonAriaExpandedValue != null) {
      if (findAccountButtonAriaExpandedValue === 'true') {
        findSecurityCodebutton.setAttribute('aria-expanded', findAccountButtonAriaExpandedValue.replace('true', 'false'));
      } else {
        findSecurityCodebutton.setAttribute('aria-expanded', findAccountButtonAriaExpandedValue.replace('false', 'true'));
      }
    }
  }

  validateNickname (control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value.length > 45) {
      return { invalidNickname: true };
    }
    const nicknamePattern = /^[A-Za-z0-9 ]*$/;
    if (!nicknamePattern.test(value)) {
      return { invalidCharacters: true };
    }
    return null;
  }

  isCardTypeAccepted (cardType: string): boolean {
    if (cardType === 'visa' || cardType === 'masterCard' ||
      cardType === 'americanExpress' || cardType === 'discover' ||
      cardType === 'Discover') {
      return true;
    }
    return false;
  }

  GetCardType (cardNumber: string): string {
    type CardType = 'visa' | 'masterCard' | 'americanExpress' | 'discover';
    const acceptedCards: Record<CardType, RegExp> = {
      visa: /^4[0-9]*$/g,
      masterCard: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]*$/g,
      americanExpress: /^3[47][0-9]*$/g,
      discover: /^6(?:011|5[0-9]{2})[0-9]/
    };
    for (const card in acceptedCards) {
      if (Object.prototype.hasOwnProperty.call(acceptedCards, card) && acceptedCards[card as CardType].test(cardNumber)) {
        return card;
      }
    }
    return 'not accepted';
  }

  luhn_validate (code: string): boolean {
    return this.luhn_checksum(code) === 0;
  }

  luhn_checksum (code: string): number {
    const len = code.length;
    const parity = len % 2;
    let sum = 0;
    for (let i = len - 1; i >= 0; i--) {
      let d = Number(code.charAt(i));
      if (i % 2 === parity) { d *= 2; }
      if (d > 9) { d -= 9; }
      sum += d;
    }
    return sum % 10;
  }

  validateCardNumber (control: AbstractControl): ValidationErrors | null {
    if (!this.isPreferredPaymentMethod) {
      this.showPreferencesSection = true;
    }
    const value = control.value;
    const regex = /^[0-9]+$/;
    if (value === '' || value === null || !regex.test(value)) {
      return { required: true };
    } else if (!this.isCardTypeAccepted(this.GetCardType(value))) {
      return { invalidCardType: true };
    } else if (value.toString().length < 12 || value.toString().length > 16) {
      return { invalidCardNumber: true };
    } else if (!this.luhn_validate(value)) {
      return { invalidLuhnCheck: true };
    }
    return null;
  }

  checkForDuplicateNickName (): void {
    const nickName = this.creditCardForm.get('nickname');
    if (nickName) {
      const nickNameValue = this.creditCardForm.get('nickname')?.value;
      if (this.isDuplicateNickName(nickNameValue)) {
        nickName.setErrors({ duplicateNickName: true });
      }
    }
  }

  isDuplicateNickName (nickNameValue: string): boolean {
    if (nickNameValue) {
      if (this.preferredPayMethods) {
        nickNameValue = nickNameValue.replace(/\s\s+/g, ' '); // double spaces and empty lines, etc.
        const recordFound = this.preferredPayMethods.find(p => p.Nickname?.trim() === nickNameValue.trim());
        if (recordFound) {
          return true;
        }
      }
    }
    return false;
  }

  checkForDuplicate (): void {
    const card = this.creditCardForm.get('cardNumber');
    if (card) {
      const cardNumber = this.creditCardForm.get('cardNumber')?.value;
      if (this.isCardAlreadySaved(cardNumber)) {
        card.setErrors({ duplicatePayMethod: true });
        this.showPreferencesSection = false;
      } else {
        this.showPreferencesSection = true;
      }
    }
  }

  isCardAlreadySaved (cardNumber: string): boolean {
    if (!this.preferredPayMethods) {
      return false;
    } else {
      const foundCard = this.preferredPayMethods.find(p => p.LastFour === cardNumber?.toString().trim().slice(-4) && p.PaymentAccountType !== PaymentAccountType.Banking);
      if (foundCard) {
        return true;
      } else {
        return false;
      }
    }
  }

  validateZipCode (control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return { required: true };
    }
    if (!/^\d{5}$/.test(value)) {
      return { invalidZipCode: true };
    }
    return null;
  }

  validateSecurityCode (control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const formGroup = control.parent as FormGroup;
    const regex = /^[0-9]+$/;
    if (!value) {
      return { required: true };
    }

    if (!regex.test(value)) {
      return { invalidSecurityCode: true };
    }

    if (formGroup) {
      // If this is an existing preferred payment method then
      // check the card type to get the expected security code length since
      // we dont have the full card number
      if (this.isPreferredPaymentMethod || this.cardExpired) {
        let expectedPreferredLength: number;
        if (this.selectedPaymethod?.PaymentAccountType === PaymentAccountType.AmericanExpress) {
          expectedPreferredLength = 4;
        } else {
          expectedPreferredLength = 3;
        }
        if (value.length !== expectedPreferredLength) {
          return { invalidSecurityCode: true };
        }
      }
      const cardNumber = formGroup.get('cardNumber')?.value;
      if (cardNumber) {
        let expectedLength: number;

        if (cardNumber.startsWith('3')) {
          expectedLength = 4;
        } else {
          expectedLength = 3;
        }
        if (value.length !== expectedLength) {
          return { invalidSecurityCode: true };
        }
      }
    }

    return null;
  }

  validateExpirationDate (control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return { required: true };
    }
    const [month, year] = value.split('/').map(Number);
    const currentYear = new Date().getFullYear() % 100; // Get last two digits of current year
    const currentYearMax = currentYear + 19;
    const currentMonth = new Date().getMonth() + 1; // Months are zero-indexed in JavaScript
    if (
      !month ||
      !year ||
      month < 1 ||
      month > 12 ||
      (year < currentYear || (year === currentYear && month < currentMonth)) ||
      year > currentYearMax
    ) {
      return { invalidExpirationDate: true };
    }
    return null;
  }

  showFormErrorLabel (errorMessage: string): void {
    this.formErrorMessage = errorMessage;
    setTimeout(() => {
      const errorEl = document.getElementById('formErrorLabel');
      errorEl?.focus();
    }, 10);
  }

  savePaymentData (): Observable<any> {
    const paymentusReq: PaymentusRequest = this.mapPaymentusCardRequest();
    return this.paymentusSvc.getPaymentusToken(paymentusReq);
  }

  mapPaymentusCardRequest (): PaymentusRequest {
    const firstName = this.userProfile?.FirstName;
    const lastName = this.userProfile?.LastName;
    let acctNumber = '';
    if (this.userProfile?.Accounts !== undefined && this.userProfile.Accounts.length > 0) {
      acctNumber = this.userProfile.Accounts[0].AccountNumber ?? '';
    }
    const cardNumber = this.creditCardForm.get('cardNumber')?.value;
    const zipCode = this.creditCardForm.get('zipCode')?.value;
    const securityCode = this.creditCardForm.get('securityCode')?.value;
    const expirationDate = this.creditCardForm.get('expirationDate')?.value;
    const setDefault = this.creditCardForm.get('setDefault')?.value;
    const saveToWallet = this.creditCardForm.get('saveWallet')?.value;
    const nickname: string = this.creditCardForm.get('nickname')?.value;
    // const cardType = this.GetCardType(cardNumber);
    const [month, year] = expirationDate.split('/').map(String);

    const cardInfo: PaymentusCardMethod = {
      'account-number': cardNumber,
      'card-holder-name': `${firstName ?? '.'} ${lastName}`,
      // eslint-disable-next-line quote-props
      'cvv': securityCode,
      // eslint-disable-next-line quote-props
      'credit-card-expiry-date': { 'month': month, 'year': year.padStart(4, '20') }
    };
    const request: PaymentusRequest = {
      'payment-method': cardInfo,
      'has-contact-info': saveToWallet,
      'default-flag': setDefault,
      'profile-description': nickname.trim(),
      // eslint-disable-next-line quote-props
      'customer': { 'first-name': firstName, 'last-name': lastName, 'address': { 'zip-code': zipCode } },
      'user-info': { 'login-id': saveToWallet === true ? acctNumber : '' }
    };
      return request;
  }

  updatePreferredPaymentData (): Observable<any> {
    let preferredPaymentRequest = new PaymentMethod();
    preferredPaymentRequest = { ...preferredPaymentRequest, ...this.selectedPaymethod };
    preferredPaymentRequest.ActionCode = 'E';
    const expirationDate = this.creditCardForm.get('expirationDate')?.value;
    const [month, year] = expirationDate.split('/').map(String);
    preferredPaymentRequest.ExpDate = year.padStart(4, '20') + month;
    return this.paymentSvc.updatePreferredPayment(preferredPaymentRequest);
  }

  saveWalletOnChange (saveToWallet: MatCheckboxChange): void {
    this.isWalletChecked = saveToWallet.checked;
    if (!this.isWalletChecked) {
      this.creditCardForm.get('setDefault')?.setValue(false);
      this.creditCardForm.get('nickname')?.setValue('');
    }
  }
}
