
    <main class="gs-page">
        <div class="errorpage-container">
            <div class="errorpage-headline-container border">
                @if (data.Header == 'Sorry, there was a problem.') {
                    <i aria-hidden="true" class="errorpage-icon fa-sharp fa-light fa-circle-exclamation fa-5x"></i>
                } @else {               
                    <i aria-hidden="true" class="errorpage-icon fa-sharp fa-light fa-circle-xmark fa-5x"></i>
                }
                <h1>{{data.Header}}</h1>
                <p class="subheader">{{data.Subheader}}</p>
            </div>
            <mat-divider></mat-divider>
            <div class="cta-container">
                <button role="link" mat-flat-button color="primary" [attr.aria-label]="data.CtaButton.AriaLabel" (click)="goToRedirectPage()" class="gs-form-button-primary ok-button">{{data.CtaButton.Label}}</button>
            </div>
        </div>
    </main>
